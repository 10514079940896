import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import Color from '../library/Color';
import Info from '../library/Info';
import Storag from '../library/Storag';
import './SutraSide.css';

export interface SideHandles {
    SetPage(page?: number): void;
}

interface Props {
    Info: Info;
    Color: Color;
}

const SutraSide: React.ForwardRefRenderFunction<SideHandles, Props> = (props, ref) => {

    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const SetPage = ((page?: number) => {
        if (page === undefined) {
            forceUpdate();
        } else {
            if (page < props.Info.PageList.length && page >= 0) {
                props.Info.Page = page;
                forceUpdate();
            }
        }
    });

    useImperativeHandle(ref, () => ({
        SetPage
    }));

    //計算頁碼
    var ChineseNum = "〇一二三四五六七八九";
    var PageNumber = props.Info.Page + 1;
    var NumIndex = 0;
    var GroupList = [];

    var PageNumberY = 0;
    
    while (PageNumber >= 1) {
        PageNumberY = props.Info.PageHeight - (++NumIndex * props.Info.SideWidth * 0.6)
        GroupList.push(<text key={'PageNumber_' + NumIndex} x={props.Info.SideWidth * 0.2} y={PageNumberY} fontSize={props.Info.SideWidth * 0.6} fill={props.Color.Preview} className="no_select">{ChineseNum.substr(PageNumber % 10, 1)}</text>);
        PageNumber = PageNumber / 10;
    }

    let FontSize: number = props.Info.SideWidth * 0.6;

    //計算可以容納的字數
    var MaxWordCount = Math.floor((PageNumberY-(0.5 * FontSize * 1.2))/FontSize)-1;
   
    let Title: string = props.Info.Title ;
    if(Title.length+2 > MaxWordCount){
        Title = Title.substring(0,MaxWordCount-1) + "︙";
    }else{
        Title = "︽" + Title+ "︾";
    }
   
    for (var i = 0; i < Title.length; i++) {
        GroupList.push(<text key={"Title" + i} fill={props.Color.Preview} x={props.Info.SideWidth * 0.2} y={((i + 0.5) * FontSize * 1.2)} fontSize={FontSize} className="no_select">{Title.substr(i, 1)}</text>);
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" style={{cursor: "default"}} fontFamily={Storag.GetFontFamily()} width={props.Info.SideWidth} height={props.Info.PageHeight} >
            <line x1={0} y1={0} x2={0} y2={props.Info.PageHeight} stroke={props.Color.Preview} strokeWidth={1} />
            {GroupList}
        </svg>
    );
}

export default forwardRef(SutraSide);
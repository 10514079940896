import React, { useEffect } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import SutraTabs from '../components/SutraTabs';
import SutraList from '../components/SutraList';
import './Recite.css';
import i18n from '../library/i18n';
import Analytics from '../library/Analytics';
import { App } from '@capacitor/app';

const Recite: React.FC = () => {

    useEffect(() => {
        document.title = (i18n.getDataByLanguage("tw")?.translation.list as any).recite;
        Analytics.ScreenName(document.title,"Recite");

        App.addListener('backButton', () => {
            App.exitApp();
        });
    });

    return (
        <IonPage>
            <IonContent forceOverscroll={false}>
                <SutraTabs>
                    <SutraList Type="Recite"></SutraList>
                </SutraTabs>
            </IonContent>
        </IonPage>
    );

};

export default Recite;

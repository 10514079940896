import { FileTransfer, FileTransferError, FileTransferObject } from '@ionic-native/file-transfer';
import { File, FileEntry ,DirectoryEntry} from '@ionic-native/file';
import Storag from './Storag';
import Device from './Device';
import { Capacitor } from '@capacitor/core';

export enum DownloadError{
    FileTransfer,
    File,
    Fetch,
    Audio,
    JsonParse,
    FileNotFound,
    Error
}

class Download {

    static DownloadList(click:boolean, onSuccess: (json: any) => void, onError: (type:DownloadError, error: any) => void): void {
        var Path = Storag.GetUrlPath();
        
        
        
       

        if(Device.IsHybrid){
            let SourceName = "List.json";
            let TargetName = "List." + process.env.REACT_APP_VERSION + ".json";

            //APP
            Download.ExistFile(TargetName, (exist:boolean)=>{
                if(exist){
                   //檔案存在
                   Download.ReadFileJson(TargetName,(data)=>{
                        onSuccess(data);
                   },onError);

                    if(Storag.GetListTime() == 0){
                        Download.DownloadFile(Path, SourceName, TargetName , ()=>{
                            Storag.SetListTime(Date.now());
                        }, onError);
                    }else{
                        var Sec = (Date.now() - Storag.GetListTime())/1000;
                        //五分鐘內不重載
                        if(Sec > 60 * 5 || click){
                            Download.DownloadFile(Path, SourceName, TargetName, ()=>{
                                Storag.SetListTime(Date.now());
                            }, ()=>{});
                        }
                    }
                }else{
                    //檔案不存在
                    Download.DownloadFile(Path, SourceName, TargetName, ()=>{
                        Download.ReadFileJson(TargetName, (json: any)=>{
                            onSuccess(json);
                            Storag.SetListTime(Date.now());
                        } , onError);
                    }, onError);
                }
            });

        }else{
            let FileName = "List.json";
            //網頁
            Download.FetchJson(Path, FileName, onSuccess, onError);
        }
    }

    //遠端檔案路徑
    static DownloadJson(fileName: string, onSuccess: (json: any) => void, onError: (type:DownloadError, error: any) => void): void {
        var Path = Storag.GetJsonPath();
        if(Device.IsHybrid){
            //APP
            //不管檔案有沒有存在JSON都要試著抓最新的,沒有才抓本機的
            Download.DownloadFile(Path, fileName, fileName, ()=>{
                Download.ReadFileJson(fileName, onSuccess, onError); 
            }, (type:DownloadError, error:any)=>{
                //無法下載時 Check檔案存不存在
                Download.ExistFile(fileName,(exist:boolean)=>{
                    if(exist){
                        Download.ReadFileJson(fileName, onSuccess, onError); //檔案存在
                     }else{
                        onError(DownloadError.FileNotFound, null); //檔案不存在
                    }
                });
            });
        }else{
            //網頁
            Download.FetchJson(Path, fileName, onSuccess, onError);
        }
    }


    static DownloadAudio(path:string, fileName:string, onSuccess:(data: string)=>void, onProgress:(transfer:FileTransferObject, loaded: number, total: number)=>void, onError: (type:DownloadError, error: any) => void) {
        const HowlControl:boolean = Storag.GetHowlControl();
        if(Device.IsHybrid){
            //APP
            Download.ExistFile(fileName, (exist:boolean)=>{
                if(exist){
                    //檔案存在就直接讀檔讀檔
                    if(HowlControl){
                        //讓Howl自己處理載入
                        onSuccess(Capacitor.convertFileSrc(Storag.GetDataDirectory()) + fileName);
                    }else{
                        Download.ReadFileBase64(fileName, onSuccess, onError);
                    }
                }else{
                    //檔案不存在就下載檔案
                    Download.DownloadFile(path,fileName,fileName, ()=>{
                        if(HowlControl){
                            //讓Howl自己處理載入
                            onSuccess(Capacitor.convertFileSrc(Storag.GetDataDirectory()) + fileName);
                        }else{
                            Download.ReadFileBase64(fileName, onSuccess, onError);
                        }
                    }, onError, onProgress);
                }
            });
        }else{
            //網頁版 網頁版無法離線
            if(HowlControl){
                onSuccess(path + fileName);
            }else{
                Download.FetchBase64(path, fileName, onSuccess, onError);
            }
        }
    }


    //下載檔案
    private static DownloadFile(path:string, sourceName: string, targetName: string, onSuccess:()=>void, onError: (type:DownloadError, error: any) => void, onProgress?:(transfer:FileTransferObject, loaded: number, total: number)=>void){
        var Transfer: FileTransferObject = FileTransfer.create();
        //進度
        if(onProgress!=null){
            Transfer.onProgress((event)=>{
                onProgress(Transfer, event.loaded, event.total);
            });
        }
        Transfer.download(path + sourceName, Storag.GetDataDirectory() + sourceName + ".temp")
        .then((entry:FileEntry) => {
            //下載完後更改檔名
            Download.RenameFile(entry, targetName, ()=>{
                onSuccess();
            }, onError);
        }, (error:FileTransferError) => {
            onError(DownloadError.FileTransfer, error);
        });

   }

    //判斷檔案是否存在
    private static ExistFile(fileName:string, result:(exist:boolean)=>void){
        File.checkFile(Storag.GetDataDirectory() ,fileName).then(()=>{
            result(true); //存在
        }).catch(()=>{
            result(false); //不存在
        });  
    }

    //刪除檔案
    static DeleteFile(fileName:string, onSuccess:()=>void, onError:(error:any)=>void){
        Download.ExistFile(fileName,(exist)=>{
            if(exist){
                File.removeFile(Storag.GetDataDirectory(), fileName).then(onSuccess).catch(onError);
            }else{
                onSuccess();
            }
        })
    }

    //更名
    private static RenameFile(entry:FileEntry, newName:string, onSuccess:()=>void, onError: (type:DownloadError, error: any) => void){
         entry.getParent((dir: DirectoryEntry)=>{
            entry.moveTo(dir, newName,()=>{
                //更名完成
                onSuccess();
            },(error)=>{
                //更名錯誤
                onError(DownloadError.File, error);
            })
        });
    }

    //讀取檔案Base64格式
    private static ReadFileBase64(fileName:string, onSuccess:(data: string)=>void, onError: (type:DownloadError, error: any) => void){
        File.readAsDataURL(Storag.GetDataDirectory(), fileName)
        .then((data:string)=>{
            onSuccess(data);
        }).catch((error)=>{
            onError(DownloadError.File, error);
        });
    }
    
    //讀取檔案Json格式
    private static ReadFileJson(fileName:string, onSuccess:(data: any)=>void, onError: (type:DownloadError, error: any) => void){
        File.readAsText(Storag.GetDataDirectory(), fileName)
        .then((data:string)=>{
            try{
                onSuccess(JSON.parse(data));
            }catch(error){
                onError(DownloadError.JsonParse, error);
            }
            
        }).catch((error)=>{
            onError(DownloadError.File, error);
        });
    }

    //直接讀取網路檔案 Base64
    private static FetchBase64(path:string, fileName: string, onSuccess:(data: string)=>void,onError: (type:DownloadError, error: any) => void){
        fetch(path + fileName)
        .then(response => response.arrayBuffer())
        .then((data:ArrayBuffer) => {
             onSuccess("data:audio/mp4;base64," + Buffer.from(data).toString("base64"));
        }).catch((error:Error) => {
            onError(DownloadError.Fetch, error);
        });
    }


    //直接讀取網路檔案Json
    private static FetchJson(path:string, fileName: string, onSuccess:(data: any)=>void,onError: (type:DownloadError, error: any) => void){
        fetch(path + fileName)
        .then(response => response.json())
        .then((data) => {
            onSuccess(data);
        }).catch((error:Error) => {
            //找不到檔案會是SyntaxError
            onError(DownloadError.Fetch, error);
        });
    }

}

export default Download;
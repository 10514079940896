import { AppState, App, BackButtonListener } from "@capacitor/app";

class Listener {

    static OnBeforeInstallPrompt() {
        window.addEventListener('beforeinstallprompt', function (e: any) {
            e.userChoice.then(function (choiceResult: any) {
                console.log(choiceResult.outcome);
                if (choiceResult.outcome === 'dismissed') {
                    console.log('User cancelled home screen install');
                }
                else {
                    console.log('User added to home screen');
                }
            });
        });
    }


    static OnLoad(listener: (this: Window, ev: Event) => void) {
        window.addEventListener('load', listener);
    }

    static OnDeviceReady(listener: () => void) {
        document.addEventListener('deviceready', listener);
    }

    static OnTouchStart(listener: (e:Event) => void) {
        document.addEventListener('touchstart', listener);
    }

    static OnBackButton(event: BackButtonListener) {
        App.addListener('backButton', event);
    }

    static OnAppStateChange(event: (state: AppState) => void) {
        App.addListener('appStateChange', event);
    }

        //深色模式異動
    static OnDarkModeChange(event:(darkMode:boolean) => void): void {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener("change", (listEvent) => {
            event(listEvent.matches);
        });
    }
    
}

export default Listener;
import React from 'react';
import './SutraEdgeBottom.css';
import Info from '../library/Info';
import Device from '../library/Device';
import Color from '../library/Color';

interface Props {
    Info: Info;
    Color:Color;
    onClick(): void;
}

const SutraEdgeBottom: React.FC<Props> = (props) => {

    var ScreenWidth = Device.GetWidth();

    var ImageList = [];
    var Height = Math.floor(props.Info.EdgeHeight * 0.8);
    var Width = Math.floor((Height / 212) * 1200);
    var Count = Math.ceil(ScreenWidth / Width);
    if (Count % 2 === 0)
        Count = Count + 1;

    var Diff = ((Width * Count) - ScreenWidth) / 2;
    
    for (var i = 0; i < Count; i++) {
        if (Math.floor(Count / 2) === i) {
            ImageList.push(<img src="/assets/edge_menu.svg" key={"bottom_" + i} alt="" style={{ position: "absolute", bottom: 0, height: Height, width: Width, left: Width * i - Diff, opacity: props.Color.Opacity, filter: props.Color.Invert?"invert(1)":"" }} className="no_drag" />);
            ImageList.push(<img src="/assets/menu_flower.svg" key={"bottom_menu"} alt="" style={{ position: "absolute", bottom: 0, height: props.Info.EdgeHeight * 0.9, width: props.Info.EdgeHeight * 0.9, left: Width * i - Diff + Width * 0.41, filter: props.Color.Invert?"invert(1)":""}} className="menu" onClick={(e)=>{props.onClick()}} />);
        } else {
            ImageList.push(<img src="/assets/edge_bottom.svg" key={"bottom_" + i} alt="" style={{ position: "absolute", bottom: 0, height: Height, width: Width, left: Width * i - Diff, opacity: props.Color.Opacity, filter: props.Color.Invert?"invert(1)":""}} className="no_drag" />);
        }

    }

    return (
        <div style={{ height: props.Info.EdgeHeight, userSelect: "none" }}>
            {ImageList}
        </div>
    );
}

export default SutraEdgeBottom;
import { IonContent, IonPage} from '@ionic/react';
import SutraTabs from '../components/SutraTabs';
import SutraSetting from '../components/SutraSetting';
import './Setting.css';
import React, { useEffect } from 'react';
import i18n from '../library/i18n';
import Analytics from '../library/Analytics';

const Setting: React.FC = () => {
    
    useEffect(() => {
        document.title = (i18n.getDataByLanguage("tw")?.translation.list as any).setting;
        Analytics.ScreenName(document.title,"Setting");
    });

    return (
        <IonPage>
            <div className="statusbar"></div>
             <IonContent forceOverscroll={false}>
                 <SutraTabs>
                     <IonContent>
                         <SutraSetting></SutraSetting>
                     </IonContent>
                 </SutraTabs>
             </IonContent>
        </IonPage>
    );
};

export default Setting;

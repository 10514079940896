import { IonContent, IonLabel, IonIcon, IonItem, IonList, IonCard, IonCardTitle, IonCardHeader, IonCardContent, IonButton, IonSpinner, IonToggle, useIonToast } from '@ionic/react';
import React, { useState, useEffect, useReducer } from 'react';
import './SutraList.css';
import { book, bookOutline, reloadOutline, volumeMuteOutline, volumeHighOutline, pause, play, trashOutline, settingsOutline, eyeOutline, eyeOffOutline, closeOutline, addOutline, warningOutline} from 'ionicons/icons';
import { useHistory } from "react-router-dom";
import Storag, { AppType, SutraStage } from '../library/Storag';
import i18n from '../library/i18n';
import moment from 'moment';
import 'moment/locale/zh-tw';
import Info from '../library/Info';
import Download from '../library/Download';
import Device from '../library/Device';
import Shortcut from '../library/Shortcut';

if(Storag.GetLanguage() == "en"){
    moment.locale('en');
}else{
    moment.locale('zh-tw');
}

export interface SutraContent {
    UpdateNotification: boolean;
    SutraList: SutraItem[];
}


export interface SutraItem {
    Title_tw: string;
    Title_cn: string;
    Title_en: string;
    Code: string;
    Audio?: string;
    Volume?: number;
    Type: string;
}

enum LoadStatus {
    None,
    Error,
    Success,
    Loading
}

interface ListItem {
    Data: SutraItem;
    Storag: SutraStage | null;
}

let SourceContent: SutraContent;

async function PresentToast(message: string) {
    const toast = await document.createElement('ion-toast');
    toast.message = message;
    toast.duration = 2000;

    document.body.appendChild(toast);
    return await toast.present();
}

interface Props {
    Type: string;
}

var Language = Storag.GetLanguage();

const SutraList: React.FC<Props> = (props) => {

    const [Status, SetStatus] = useState(LoadStatus.None);
    const [SoundStatus, SetSoundStatus] = useState(Storag.GetSoundStatus());
    const [PlayStatus, SetPlayStatus] = useState(Storag.GetPlayStatus());
    const [EditStatus, SetEditStatus] = useState(false);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    let History = useHistory();

    const Toast = useIonToast()[0];

    useEffect(() => {
        if (Status === LoadStatus.None) {
            Reload(false);
        }
    });

    const Reload = (click:boolean) => {
        Download.DownloadList(click,(json:SutraContent)=>{
            SourceContent = json;
            SetStatus(LoadStatus.Success);
            return;
        },(error)=>{
            SetStatus(LoadStatus.Error);
            return;
        });

        SetStatus(LoadStatus.Loading);
    };

    const Sort = () => {
        var Type = AppType[Storag.GetAppType()].toString().toLowerCase();

        return SourceContent.SutraList
            .filter((item, index, array) => { return (((props.Type === "Recite" && item.Audio != undefined) || (props.Type === "Read" && item.Audio === undefined)) && (Storag.GetSutraDisplay(item.Code) || EditStatus) && (item.Type=="base" || (item.Type==Type))); })
            
            .map(x => { return { "Data": x, "Storag": Storag.GetSutraStage(x.Code) } })
            .sort(Compare)
            .map(x => (Item(x.Data, x.Storag)));
    };


    const Compare = (x: ListItem, y: ListItem) => {
        if (x.Storag === null && y.Storag == null)
            return 0;

        if (x.Storag === null)
            return 1;

        if (y.Storag === null)
            return -1;


        if (x.Storag.Date === null)
            return 1;

        if (y.Storag.Date === null)
            return -1;

        if (x.Storag.Date > y.Storag.Date)
            return -1;

        if (x.Storag.Date < y.Storag.Date)
            return 1;

        return 0;
    };


    const Go = (code: string) => {
        //Storag.SetPlayStatus(true);
        if(!EditStatus){
            History.push("/Book/" + code);
            //window.location.href = "/Book/" + code;
        }
           
    };

    const Display = (code:string, sutraName:string, show:boolean) => {
        Storag.SetSutraDisplay(code, show);
        Toast(i18n.t("list.item_" + (show?"show":"hide"), { name: sutraName }), 1500);
        forceUpdate();
    }

    const Delete = (code:string, sutraName:string, volume?:number, totalVolume?:number) => {
        if(Device.IsHybrid){
            Download.DeleteFile(code + ".json",()=>{
                Download.DeleteFile(code + ".m4a",()=>{
                    Storag.DelSutraStage(code, volume, totalVolume);

                    if(Storag.GetSutraStage(code) == null){
                        Toast(i18n.t("list.item_audio_delete", { name: sutraName }), 1500);
                    }else{
                        Toast(i18n.t("list.item_audio_stage_delete", { name: sutraName }), 1500);
                    }
                    
                    forceUpdate();
                },(error:any)=>{
                    Toast(i18n.t("list.item_audio_delete_error", { name: sutraName }), 1500);
                });
            },(error:any)=>{
                Toast(i18n.t("list.item_audio_delete_error", { name: sutraName }), 1500);
            });
        }else{
            Storag.DelSutraStage(code, volume, totalVolume);
            Toast(i18n.t("list.item_stage_delete", { name: sutraName }), 1500);
            forceUpdate();
        }
    };

    const Item = (item: SutraItem, stage: SutraStage | null) => {
        var SutraName = Storag.GetLanguage() === "cn" ? item.Title_cn : item.Title_tw;
        var SoundType: JSX.Element[] = [];
        if(EditStatus){
            var ItemDisplay = Storag.GetSutraDisplay(item.Code);
            if(Storag.GetAppType() == AppType.Full)
                if(item.Volume == null)
                    if(Shortcut.Supported){
                        SoundType.push(
                            <IonButton key={item.Code + "_shortcut"} color="light" onClick={(e) => {Shortcut.AddShortcut(item.Code, SutraName) }}>
                                <IonIcon icon={addOutline} />
                            </IonButton>
                        );
                    }

            SoundType.push(
                <IonButton key={item.Code + "_display"} color="light" onClick={(e) => { Display(item.Code, SutraName, !ItemDisplay) }}>
                    <IonIcon icon={ItemDisplay?eyeOutline:eyeOffOutline} />
                </IonButton>
            );

            if(item.Volume == null)
                SoundType.push(
                    <IonButton key={item.Code + "_del"} color="light" onClick={(e) => { Delete(item.Code, SutraName) }}>
                        <IonIcon icon={trashOutline} />
                    </IonButton>
                );
        }else{
            /*
            switch (item.Audio) {
                case "male":
                    SoundType.push(<IonIcon icon={manOutline} />);
                    break;
                case "female":
                    SoundType.push(<IonIcon icon={womanOutline} />);
                    break;
                case "both":
                    SoundType.push(<IonIcon icon={peopleOutline} />);
                    break;
            }
            */
            
            if (item.Audio == null)
                SoundType.push(<IonIcon key={item.Code + "_sound"} icon={volumeMuteOutline} />);
            else {
                if (SoundStatus) {
                    if (!PlayStatus)
                        SoundType.push(<IonIcon icon={pause} />);
                    SoundType.push(<IonIcon key={item.Code + "_sound"} icon={volumeHighOutline} />);
                } else {
                    SoundType.push(<IonIcon key={item.Code + "_sound"} icon={volumeMuteOutline} />);
                }
            }
        }
        
        if (item.Volume == null) {
            return (<IonItem key={item.Code} button={true} onClick={() => { Go(item.Code); }}>
                <IonIcon icon={stage === null?bookOutline:book} slot="start" />
                <IonLabel>
                    <h2>{SutraName}<span style={{ fontSize: "70%", color: "grey" }}>{stage == null ? "" : " (" + moment(stage.Date).fromNow(false) + ")"}</span></h2>
                    <h3>{item.Title_en}</h3>
                </IonLabel>
                {SoundType}
            </IonItem>);
        } else {
            let ButtonList = [];

            for (let i = 0; i < item.Volume; i++) {
                let SubCode = item.Code + "_" + (i + 1);
                let SubStage = Storag.GetSutraStage(SubCode);
                let SubSutraName:string = i18n.t("list.volume",{volume:Info.GetVolumeString((i + 1), item.Volume, Language)});
                let ShortcutName:string = i18n.t("list.volume",{volume:Info.GetShortcutVolumeString((i + 1), item.Volume, Language)});

                let SubButtonList = [];
                if (SubStage == null) {
                    SubButtonList.push(
                        <IonButton key={item.Code + "_" + i + "_del"} onClick={() => {Go(SubCode); }} disabled={EditStatus} color="light" size="small">
                            {SubSutraName}
                        </IonButton>
                    );
                } else {
                    let Volume: number | null = null;
                    if (stage != null)
                        Volume = stage.Volume;
                    SubButtonList.push(
                        <IonButton key={item.Code + "_" + i + "_del"} onClick={() => {Go(SubCode);}} disabled={EditStatus} color={Volume == (i + 1) ? "primary" : "dark"} size="small">
                            {SubSutraName}
                        </IonButton>
                    );
                }


                if(EditStatus){
                    SubButtonList.push(
                        <IonButton key={item.Code + "_" + i + "_del"} onClick={() => {EditStatus?Delete(item.Code, (SutraName+SubSutraName), (i + 1), item.Volume):Go(SubCode); }} color="light" size="small">
                            <IonIcon icon={trashOutline}/>
                        </IonButton>
                    );
                    
                    if(Storag.GetAppType() == AppType.Full)
                        if(Shortcut.Supported){
                            SubButtonList.push(
                                <IonButton key={item.Code + "_" + i + "_shortcut"} color="light" onClick={(e) => {Shortcut.AddShortcut(SubCode, SutraName+ShortcutName) }}>
                                    <IonIcon icon={addOutline} />
                                </IonButton>
                            );
                        }
                }
                ButtonList.push(<span key={item.Code + "_" + i} style={{whiteSpace:"nowrap",display: "inline-flex", paddingRight:EditStatus?"8px":"0px"}}>{SubButtonList}</span>);
            }
            
            return (
                [
                    <IonItem key={item.Code} button={true} lines="none">
                        {stage === null ? <IonIcon key={item.Code + "_book"} icon={bookOutline} slot="start" /> : <IonIcon icon={book} slot="start" />}
                        <IonLabel>
                            <h2>{SutraName} {item.Volume != null ? i18n.t("list.total_volume", { volume: item.Volume }) : ""}<span style={{ fontSize: "70%", color: "grey" }}>{stage == null ? "" : " (" + moment(stage.Date).fromNow(false) + (stage.Volume == null ? "" : (", " + i18n.t("list.volume") + Info.GetVolumeString((stage.Volume), item.Volume, Language))) + ")"}</span></h2>
                            <h3>{item.Title_en}</h3>
                        </IonLabel>
                        {SoundType}
                    </IonItem>
                    ,
                    <IonItem key={item.Code + "_Button"} button={false}>
                        <span slot="start" />
                        <div style={{width:"100%"}}>
                            {ButtonList}
                        </div>
                    </IonItem>
                ]
            );

        }

    };



    if (Status === LoadStatus.Success) {
        
        if(SourceContent.UpdateNotification){
            return (
                <span>
                    <div className="statusbar"></div>
                    <IonCard>
                        <div style={{ padding: 0, textAlign: "center" }} />
                        <IonCardHeader>
                            <IonCardTitle><span style={{color:"#ac1400"}}><IonIcon icon={warningOutline}></IonIcon> {i18n.t("list.updated")}</span></IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            {i18n.t("list.update_notification")}
                        </IonCardContent>
                    </IonCard>
                </span>
            );
        }

        return (
            <IonContent>
                <div className="statusbar"></div>
                <IonList>
                    <IonItem>
                        <IonButton color="light" style={{zoom:1.5}} hidden={EditStatus} onClick={(e) => { SetEditStatus(true); }}>
                            <IonIcon icon={settingsOutline} />
                        </IonButton>
                        <IonButton color="light" style={{zoom:1.5}} hidden={!EditStatus} onClick={(e) => { SetEditStatus(false); }}>
                            <IonIcon icon={closeOutline} />
                        </IonButton>
                        <IonButton slot="end" color="light" style={{zoom:1.5}} disabled={!SoundStatus} onClick={(e) => { Storag.SetPlayStatus(!PlayStatus); SetPlayStatus(!PlayStatus); }} hidden={props.Type != "Recite" || EditStatus}>
                            <IonIcon icon={PlayStatus ? pause : play} />
                        </IonButton>
                        <IonIcon slot="end" icon={volumeMuteOutline}  hidden={props.Type != "Recite" || EditStatus}/>
                        <IonToggle slot="end" style={{zoom:0.9}} checked={SoundStatus} onIonChange={(e) => { Storag.SetSoundStatus(e.detail.checked); SetSoundStatus(e.detail.checked); PresentToast(e.detail.checked ? i18n.t("list.sound_status_open") : i18n.t("list.sound_status_close")); }}  hidden={props.Type != "Recite" || EditStatus}></IonToggle>
                        <IonIcon slot="end" icon={volumeHighOutline}  hidden={props.Type != "Recite" || EditStatus}/>
                    </IonItem>
                    {Sort()}
                </IonList>
            </IonContent>
        );
    } else if (Status === LoadStatus.Error) {
        return (
            <span>
                <div className="statusbar"></div>
                <IonCard>
                    <div style={{ padding: 0, textAlign: "center" }} />
                    <IonCardHeader>
                        <IonCardTitle><IonIcon icon={warningOutline}></IonIcon> {i18n.t("list.list_error")}</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        {i18n.t("list.error_retry")}<IonButton onClick={()=>{Reload(true);}}><IonIcon slot="start" icon={reloadOutline} />{i18n.t("list.retry")}</IonButton>
                    </IonCardContent>
                </IonCard>
            </span>
        );
    }

    return (
        <span>
            <div className="statusbar"></div>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>
                        <IonCardTitle><IonSpinner></IonSpinner>{i18n.t("list.list_loding")}</IonCardTitle>
                    </IonCardTitle>
                </IonCardHeader>
            </IonCard>
        </span>
    );

};

export default SutraList;

import i18next from 'i18next';
import Storag from './Storag';

i18next
    .init({
        interpolation: {
            // React already does escaping
            escapeValue: false,
        },
        lng: Storag.GetLanguage(),
        // Using simple hardcoded resources for simple example
        resources: {
            tw: {
                translation: {
                    menu: {
                        play: "繼續播放",
                        pause: "暫停播放",
                        play_status: "播放狀態",
                        rate: "播放速率",
                        rate1: "稍慢",
                        rate2: "略慢",
                        rate3: "正常速度",
                        rate4: "略快",
                        rate5: "稍快",
                        rate6: "甚快",
                        rate7: "最快",
                        exit: "返回列表",
                        setting: "設定",
                        info: "資訊",
                        sutra_setting: "經文設定",
                        device_info: "裝置資訊",
                        chapter: "章卷頁面",
                        search: "搜尋",
                        clear: "清除",
                        search_input: "請輸入查詢文字",
                        search_over: "查詢僅顯示50筆以內之資料",
                        select_chapter: "選擇章節",
                        select_page: "選擇頁數",
                        select_volume: "選擇卷數",
                        page: "第{{page}}頁",
                        repeat: "重複播放",
                        preview: "顯示預覽列",
                        phonetic: "顯示注音",
                        phonetic_0: "不顯示",
                        phonetic_1: "注音",
                        phonetic_2: "拼音",
                        phonetic_3: "拼音(轉置)",
                        font: "選擇字型",
                        font_size: "字體大小",
                        size_large: "大",
                        size_small: "小",
                        theme: "顏色樣式",
                        theme_display: "樣式預覽",
                        resume_auto: "自動回復進度",
                        resume_resume: "回復上次進度",
                        resume_start: "從頭開始播放",
                        resume_ask: "每次都問我",
                        resume_ask_once: "下次再問我",
                        resume_dont_ask: "下次不再問我",
                        resume_message: "是否從上次進度開始？",
                        volume: "卷",
                        volume_num: "卷{{num}}",
                        next_volume: "下一卷",
                        pre_volume: "上一卷",
                        ask_next_volume: "是否跳到<strong>後一卷({{num}}卷)</strong>？",
                        ask_pre_volume: "是否跳到<strong>前一卷({{num}}卷)</strong>？",
                        file_path: "檔案路徑",
                        report_problem: "回報問題",
                        report_text: "送出回報",
                        report_text_body: "請直接填寫回報內容\n(回報內容僅提供參考，仍需以實際狀況為主。)",
                        report_ok: "已送出！",
                        report_fail: "送出發生錯誤！"
                    },
                    list: {
                        recite: "梵唄",
                        read: "經文",
                        help: "說明",
                        setting: "資訊",
                        list_loding: "載入列表中...",
                        list_error: "列表載入錯誤！",
                         error_retry: "列表無法載入或載入錯誤，您可以試著重新載入！",
                         updated: "已有新版本！",
                         update_notification: "請至App Store/Google Play更新至最新版本！",
                        item_hide: "將「{{name}}」設為隱藏",
                        item_show: "將「{{name}}」設為顯示",
                        item_audio_delete: "已清除「{{name}}」之離線聲音檔",
                        item_stage_delete: "已清除「{{name}}」之進度紀錄",
                        item_audio_stage_delete: "已清除「{{name}}」之進度紀錄及離線聲音檔",
                        item_audio_delete_error: "清除「{{name}}」之進度紀錄時發生錯誤！",
                        retry: "重試",
                        sound_status_open: "設定為「梵唄」模式，使用經本時將載入聲音檔。",
                        sound_status_close: "設定為「經文」模式，使用經本時無聲音檔",
                        total_volume: "[共{{volume}}卷]",
                        volume: "{{volume}}卷"
                    },
                    book: {
                        audio_loading: "聲音檔下載中....",
                        audio_offline_message: "※經文下載後即可「離線」播放",
                        sutra_loaded: "已載入完成，任意點選後進入經文！",
                        sutra_not_load: "無法載入經文資訊！",
                        sutra_loading: "載入經文資料中...",
                        sutra_not_connection:"無網路連線！"
                    },
                    info: {
                        app_name:"佛經誦讀",
                        sutra_name: "經文名稱",
                        ok:"確認",
                        cancel:"取消",
                        language: "設定語言",
                        version: "版本",
                        base_version: "基本版",
                        full_version: "完整版",
                        test_version: "測試版",
                        browse: "瀏覽器",
                        system: "作業系統",
                        device: "裝置類型",
                        view_size: "畫面大小",
                        screen_size: "螢幕大小",
                        model: "型號",
                        manufacturer: "廠牌",
                        system_version: "作業系統版本",
                        virtual: "模擬器",
                        pwa: "PWA",
                        app: "APP",
                        webaudio: "WebAudio",
                        service_worker: "緩存機制",
                        use_service_worker: "使用緩存",
                        wakelock: "WakeLock",
                        howl_control: "Howl控管載入檔",
                        useragent: "UserAgent",
                        font_sung: "正宋體",
                        font_kai: "正楷體",
                        font_ming: "明體",
                        font_weibei: "魏碑體",
                        font_kleeone: "Klee One",
                        font_system: "(系統字型)",
                        developer_mode: "開發者模式",
                        developer_mode_open: "開啟開發者模式",
                        developer_mode_close: "關閉開發者模式",
                        yes: "是",
                        no: "否",
                        supported: "支援",
                        unsupported: "不支援",
                        used: "使用",
                        unused: "未使用"
                    },
                    stote: {
                        buy:"購買",
                        donate:"小額捐助",
                        buy_message:"歡迎您透過購買機制來捐助本APP。",
                        donate_message:"透過「Buy Me a Coffee」來捐助。",
                        purchase:"購買",
                        instruction: "購買/說明",
                        instruction_message: "購買說明：<ul><li>您可透過購買的方式捐助本程式開發者，但是請於確認購買前審慎考慮。</li><li>本程式<b>不存在</b>強迫購買及下載之情況，是否購買<b>不會</b>影響經文的下載。</li><li>若無法下載經文時請先確認網路狀況，若是主機異常時請稍待。與購買與否無關！</li><li>「佛經誦讀」為個人開發及負擔維護成本，您的購買/捐助對於「佛經誦讀」的維持將有很大的幫助！</li><li>購買後將提供若干完整版本獨有功能以感謝完整版使用者的贊助。</li><li><b>基本版</b>與<b>完整版</b>功能差異不大，僅以此些功能感謝贊助者。</li><li>「恢復購買」用於已購買但未自動變更為完整版時使用。(ex.更換手機時使用)</li></ul>完整版感謝功能：<ol><li>自訂經文主題顏色</li><li>經文搜尋功能</li><li>慢速播放選項</li><li>專屬字體(Klee One)</li><li>更多的經文，優先使用新上線之經文。</li><li>重複播放功能</li><li>加入捷徑功能，可透過捷徑快速開啟指定經文。</li></ul>",
                        android_message:"2020/03/30前之購買，因設定問題Google皆已三日後自動退款，欲購買者需重新購買！",
                        purchased: "已購買",
                        restore: "恢復購買",
                        restored: "已恢復購買"
                    },
                    help: {
                        orientation: "您可以依照裝置狀況，直置使用本程式，也可以翻轉橫置使用。",
                        device: "平板與手機皆適合使用，手機建議直置使用，平板建議橫置使用。",
                        swipe: "透過左右滑動可切換前後頁面，由左至右滑動可切換至下一頁，由右至左滑動可切換至上一頁。",
                        pace: "使用時系統會自動與經文同步，且唱誦到的文字會強調顯示，若有需求亦可點選文字可直接跳至該段播放。",
                        preview: "每頁的最末行灰色文字為次頁第一行，系統念誦到此行系統將自動翻頁，免除傳統紙本翻頁及找不到經咒文字的困擾。",
                        light: "經咒播放過程之中，螢幕將保持喚醒，使畫面持續顯示，以利於念誦使用。於念誦完畢後回歸系統預設。",
                        menu: "點選下方蓮花圖示可開啟功能設定選單。",
                        play: "透過設定選單的「暫停播放」/「繼續播放」可以得知目前的播放狀況並進行操作，於下次啟動程式後會自動套用此操作。",
                        set_font_size: "經文內容支援字體明體、正楷體、正宋體、魏碑體顯示。",
                        set_font: "在不同裝置上預設最適合的文字大小，可透過設定調整字級大小。",
                        set_theme: "可設定經文顏色配置。",
                        set_preview: "開啟或關閉經文最後一列之預覽行。",
                        set_rate: "調整經文唱誦之速度。",
                        set_repeat: "經文唱誦後自動重複播放。",
                        set_resume_auto: "勾選「自動回復進度」，下次使用本程式時將會回復至上次的進度點。",
                        select_page: "可跳至選取之頁數。",
                        select_chapter: "可跳至選取之章節。"
                    }
                }
            },
            cn: {
                translation: {
                    menu: {
                        play: "继续播放",
                        pause: "暂停播放",
                        play_status: "播放状态",
                        rate: "播放速率",
                        rate1: "稍慢",
                        rate2: "略慢",
                        rate3: "正常速度",
                        rate4: "略快",
                        rate5: "稍快",
                        rate6: "甚快",
                        rate7: "最快",
                        exit: "返回列表",
                        setting: "设定",
                        info: "资讯",
                        sutra_setting: "经文设定",
                        device_info: "装置资讯",
                        chapter: "章卷页面",
                        search: "搜寻",
                        clear: "清除",
                        search_input: "请输入查询文字",
                        search_over: "查詢僅顯示50筆以內之資料",
                        select_chapter: "选择章节",
                        select_page: "选择页数",
                        select_volume: "选择卷数",
                        page: "第{{page}}页",
                        repeat: "重复播放",
                        preview: "显示预览列",
                        phonetic: "显示注音",
                        phonetic_0: "不显示",
                        phonetic_1: "注音",
                        phonetic_2: "拼音",
                        phonetic_3: "拼音(转置)",
                        font: "选择字型",
                        font_size: "字体大小",
                        size_large: "大",
                        size_small: "小",
                        theme: "颜色样式",
                        theme_display: "样式预览",
                        resume_auto: "自动回复进度",
                        resume_resume: "回复上次进度",
                        resume_start: "从头开始播放",
                        resume_ask: "每次都问我",
                        resume_ask_once: "下次再问我",
                        resume_dont_ask: "下次不再问我",
                        resume_message: "是否从上次进度开始？",
                        volume: "卷",
                        volume_num: "卷{{num}}",
                        next_volume: "下一卷",
                        pre_volume: "上一卷",
                        ask_next_volume: "是否跳到<strong>后一卷({{num}}卷)</strong>？",
                        ask_pre_volume: "是否跳到<strong>前一卷({{num}}卷)</strong>？",
                        file_path: "档案路径",
                        report_problem: "回報問題",
                        report_text: "送出回报",
                        report_text_body: "请直接填写回报内容\n(回报内容仅提供参考，仍需以实际状况为主。)",
                        report_ok: "已送出！",
                        report_fail: "送出发生错误！"
                    },
                    list: {
                        recite: "梵呗",
                        read: "经文",
                        help: "说明",
                        setting: "资讯",
                        list_loding: "载入列表中...",
                        list_error: "列表载入错误！",
                        error_retry: "列表无法载入或载入错误，您可以试着重新载入！",
                        updated: "已有新版本！",
                        update_notification: "請至App Store/Google Play更新至最新版本！",
                        item_hide: "将「{{name}}」设为隐藏",
                        item_show: "将「{{name}}」设为显示",
                        item_audio_delete: "已清除「{{name}}」之离线声音档",
                        item_stage_delete: "已清除「{{name}}」之进度纪录",
                        item_audio_stage_delete: "已清除「{{name}}」之进度纪录及离线声音档",
                        item_audio_delete_error: "清除「{{name}}」之进度纪录时发生错误！",
                        retry: "重试",
                        sound_status_open: "设定为「梵呗」模式，使用经本时将载入声音档。 ",
                        sound_status_close: "设定为「经文」模式，使用经本时无声音档",
                        total_volume: "[共{{volume}}卷]",
                        volume: "{{volume}}卷"
                    },
                    book: {
                        audio_loading: "声音档下载中....",
                        audio_offline_message: "※经文下载后即可「离线」播放",
                        sutra_loaded: "已载入完成，任意点选后进入经文！",
                        sutra_not_load: "无法载入经文资讯！",
                        sutra_loading: "载入经文资料中...",
                        sutra_not_connection:"无网路连线！"
                    },
                    info: {
                        app_name:"佛经诵读",
                        sutra_name: "经文名称",
                        ok:"确认",
                        cancel:"取消",
                        language: "设定语言",
                        version: "版本",
                        base_version: "基本版",
                        full_version: "完整版",
                        test_version: "测试版",
                        browse: "浏览器",
                        system: "作业系统",
                        device: "装置类型",
                        view_size: "画面大小",
                        screen_size: "萤幕大小",
                        model: "型号",
                        manufacturer: "厂牌",
                        system_version: "作业系统版本",
                        virtual: "模拟器",
                        pwa: "PWA",
                        app: "APP",
                        webaudio: "WebAudio",
                        service_worker: "缓存机制",
                        use_service_worker: "使用缓存",
                        wakelock: "WakeLock",
                        howl_control: "Howl控管载入档",
                        useragent: "UserAgent",
                        font_sung: "正宋体",
                        font_kai: "正楷体",
                        font_ming: "明体",
                        font_weibei: "魏碑体",
                        font_kleeone: "Klee One",
                        font_system: "(系统字型)",
                        developer_mode: "开发者模式",
                        developer_mode_open: "开启开发者模式",
                        developer_mode_close: "关闭开发者模式",
                        yes: "是",
                        no: "否",
                        supported: "支援",
                        unsupported: "不支援",
                        used: "使用",
                        unused: "未使用"
                    },
                    stote: {
                        buy:"购买",
                        donate:"小额捐助",
                        buy_message:"欢迎您透过购买机制来捐助本APP。",
                        donate_message:"透过「Buy Me a Coffee」来捐助。",
                        purchase:"购买",
                        instruction: "购买/说明",
                        instruction_message: "购买说明：<ul><li>您可透过购买的方式捐助本程式开发者，但是请于确认购买前审慎考虑。</li><li>本程式<b>不存在</b>强迫购买及下载之情况，是否购买<b>不会</b>影响经文的下载。</li><li>若无法下载经文时请先确认网路状况，若是主机异常时请稍待。与购买与否无关！</li><li>「佛经诵读」为个人开发及负担维护成本，您的购买/捐助对于「佛经诵读」的维持将有很大的帮助！ </li><li>购买后将提供若干完整版本独有功能以感谢完整版使用者的赞助。 </li><li><b>基本版</b>与<b>完整版</b>功能差异不大，仅以此些功能感谢赞助者。 </li><li>「恢复购买」用于已购买但未自动变更为完整版时使用。(ex.更换手机时使用)</li></ul>完整版感谢功能：<ol><li>自订经文主题颜色</li><li>经文搜寻功能</li><li>慢速播放选项</li><li>专属字体(Klee One)</li><li>更多的经文，优先使用新上线之经文。 </li><li>重复播放功能</li><li>加入捷径功能，可透过捷径快速开启指定经文。 </li></ul>",
                        android_message:"2020/03/30前之購買，因設定問題Google皆已三日後自動退款，欲購買者需重新購買！",
                        purchased: "已购买",
                        restore: "恢复购买",
                        restored: "已恢复购买"
                    },
                    help: {
                        orientation: "您可以依照装置状况，直置使用本程式，也可以翻转横置使用。",
                        device: "平板与手机皆适合使用，手机建议直置使用，平板建议横置使用。",
                        swipe: "透过左右滑动可切换前后页面，由左至右滑动可切换至下一页，由右至左滑动可切换至上一页。",
                        pace: "使用时系统会自动与经文同步，且唱诵到的文字会强调显示，若有需求亦可点选文字可直接跳至该段播放。",
                        preview: "每页的最末行灰色文字为次页第一行，系统念诵到此行系统将自动翻页，免除传统纸本翻页及找不到经咒文字的困扰。",
                        light: "经咒播放过程之中，萤幕将保持唤醒，使画面持续显示，以利于念诵使用。于念诵完毕后回归系统预设。",
                        menu: "点选下方莲花图示可开启功能设定选单。",
                        play: "透过设定选单的「暂停播放」/「继续播放」可以得知目前的播放状况并进行操作，于下次启动程式后会自动套用此操作。 ",
                        set_font_size: "经文内容支援字体明体、正楷体、正宋体、魏碑体显示。",
                        set_font: "在不同​​装置上预设最适合的文字大小，可透过设定调整字级大小。",
                        set_theme: "可设定经文颜色配置。",
                        set_preview: "开启或关闭经文最后一列之预览行。",
                        set_rate: "调整经文唱诵之速度。",
                        set_repeat: "经文唱诵后自动重复播放。",
                        set_resume_auto: "勾选「自动回复进度」，下次使用本程式时将会回复至上次的进度点。 ",
                        select_page: "可跳至选取之页数。",
                        select_chapter: "可跳至选取之章节。"
                    }
                }
            },
            en: {
                translation: {
                    menu: {
                        play: "Play",
                        pause: "Pause",
                        play_status: "Status",
                        rate: "Speed",
                        rate1: "Slightly slow",
                        rate2: "Slower",
                        rate3: "Normal",
                        rate4: "Slightly fast",
                        rate5: "Faster",
                        rate6: "Very fast",
                        rate7: "Fastest ",
                        exit: "Return",
                        setting: "Format",
                        info: "Information",
                        sutra_setting: "Sutra Format",
                        device_info: "Device Information",
                        chapter: "Chapter",
                        search: "Search",
                        clear: "clear",
                        search_input: "請輸入查詢文字",
                        search_over: "查詢僅顯示50筆以內之資料",
                        select_chapter: "Chapters",
                        select_page: "Pages",
                        select_volume: "Volumes",
                        page: "第{{page}}頁",
                        repeat: "Repeat",
                        preview: "Preview column",
                        phonetic: "Phonetic",
                        phonetic_0: "None",
                        phonetic_1: "Bopomofo",
                        phonetic_2: "Pinyin",
                        phonetic_3: "Pinyin(Rotate)",
                        font: "Font",
                        font_size: "Font size",
                        size_large: "Large",
                        size_small: "Small",
                        theme: "Theme",
                        theme_display: "Preview",
                        resume_auto: "Resume",
                        resume_resume: "Last position",
                        resume_start: "From beginning",
                        resume_ask: "Always ask me",
                        resume_ask_once: "Ask me next time",
                        resume_dont_ask: "Don't ask me again",
                        resume_message: "是否從上次進度開始？",
                        volume: "Volume",
                        volume_num: "Vol.{{num}}",
                        next_volume: "Next volume",
                        pre_volume: "Previous volume",
                        ask_next_volume: "是否跳到<strong>後一卷({{num}}卷)</strong>？",
                        ask_pre_volume: "是否跳到<strong>前一卷({{num}}卷)</strong>？",
                        file_path: "File path",
                        report_problem: "回報問題",
                        report_text: "送出回報",
                        report_text_body: "請直接填寫回報內容\n(回報內容僅提供參考，仍需以實際狀況為主。)",
                        report_ok: "已送出！",
                        report_fail: "送出發生錯誤！"
                    },
                    list: {
                        recite: "Recite",
                        read: "Read",
                        help: "Help",
                        setting: "Settings",
                        list_loding: "List loading...",
                        list_error: "Failed to load list!",
                        error_retry: "Unable to load list, Please try again!",
                        updated: "已有新版本！",
                        update_notification: "請至App Store/Google Play更新至最新版本！",
                        item_hide: "The \"{{name}}\" was hidden.",
                        item_show: "The \"{{name}}\" was shown",
                        item_audio_delete: "已清除「{{name}}」之離線聲音檔",
                        item_stage_delete: "已清除「{{name}}」之進度紀錄",
                        item_audio_stage_delete: "已清除「{{name}}」之進度紀錄及離線聲音檔",
                        item_audio_delete_error: "清除「{{name}}」之進度紀錄時發生錯誤！",
                        retry: "Retry",
                        sound_status_open: "設定為「梵唄」模式，使用經本時將載入聲音檔。",
                        sound_status_close: "設定為「經文」模式，使用經本時無聲音檔",
                        total_volume: "[Total: {{volume}} volumes]",
                        volume: "Vol.{{volume}}",
                    },
                    book: {
                        audio_loading: "Audio downloading....",
                        audio_offline_message: "※經文下載後即可「離線」播放",
                        sutra_loaded: "Touching screen to enter into the sutra...",
                        sutra_not_load: "Unable to load sutra!",
                        sutra_loading: "Sutra data loading...",
                        sutra_not_connection:"Offline!"
                    },
                    info: {
                        app_name:"Sutra Reciter",
                        sutra_name: "經文名稱",
                        ok:"OK",
                        cancel:"Cancel",
                        language: "Language",
                        version: "Version",
                        base_version: "基本版",
                        full_version: "完整版",
                        test_version: "測試版",
                        browse: "Browser",
                        system: "OS",
                        device: "Device type",
                        view_size: "View size",
                        screen_size: "Screen size",
                        model: "Model",
                        manufacturer: "Manufacturer",
                        system_version: "OS version",
                        virtual: "Emulator",
                        pwa: "PWA",
                        app: "APP",
                        webaudio: "WebAudio",
                        service_worker: "Supports service workers",
                        use_service_worker: "Using service workers",
                        wakelock: "WakeLock",
                        howl_control: "Howl control loading",
                        useragent: "UserAgent",
                        font_sung: "Sung(宋體)",
                        font_kai: "Kai(楷體)",
                        font_ming: "Ming(明體)",
                        font_weibei: "WeiBei(魏碑體)",
                        font_kleeone: "Klee One",
                        font_system: "(System default)",
                        developer_mode: "Developer mode",
                        developer_mode_open: "Enable developer mode",
                        developer_mode_close: "Disable developer mode",
                        yes: "Yes",
                        no: "No",
                        supported: "Supported",
                        unsupported: "Unsupported",
                        used: "Used",
                        unused: "Unused"
                    },
                    stote: {
                        buy:"購買",
                        donate:"小額捐助",
                        buy_message:"歡迎您透過購買機制來捐助本APP。",
                        donate_message:"透過「Buy Me a Coffee」來捐助。",
                        purchase:"購買",
                        instruction: "購買/說明",
                        instruction_message: "購買說明：<ul><li>您可透過購買的方式捐助本程式開發者，但是請於確認購買前審慎考慮。</li><li>本程式<b>不存在</b>強迫購買及下載之情況，是否購買<b>不會</b>影響經文的下載。</li><li>若無法下載經文時請先確認網路狀況，若是主機異常時請稍待。與購買與否無關！</li><li>「佛經誦讀」為個人開發及負擔維護成本，您的購買/捐助對於「佛經誦讀」的維持將有很大的幫助！</li><li>購買後將提供若干完整版本獨有功能以感謝完整版使用者的贊助。</li><li><b>基本版</b>與<b>完整版</b>功能差異不大，僅以此些功能感謝贊助者。</li><li>「恢復購買」用於已購買但未自動變更為完整版時使用。(ex.更換手機時使用)</li></ul>完整版感謝功能：<ol><li>自訂經文主題顏色</li><li>經文搜尋功能</li><li>慢速播放選項</li><li>專屬字體(Klee One)</li><li>更多的經文，優先使用新上線之經文。</li><li>重複播放功能</li><li>加入捷徑功能，可透過捷徑快速開啟指定經文。</li></ul>",
                        android_message:"2020/03/30前之购买，因设定问题Google皆已三日后自动退款，欲购买者需重新购买！",
                        purchased: "已購買",
                        restore: "恢復購買",
                        restored: "已恢復購買"
                    },
                    help: {
                        orientation: "Based on the device status, you can use this program vertically or also flip to use it horizontally.",
                        device: "The app is applicable to the tablet and the mobile phones. We suggest that the app is used vertically in phone mode and horizontally in table mode.",
                        swipe: "Through swiping around, it can switch the front and back page. Swipe from the left to the right, you can switch to the next page. Swipe from the right to the left, you can switch to the previous page.",
                        pace: "When we use the app, the system synchronize with the sutra automatically, and the text will be highlighted when the audio chant the text.  If you are in need, you can also click on the text and jump to the section for playing directly.",
                        preview: "The last line (gray text) on each page is the first line of the next page, eliminating the difficulty from the traditional problem of page flip, which it is difficult to find the line. And if the system recite this line, the system will automatically flip the page, eliminating the difficulty from the traditional problem of page flip.",
                        light: "During the playing process, the screen will stay awake, so that the screen can display continuously, in order to facilitate the chanting. Upon the completion of the chanting, the system will come back to the default setting.",
                        menu: "Click on the lotus icon below, you can open the setup menu.",
                        play: "Through \"Pause\"/\"Play\" in the setup menu, you can know the current playing status and continue to operate. It will automatically apply this setting when starting the program next time.",
                        set_font_size: "Supports Ming, Kai, Sung, WebBei Fonts",
                        set_font: "The default font size is the most suitable size which is evaluated for the different devices. Due to the need for reading, you can change the font size through the setup menu.",
                        set_theme: "可設定經文顏色配置。",
                        set_preview: "開啟或關閉經文最後一列之預覽行。",
                        set_rate: "調整經文唱誦之速度。",
                        set_repeat: "經文唱誦後自動重複播放。",
                        set_resume_auto: "勾選「自動回復進度」，It will come to the last process automatically.",
                        select_page: "可跳至選取之頁數。",
                        select_chapter: "可跳至選取之章節。"
                    }
                }
            }
        }
    })

export default i18next
import Device, { DeviceType } from './Device';
import { File } from '@ionic-native/file';
import Color from './Color';

export interface KeyValue {
    Key: string;
    Value: string;
}

export interface SutraStage {
    Index: number;
    Second: number;
    Volume: number | null;
    Date: Date;
}

export enum PhoneticType {
    None,
    Bopomofo,
    PinYin,
    PinYinRotate
}

export enum AppType {
    Base,
    Full,
    Test
}


class Storag {

    static FontFullList: KeyValue[] = [
        { Value: "system", Key: "" },
        { Value: "sung", Key: "sung" },
        { Value: "kai", Key: "kai" },
        { Value: "ming", Key: "ming" },
        { Value: "weibei", Key: "weibei" },
        { Value: "kleeone", Key: "kleeone" }
    ];


    static FontList: KeyValue[] = [
        { Value: "system", Key: "" },
        { Value: "sung", Key: "sung" },
        { Value: "kai", Key: "kai" },
        { Value: "ming", Key: "ming" },
        { Value: "weibei", Key: "weibei" }
    ];

    static GetFilePath(fileName: string): string {
         var Path = Storag.GetUrlPath() + fileName;
        return Path;
    }

    static GetAudioPath(): string {
        return Storag.GetUrlPath();
       //return Storag.GetFilePath("audio/");
   }



    static GetUrlPath(): string {
        if (localStorage["UrlPath"] !== undefined && localStorage["UrlPath"] !== "") {
            return localStorage["UrlPath"];
        }

        return (process.env.REACT_APP_FILE_PATH + "/" + process.env.REACT_APP_FILE_DIR + "/");
    }



    static GetFeedbackPath(): string {
        if (localStorage["FeedbackPath"] !== undefined && localStorage["FeedbackPath"] !== "") {
            return localStorage["FeedbackPath"];
        }

        return (process.env.REACT_APP_FILE_PATH + "/Feedback.php");
    }

    static FormatDate(){
        var Today = new Date();
        return Today.getFullYear() + "-" + (Today.getMonth() + 1) + "-" + Today.getDate() + " " + Today.getHours() + ":" + Today.getMinutes() + ":" + Today.getSeconds();
    }

    

    static Feedback(subject:string, description:string, success:() => void, fail:(e:any) => void){
        var Body:string = description + "\n";
        Body += "Version: " + process.env.REACT_APP_VERSION + "\n";
        Body += "Device: " + Device.DeviceString() + "\n";

        if(Device.GetModel())
            Body += "Model: " + Device.GetModel() + "\n";
            
        Body += "System: " + Device.SystemString() + "\n";

        if(Device.GetSystemVersion())
            Body += "SystemVersion: " + Device.GetSystemVersion() + "\n";
        Body += "UserCode: " + Storag.GetUserCode() + "\n";

        fetch(Storag.GetFeedbackPath() + "?subject=" + encodeURIComponent(subject + "[" + Storag.FormatDate() + "]"), {
            method: "POST",
            mode: "no-cors",
            headers: { "Content-Type": "application/text"},
            body: Body
        }).then(()=>{
            success();
        }).catch((e)=>{
            fail(e);
        });

    }

    static GetUserCode() {
        if (localStorage["UserCode"] == undefined || localStorage["UserCode"] == "") {
            localStorage["UserCode"] = Storag.GetGuid();
        }

        return localStorage["UserCode"] as string;
    }

    static GetGuid() {
        var d = Date.now();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
            d += performance.now(); //use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
              return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
          });
    }


    static SetUrlPath = (path: string) => {
        if(path != null){
            localStorage["UrlPath"] = path;
            localStorage.removeItem("ListTime");
        }

    }

    static GetJsonPath(fileName: string = ""): string {
        return Storag.GetFilePath(fileName);
        /*
        if (this.GetLanguage() === "cn") {
            return Storag.GetFilePath("cn/" + fileName);
        } else {
            return Storag.GetFilePath("tw/" + fileName);
        }
        */
    }



    //字體大小
    static DefaultFontSize(): number {
        switch (Device.Device) {
            case DeviceType.Mobile:
                //行動裝置 螢幕通常比較小
                return Math.floor(Math.min(window.screen.width, window.screen.height) / 12);
            case DeviceType.Tablet:
                return Math.floor(Math.min(window.screen.width, window.screen.height) / 17);
            case DeviceType.Desktop:
                //windows好像不理 window.devicePixelRatio mac不確定
                return Math.floor(Math.min(window.screen.width, window.screen.height) / 30);
            default:
                //可能在手機上的電腦模式
                return Math.floor(Math.min(window.screen.width, window.screen.height) * window.devicePixelRatio / 15);
        }
    };

    static SetFontScale = (scale: number) => {
        localStorage["FontSizeScale"] = scale;
    }

    static GetFontScale = () => {
        if (localStorage["FontSizeScale"] !== undefined) {
            return parseFloat(localStorage["FontSizeScale"]);
        }
        return 1;
    }

    static GetFontSize = () => {
        return Storag.DefaultFontSize() * Storag.GetFontScale();
    }

    //字體設定
    static SetFontFamily = (fontName: string) => {
        localStorage["FontFamily"] = fontName;
    }

    static GetFontFamily = () => {
        var FontFamily = "ming";
        if (localStorage["FontFamily"] !== undefined) {
            FontFamily = localStorage["FontFamily"] as string
        }

        if(FontFamily=="kleeone" && Storag.GetAppType()!=AppType.Full){
            FontFamily = "ming";
        }

        return FontFamily;
    }

    //播放狀態
    static GetPlayStatus = (): boolean => {
        if (localStorage["PlayStatus"] !== undefined) {
            return localStorage["PlayStatus"] === "true";
        }
        return true;
    };

    static SetPlayStatus = (play: boolean) => {
        localStorage["PlayStatus"] = play;
    };


    static GetSoundStatus = (): boolean => {
        if (localStorage["SoundStatus"] !== undefined) {
            return localStorage["SoundStatus"] === "true";
        }
        return true;
    };

    static SetSoundStatus = (play: boolean) => {
        localStorage["SoundStatus"] = play;
    };

    //是否重複撥放
    static SetRepeat = (repeat: boolean) => {
        localStorage["Repeat"] = repeat;
    }

    static GetRepeat = () => {
        if(Storag.GetAppType()!=AppType.Full){
            return false;
        }

        if (localStorage["Repeat"] !== undefined) {
            return localStorage["Repeat"] === "true";
        }
        return true;
    }

    //Howl控管載入生聲音 不用先轉Base64
    static SetHowlControl = (howlControl: boolean) => {
        localStorage["HowlControl"] = howlControl;
    }
    
    static GetHowlControl = () => {
        if (localStorage["HowlControl"] !== undefined) {
            return localStorage["HowlControl"] === "true";
        }
        return true;
    }

        

    //是否顯示預覽列
    static SetPreview = (preview: boolean) => {
        localStorage["Preview"] = preview;
    }

    static GetPreview = () => {
        if (localStorage["Preview"] !== undefined) {
            return localStorage["Preview"] === "true";
        }
        return true;
    }

  
    static SetPhoneticType = (type: PhoneticType) => {
        localStorage["PhoneticType"] = type;
    }
     
    static GetPhoneticType = () => {
        if (localStorage["PhoneticType"] !== undefined) {
            return  parseInt(localStorage["PhoneticType"]) as PhoneticType;
        }
        //return Storag.GetLanguage()=="cn"?PhoneticType.PinYin:PhoneticType.Bopomofo;
        return PhoneticType.None;
     }
     

    static SetListTime = (time:number) => {
        localStorage["ListTime"] = time;
    }

    
    static GetListTime = () => {
        if (localStorage["ListTime"] !== undefined) {
            return parseInt(localStorage["ListTime"]) as number;
        }

        return 0;
    }


    static GetColorInfo = (): Color => {
        return new Color(Storag.GetColor());
    }

    static GetColor = (): number => {
        if (localStorage["Color"] !== undefined)
            return parseInt(localStorage["Color"]);

        if (localStorage["Theme"] !== undefined){
            localStorage["Color"] = Color.ColorListBase[parseInt(localStorage["Theme"])];
            return parseInt(localStorage["Color"]);
        }
        
        return Color.ColorListBase[0];
    }

    static SetColor = (color: number) => {
        localStorage["Color"] = color;
    }

    //是否回復上次進度
    static SetResume = (resume: string) => {
        localStorage["Resume"] = resume;
    }

    static GetResume = () => {
        if (localStorage["Resume"] !== undefined) {
            return localStorage["Resume"];
        }

        return "AskOnce";
    }


    static SetDevelopmentMode = (developmentMode: boolean) => {
        localStorage["DevelopmentMode"] = developmentMode;
    }


    static GetDevelopmentMode = () => {
        if (localStorage["DevelopmentMode"] !== undefined) {
            return localStorage["DevelopmentMode"] === "true";
        }

        return false;
    }

    static GetSutraDisplay = (code: string) => {
        return !(localStorage[code + "_Display"] === "false");
    }

    static SetSutraDisplay = (code: string, show:boolean) => {
        localStorage[code + "_Display"] = show
    }



    static SetSutraStage = (code: string, index: number, second: number) => {
        var Regx = code.match(/(.*)_([0-9]+)/);
        let Volume: number | null = null;
        if (Regx != null) {
            var Code = Regx[1];
            Volume = parseInt(Regx[2], 10);
            localStorage.setItem(Code + "_Stage", JSON.stringify({ Index: index, Second: second, Date: new Date(), Volume: Volume }));
        }
        localStorage.setItem(code + "_Stage", JSON.stringify({ Index: index, Second: second, Date: new Date(), Volume: Volume }));
    }

    static GetSutraStage = (code: string) => {
        var Value = localStorage.getItem(code + "_Stage");
        if (Value !== null) {
            return JSON.parse(Value) as SutraStage;
        }

        return null;
    }

    static DelSutraStage = (code: string, volume?:number, totalVolume?:number) => {
        if(volume==null)
            localStorage.removeItem(code + "_Stage");
        else{
            localStorage.removeItem(code + "_" + volume + "_Stage");
            var AllNull:boolean = true;
            if(totalVolume != null){
                for (let i = 0; i < totalVolume; i++) {
                    var Stage = Storag.GetSutraStage(code + "_" + (i+1));
                    if(Stage != null){
                        AllNull = false;
                    }
                }
  
                if(AllNull){
                    localStorage.removeItem(code + "_Stage");
                }
            }
        }
            
    }
    static SetLanguage = (language: string) => {
        localStorage["Language"] = language;
    }

    static GetLanguage = () => {
        if (localStorage["Language"] !== undefined) {
            return localStorage["Language"];
        }

        var Language = navigator.language.toLowerCase();
        if (Language === "zh-cn" || Language === "zh-sg") {
            return "cn"
        }

        if (Language === "zh-tw" || Language === "zh-hk" || Language === "zh-mo") {
            return "tw"
        }

        if (Language.indexOf("zh") >=0 ) {
            return "tw"
        }

        return "tw";
    }

    static SetAppType = (type: AppType) => {
        localStorage["AppType"] = type;
    }

    static GetAppType = () => {
        if (localStorage["AppType"] !== undefined) {
            return parseInt(localStorage["AppType"]) as AppType;
        }

        return AppType.Base;
    }

    static GetDataDirectory(){
        //不明原因 ios路徑偶而會回傳null 規律不明 但固定出現
        var Path = File.dataDirectory;
        
        if(Path != null)
            localStorage["DataDirectory"] = Path;

        return localStorage["DataDirectory"] as string;
    }

}

export default Storag;
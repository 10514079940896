import React from 'react';
import './SutraEdgeTop.css';
import Info from '../library/Info';
import Device from '../library/Device';
import Color from '../library/Color';

interface Props {
    Info: Info;
    Color:Color;
}

const SutraEdgeTop: React.FC<Props> = (props) => {

    var ScreenWidth = Device.GetWidth();

    var ImageList = [];
    var Height = Math.floor(props.Info.EdgeHeight * 0.8);
    var Width = Math.floor((Height / 212) * 1200);
    var Count = Math.ceil(ScreenWidth / Width);
    if (Count % 2 === 0)
        Count = Count + 1;

    var Diff = ((Width * Count) - ScreenWidth) / 2;
    
    for (var i = 0; i < Count; i++) {
        ImageList.push(<img src="/assets/edge_top.svg" key={"top_" + i} alt="" style={{ position: "absolute", height: Height, width: Width, left: Width * i - Diff, opacity: props.Color.Opacity, filter: props.Color.Invert?"invert(1)":"" }} className="no_drag" />);
    }

    return (
        <div style={{ height: props.Info.EdgeHeight, top: 0, userSelect: "none" }}>
            {ImageList}
        </div>
    );
}

export default SutraEdgeTop;
import { Insomnia } from '@ionic-native/insomnia';
import { isPlatform } from '@ionic/react';
import {Device as DeviceInfo}  from '@ionic-native/device';

export enum SystemType {
    Windows,
    Macintosh,
    Android,
    IOS,
    Linux,
    Other
}

export enum DeviceType {
    Mobile,
    Tablet,
    Desktop,
    Other
}

export enum BrowserType {
    Chrome,
    FireFox,
    Safari,
    Opera,
    Edge,
    EdgeChrome,
    IE,
    IE11,
    Line,
    Facebook,
    Other
}

class Device {

   

    static UserAgent: string;
    static System = SystemType.Other;
    static Device = DeviceType.Other;
    static Browser = BrowserType.Other;
    static IsHybrid = false;


    static initialize() {
        
        Device.UserAgent = navigator.userAgent.toLowerCase();

        if (isPlatform('ios'))
            Device.System = SystemType.IOS;
        else if (isPlatform('android'))
            Device.System = SystemType.Android;
        else if (/(?:windows nt)/.test(Device.UserAgent)) {
            Device.System = SystemType.Windows;
        } else if (/(?:mac)/.test(Device.UserAgent)) {
            Device.System = SystemType.Macintosh;
        } else if (/(?:android)/.test(Device.UserAgent)) {
            Device.System = SystemType.Android;
        } else if (/(?:ipad|ipod|iphone)/.test(Device.UserAgent)) {
            Device.System = SystemType.IOS;
        } else if (/(?:linux)/.test(Device.UserAgent)) {
            Device.System = SystemType.Linux;;
        }

        
        if (isPlatform('tablet') || isPlatform('ipad') || /(?:ipad|tablet)/.test(Device.UserAgent))
            Device.Device = DeviceType.Tablet;
        else if (isPlatform('mobile') || isPlatform('iphone') || /(?:mobile)/.test(Device.UserAgent))
            Device.Device = DeviceType.Mobile;
        else if (isPlatform('desktop') || Device.System === SystemType.Windows) {
            Device.Device = DeviceType.Desktop;
        } else if (Device.System === SystemType.Macintosh) {
            //ipad標記被拿掉了 用多觸點判斷是否是平板
            if (navigator.maxTouchPoints === 0)
                Device.Device = DeviceType.Desktop;
            else
                Device.Device = DeviceType.Tablet;
        }

        if (/(?:firefox)/.test(Device.UserAgent)) {
            Device.Browser = BrowserType.FireFox;
        } else if (/(?:opr)/.test(Device.UserAgent)) {
            Device.Browser = BrowserType.Opera;
        } else if (/(?:line)/.test(Device.UserAgent)) {
            Device.Browser = BrowserType.Line;
        } else if (/(?:fbav)/.test(Device.UserAgent)) {
            Device.Browser = BrowserType.Facebook;
        } else if (/(?:edge)/.test(Device.UserAgent)) {
            Device.Browser = BrowserType.Edge;
        } else if (/(?:edg)/.test(Device.UserAgent)) {
            Device.Browser = BrowserType.EdgeChrome;
        } else if (/(?:chrome|crios)/.test(Device.UserAgent)) {
            Device.Browser = BrowserType.Chrome;
        } else if (/(?:safari)/.test(Device.UserAgent)) {
            Device.Browser = BrowserType.Safari;
        } else if (/(?:msie)/.test(Device.UserAgent)) {
            Device.Browser = BrowserType.IE;
        } else if (/(?:trident)/.test(Device.UserAgent)) {
            Device.Browser = BrowserType.IE11;
        }
        
        Device.IsHybrid = isPlatform('hybrid');
        //if (isPlatform('hybrid'))
        //    Device.IsHybrid = true;
        //else
            //Device.IsHybrid = (/(?:webview)/.test(Device.UserAgent) || /(iphone|ipod|ipad)(?!.*safari\/)/.test(Device.UserAgent) || /android.*(wv|\.0\.0\.0)/.test(Device.UserAgent));
    }

    static DeviceString(): string {
        return DeviceType[Device.Device].toString();
    }

    static BrowserString(): string {
        return BrowserType[Device.Browser].toString();
    }

    static SystemString(): string {
        return SystemType[Device.System].toString();
    }

    static SupportWebAudioAPI(): boolean {
        if ((window as any).AudioContext || (window as any).webkitAudioContext) {
            return true;
        }

        return false;
    }

    static SupportServiceWorker(): boolean {
        if ('serviceWorker' in navigator) {
            return true;
        }

        return false;
    }


    static UsedServiceWorker(): boolean {
        if ('serviceWorker' in navigator) {
            if (navigator.serviceWorker.controller) {
                return true;
            }
        }
        return false;
    }

    static SupportWakeLock(): boolean {
        if ('wakeLock' in navigator)
            return true;
        return false;
    }

    static wakeLock: any = null;
    static async WakeLock(lock: boolean) {
        if (Device.IsHybrid) {
            if (lock) {
                Insomnia.keepAwake();
                console.log("Insomnia WakeLock On");
            } else {
                Insomnia.allowSleepAgain();
                console.log("Insomnia WakeLock Off");
            }
        } else {
            if (Device.SupportWakeLock()) {
                //支援WakeLock就用WakeLock
                if (lock) {
                    try {
                        Device.wakeLock = await navigator.wakeLock.request("screen");
                        console.log("WakeLock On");
                    } catch (err) {
                        console.log("WakeLock On Error:" + err);
                    }
                } else {
                    try {
                        if (Device.wakeLock) {
                            Device.wakeLock.release();
                            Device.wakeLock = null;
                            console.log("WakeLock Off");
                        }
                    } catch (err) {
                        console.log("WakeLock Off Error" + err);
                    }
                }
            }
        }
    }

    static IsPwa(): boolean {
        if ('fullscreen' in navigator) {
            if ((navigator as any).fullscreen) {
                return true;
            }
        }

        if (window.matchMedia('(display-mode: fullscreen)').matches)
            return true;

        return false;
    }

    //取得是否是深色模式
    static IsDarkMode(): boolean {
        return window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    static GetWidth(): number {
        //return document.body.scrollWidth;
        return document.documentElement.clientWidth;
    }

    static GetHeight(): number {
        //return document.body.scrollHeight;
        return document.documentElement.clientHeight;
    }

    static GetPixelRatio(): number {
        return window.devicePixelRatio;
    }

    //手機的型號
    static GetModel(): string {
        return DeviceInfo.model;
    }

    //手機廠牌
    static GetManufacturer(): string {
        return DeviceInfo.manufacturer;
    }

    //作業系統版本
    static GetSystemVersion(): string {
        return DeviceInfo.version;
    }

    //使用模擬器
    static IsVirtual(): boolean {
        return DeviceInfo.isVirtual;
    }

    static GetUUID(): string {
        return DeviceInfo.uuid;
    }

    
    static GetShortSide(): number {
        return Math.min(Device.GetWidth(), Device.GetHeight());
    }



    static ExitApp() {

        (navigator as any).app.exitApp();

    }

}

export default Device;
Device.initialize();
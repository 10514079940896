import React, { useEffect } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import SutraTabs from '../components/SutraTabs';
import SutraList from '../components/SutraList';
import './Read.css';
import i18n from '../library/i18n';
import Analytics from '../library/Analytics';

const Read: React.FC = () => {

    useEffect(() => {
        document.title = (i18n.getDataByLanguage("tw")?.translation.list as any).read;
        Analytics.ScreenName(document.title,"Read");
    });
    
    return (
        <IonPage>
            <IonContent forceOverscroll={false}>
                <SutraTabs>
                    <SutraList Type="Read"></SutraList>
                </SutraTabs>
            </IonContent>
        </IonPage>
    );

};

export default Read;

import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { IonButton, IonContent, IonPopover, useIonAlert } from '@ionic/react';
import './SutraPurchase.css';
import i18n from '../library/i18n';
import Storag, { AppType } from '../library/Storag';
import Stote from '../library/Stote';
import Device, { SystemType } from '../library/Device';


export interface PurchaseHandles {
    ShowPurchase(): void;
}

interface Props {
    OnHide(): void;
}



const Popover = React.createRef<HTMLIonPopoverElement>();


const SutraPurchase: React.ForwardRefRenderFunction<PurchaseHandles, Props> = (props, ref) => {
    const [ShowPurchase, SetShowPurchase] = useState(false);
    const Alert = useIonAlert()[0];


    useImperativeHandle(ref, () => ({
        ShowPurchase: () => {
            
            SetShowPurchase(true);
        }
    }));

    const HideMenu = () => {
        SetShowPurchase(false);
        props.OnHide();
    };

    return (
        <IonPopover isOpen={ShowPurchase} onDidDismiss={(e) => HideMenu()} ref={Popover}>
            <IonContent>
                <div dangerouslySetInnerHTML={{__html:i18n.t("stote.instruction_message")}}/>
                <div style={{textAlign:"center", whiteSpace:"nowrap"}}>
                    <IonButton color="primary" size="default" disabled={Storag.GetAppType()==AppType.Full} onClick={()=>{Stote.Order("full_sutra_recite",()=>{Alert(i18n.t("stote.purchased"))}, ()=>{Storag.SetAppType(AppType.Full);HideMenu();})}}>{i18n.t("stote.purchase")}</IonButton>
                    <IonButton color="primary" size="default" onClick={()=>{Stote.Restore(()=>{HideMenu();Alert(i18n.t("stote.restored"))})}}>{i18n.t("stote.restore")}</IonButton>
                    <IonButton color="light" size="default" onClick={HideMenu}>{i18n.t("info.cancel")}</IonButton>
                </div>
            </IonContent>
        </IonPopover>
    );
}

export default forwardRef(SutraPurchase);

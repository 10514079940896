import { HSLColor, RGBColor } from 'react-color';

class Color {
    static ColorListBase: number[] = [ 0xedcc8a, 0x000000, 0xffffff, 0xfed6e0, 0xd0eaff];
    static ColorListFull: number[] = [ 0xedcc8a, 0x000000, 0xffffff, 0xfed6e0, 0xd0eaff ,0xF0F4C3, 0xC8E6C9, 0xB2EBF2,0xD7CCC8, 0xD1C4E9,0xFFF9C4,0xFFCCBC, 0x555555, 0x888888,0xBBBBBB];

    Background: string;
    Font: string;
    SelectedFont: string;
    Preview: string;
    Stroke: string;
    Opacity: number;
    Invert: boolean;
    Hsl: HSLColor;
    Rgb: RGBColor;

    constructor(color: number) {
        this.Rgb = this.ToRgb(color);
        this.Hsl = this.RgbToHsl(this.Rgb.r, this.Rgb.g, this.Rgb.b);
        this.Background = Color.ToHex(color);
        this.Invert = (this.Hsl.l < 0.4);

        let FontColor:RGBColor; 
        //區分深淺
   
        if(this.Hsl.l <= 0.08 ){
            //接近黑色
            FontColor = this.HslToRgb(this.Hsl.h, this.Hsl.s * 0.3 , 1- this.Hsl.l);
        }else if(this.Hsl.l <= 0.55){
            //深色
            FontColor = this.HslToRgb(this.Hsl.h, this.Hsl.s * 0.3 , this.Hsl.l / 0.6 + 0.5);
        }else if(this.Hsl.l < 0.92){
            //淺色
            FontColor = this.HslToRgb(this.Hsl.h, this.Hsl.s * 0.3 , this.Hsl.l * 0.6 - 0.35);
        }else{
           //接近白色
           FontColor = this.HslToRgb(this.Hsl.h, this.Hsl.s * 0.3 , 1- this.Hsl.l);
        }
        this.Stroke = this.Hsl.l>=0.92?"#000000":"#FFFFFF";

        if(this.Hsl.l <= 0.4){
            this.SelectedFont = Color.ToHex(Color.FromRgb(this.Rgb));
        }else if(this.Hsl.l <= 0.6){
            this.SelectedFont = "#000000";
        }else if(this.Hsl.l < 0.92){
            this.SelectedFont = Color.ToHex(Color.FromRgb(FontColor));
        }else{
            this.SelectedFont = Color.ToHex(Color.FromRgb(this.Rgb));
        }

        this.Font = Color.ToHex(Color.FromRgb(FontColor));

        if(this.Hsl.l >= 0.7){
            this.Preview =  Color.ToHex(this.FromHsl(0, 0, 0.6));
         }else if(this.Hsl.l >= 0.5){
            this.Preview =  Color.ToHex(this.FromHsl(0, 0, 0.35));
        }else if(this.Hsl.l > 0.3){
            this.Preview =  Color.ToHex(this.FromHsl(0, 0, 0.65));
         }else{
            this.Preview =  Color.ToHex(this.FromHsl(0, 0, 0.6));
         }

        this.Opacity = 0.3;
        
     
    }

    static ToHex = (number: number) => {
        var Text = "000000" + number.toString(16);
        return "#" + Text.substring(Text.length - 6);
    }
  

    FromHsl = (h:number, s:number, l:number) => {
        return Color.FromRgb(this.HslToRgb(h, s, l));
    }

    static FromRgb = (rgb:RGBColor) => {
        if(rgb.r > 255) rgb.r = 255; 
        if(rgb.r > 255) rgb.g = 255;
        if(rgb.b > 255) rgb.b = 255;

        if(rgb.r < 0) rgb.r = 0; 
        if(rgb.r < 0) rgb.g = 0;
        if(rgb.b < 0) rgb.b = 0;

        return rgb.r*256*256 + rgb.g*256 + rgb.b;
    }

    ToRgb = (color: number) => {
        var Rgb:RGBColor = {
            r: Math.floor(color / 256 / 256),
            g: Math.floor(color / 256 % 256),
            b: color % 256
        };

        return Rgb;
    }

    HueToRgb = (p:number, q:number, t:number) => {
        if(t < 0)
            t += 1;

        if(t > 1)
            t -= 1;

        if(t < 1/6)
            return p + (q - p) * 6 * t;

        if(t < 1/2)
            return q;

        if(t < 2/3)
            return p + (q - p) * (2/3 - t) * 6;

        return p;
    }

    HslToRgbColor = (color:HSLColor) => {
        return this.HslToRgb(color.h, color.s, color.l);
    }

    HslToRgb = (h:number, s:number, l:number) => {
        if(h > 1) h = 1;
        if(s > 1) s = 1;
        if(l > 1) l = 1;

        if(h < 0) h = 0;
        if(s < 0) s = 0;
        if(l < 0) l = 0;

        var R = l;
        var G = l;
        var B = l;

        if(s != 0){
            var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            var p = 2 * l - q;
            R = this.HueToRgb(p, q, h + 1/3);
            G = this.HueToRgb(p, q, h);
            B = this.HueToRgb(p, q, h - 1/3);
        }

        return {
            r: Math.round(R * 255),
            g: Math.round(G * 255),
            b: Math.round(B * 255)
        } as RGBColor;
    }
    RgbToHslColor = (color:RGBColor) => {
        return this.RgbToHsl(color.r, color.g, color.b);
    }

    RgbToHsl = (r:number, g:number, b:number) => {
        var R = r / 255
        var G = g / 255
        var B = b / 255

        var Max = Math.max(R, G, B);
        var Min = Math.min(R, G, B);

        var HSL:HSLColor = {
            h:0,
            s:0,
            l:(Max + Min) / 2
        };
  
        if(Max != Min){
            var d = Max - Min;
            HSL.s = HSL.l > 0.5 ? d / (2 - Max - Min) : d / (Max + Min);
            switch(Max){
                case R:
                    HSL.h = (G - B) / d + (G < B ? 6 : 0);
                    break;
                case G:
                    HSL.h = (B - R) / d + 2;
                    break;
                case B:
                    HSL.h = (R - G) / d + 4;
                    break;
            }
            HSL.h /= 6;
        }
        return HSL;
    }

    RgbInvert = (color:RGBColor) => {
        return {
            r: 255 - color.r,
            g: 255 - color.g,
            b: 255 - color.b
        } as RGBColor;
    }
}

export default Color;
import React, { useImperativeHandle, forwardRef, useState, useEffect, useReducer } from 'react';
import Analytics from '../library/Analytics';
import Color from '../library/Color';
import Device from '../library/Device';
import Info, { SentenceData } from '../library/Info';
import Storag, { PhoneticType } from '../library/Storag';
import './SutraPage.css';

export interface PageHandles {
    SetPage(page: number): void;
    Next(): void;
    Previous(): void;
    SetIndex(index: number | null, auto: boolean): void;
    SetX(x: number): void;
    MoveToX(from: number, to: number, event: (() => void) | null): void;
}

export interface SutraPageData {
    Data: SentenceData[][],
    DefaultIndex: number | null,
    PageIndex: number | null
}


interface Props {
    Info: Info;
    Color: Color;
    TouchBack(index: number, goNext: boolean): void;
    RenderBack(): void;
}


const SutraPage: React.ForwardRefRenderFunction<PageHandles, Props> = (props, ref) => {
    //const [SvgRef] = useState(React.createRef<SVGSVGElement>());
    const [GRef] = useState(React.createRef<SVGGElement>());

    const [, forceUpdate] = useReducer(x => x + 1, 0);
    var FontSize = Storag.GetFontSize();
    
    let StrokeWidth: number = Math.floor(FontSize / 8.5);
    useEffect(() => {
        console.log("SutraPage載入");
        props.RenderBack();
    });

    const SetPage = ((page: number) => {
        try {
            if (page < props.Info.PageList.length && page >= 0) {
                props.Info.Page = page;
                forceUpdate();
            }
            if (GRef.current) {
                GRef.current.setAttribute("transform", "translate(0)");
            }
        } catch (error) {
            Analytics.Exception(error);
        }
    });

    const MoveToX = ((from: number, to: number, event: (() => void) | null) => {
        try {
            let Time: number = (Math.abs(to - from) / props.Info.PageWidth) * 500;
            if (GRef.current) {
                if(typeof GRef.current.animate === "function"){
                    GRef.current.animate([
                        { transform: "translateX(" + from + "px)" },
                        { transform: "translateX(" + (from + to) / 2 * 1.4 + "px)" },
                        { transform: "translateX(" + to + "px)" }
                    ], { duration: Time }).onfinish = function () {
                        if (GRef.current != null)
                            GRef.current.setAttribute("transform", "translate(" + to + ")");
                        setTimeout(() => {
                            if (event != null)
                                event();
                        }, 50);

                    };
                }else{
                    //不支援動畫
                    setTimeout(() => {
                        if (event != null)
                            event();
                    }, 50);
                }
    
            }
        } catch (error) {
            Analytics.Exception(error);
        }
    });

    const RefreshIndex = (() => {
        if (GRef.current != null) {
            var Children = GRef.current.children;
            for (var i = 0; i < Children.length; i++) {
                Children[i].setAttribute("stroke-width", "0");
                Children[i].setAttribute("fill", props.Color.Font);
                if (props.Info.SelectIndex !== null)
                    if (Children[i].getAttribute("data-index") === props.Info.SelectIndex.toString()) {
                        Children[i].setAttribute("stroke-width", StrokeWidth.toString());
                        Children[i].setAttribute("fill", props.Color.SelectedFont);
                    }
            }
        }
    });

    useImperativeHandle(ref, () => ({
        SetPage,
        Next: () => {
            SetPage(props.Info.Page + 1);
        },
        Previous: () => {
            SetPage(props.Info.Page - 1);
        },

        //auto會判斷是否為當下頁 若否 則會強制跳頁動畫
        SetIndex: (index: number | null, auto: boolean) => {
            try {
                if (props.Info.SelectIndex !== index) {

                    if (index != null) {

                        var IndexPage = props.Info.IndexPage[index];

                        if (props.Info.SelectIndex != null && auto) {
                            if (props.Info.Page !== props.Info.IndexPage[props.Info.SelectIndex]) {
                                //要是現前頁才自動翻頁
                                props.Info.SelectIndex = index;
                                forceUpdate();
                                return;
                            }
                        }

                        if (props.Info.Page !== IndexPage) {
                            props.Info.SelectIndex = index;
                            if (props.Info.Page + 1 === IndexPage) {
                                //下一頁
                                MoveToX(0, props.Info.PageWidth, () => {
                                    SetPage(IndexPage);
                                });
                            } else if (props.Info.Page - 1 === IndexPage) {
                                //上一頁
                                MoveToX(0, -props.Info.PageWidth, () => {
                                    SetPage(IndexPage);
                                });
                            } else {
                                //其他頁 直接跳不用翻頁效果
                                SetPage(IndexPage);
                            }
                            return;
                        }
                    }

                    props.Info.SelectIndex = index;
                    RefreshIndex();
                    
                }
            } catch (error) {
                Analytics.Exception(error);
            }
        },
        SetX: (x: number) => {
            if (GRef.current != null) {
                GRef.current.setAttribute("transform", "translate(" + x.toString() + ")");
            }

        },
        MoveToX: (from: number, to: number, event: (() => void) | null) => {
            MoveToX(from, to, event);
        }
    }));


    if (props.Info == null)
        return (<span>...</span>);

    //點選經文事件
    const GlyphTouch = ((event: React.MouseEvent) => {
        try {
            var TouchIndex = event.currentTarget.getAttribute("data-index");
            var TouchNext = event.currentTarget.getAttribute("data-next");
            if (TouchIndex !== null && TouchIndex !== null) {
                if (props.Info.SelectIndex !== parseInt(TouchIndex, 10)) {
                    props.TouchBack(parseInt(TouchIndex, 10), TouchNext === "true");

                    if (event.currentTarget.parentElement != null) {
                        var Children = event.currentTarget.parentElement.children
                        for (var i = 0; i < Children.length; i++) {
                            var ChildrenIndex = Children[i].getAttribute("data-index");
                            if (ChildrenIndex !== null) {
                                if (TouchIndex === ChildrenIndex) {

                                    Children[i].setAttribute("stroke-width", StrokeWidth.toString());
                                    Children[i].setAttribute("fill", props.Color.SelectedFont);
                                } else {
                                    Children[i].setAttribute("stroke-width", "0");
                                    Children[i].setAttribute("fill", props.Color.Font);
                                }
                            }
                        }
                    }

                    props.Info.SelectIndex = parseInt(TouchIndex, 10);
                }
            }
        } catch (error) {
            Analytics.Exception(error);
        }
    });

    try {
        var RowSpacing = Math.floor((props.Info.PageHeight - (FontSize * props.Info.RowCount)) / (props.Info.RowCount + 1)*10)/10;
        var ColSpacing = Math.floor((props.Info.PageWidth - (FontSize * props.Info.ColCount)) / (props.Info.ColCount + 1)*10)/10;

        var FixTop = (Device.SystemString()=="IOS"?(-FontSize * 0.2):0);

        switch (Storag.GetFontFamily()) {
            case "sung":
                FixTop = -FontSize * 0.2;
                break;
            case "ming":
                FixTop = -FontSize * 0.1;
                break;
            case "kai":
                FixTop = -FontSize * 0.23;
                break;
            case "weibei":
                FixTop = -FontSize * 0.17;
                break;
            case "kleeone":
                FixTop = -FontSize * 0.15;
                break;
        }
 
        var PageGroupList = [];
        var ShowPreview = Storag.GetPreview();
        for (let p = 1; p >= -1; p--) {
            var PageIndex = props.Info.Page + p;
           
            if (PageIndex >= 0 && PageIndex < props.Info.PageList.length) {
                let PageData = props.Info.PageList[PageIndex];
                var Left = -p * props.Info.PageWidth;
                let GroupList = [];
                var Row = 0;
                //var GroupIndex = 0;
                
                let PreIndex: number | null = null;
                for (let i = 0; i < PageData.length; i++) {
                    for (let j = 0; j < PageData[i].length; j++) {
                        var TextList = [];
                        var Index = PageData[i][j].Index;
                        var Data = PageData[i][j].Data;
                        if(Data){
                            if (i < props.Info.ColCount - 1 || !ShowPreview) {
                                var ImageTag = <image x={Left} y={0} width={"100%"} height={"100%"} xlinkHref={Data}></image>
                                TextList.push(ImageTag);
                            }
                        }
                        for (let k = 0; k < PageData[i][j].Word.length; k++) {
                            var Word = PageData[i][j].Word[k];
                            var x = (props.Info.PageWidth) - ((i + 1) * (FontSize + ColSpacing)) + Left
                            var y = (Row + 1) * (FontSize + RowSpacing) + FixTop;
                            var Key = "T" + Index+"_" + k;
                            var TextProps = {key:"T"+Key, x:x, y:y, fontSize:FontSize, fill:(null as string|null)};

                            if(Word != undefined){
                                if (i < props.Info.ColCount - 1 || !ShowPreview) {
                                    PreIndex = Index;
                                } else {
                                    TextProps.fill = props.Color.Preview;
                                }
                                TextList.push(React.createElement("text", TextProps, Word.Word+ Word.Phonetic));
                            }
                            ++Row;
                        }

                        var Selected = (props.Info.SelectIndex === Index && Index != null);
                        GroupList.push(<g key={Storag.GetGuid()} data-next={(PreIndex !== Index && Index != null)} data-index={Index} onClick={GlyphTouch} stroke={props.Color.Stroke} paintOrder="stroke fill" strokeLinejoin="round" strokeWidth={Selected ? StrokeWidth : 0} fill={Selected ? props.Color.SelectedFont : props.Color.Font}>{TextList}</g>);

                    }
                    Row = 0;
                }
             
                PageGroupList.push(GroupList);
               
   
            }
        }

      
        return (
            <svg style={{cursor: "pointer"}} xmlns="http://www.w3.org/2000/svg" fontFamily={[PhoneticType[props.Info.PhoneticType], Storag.GetFontFamily()].filter(x=>x.length != 0 && x != "None").join(",")} className="no_select" width={props.Info.PageWidth} height={props.Info.PageHeight}>
                <g transform="translate(0)" ref={GRef}>
                    <text>&nbsp;</text>
                    {PageGroupList}
                </g>
            </svg>
        );
    } catch (error) {
        Analytics.Exception(error);
    }
    return <div>Error</div>;
};

export default forwardRef(SutraPage);

import { IonItem, IonLabel, IonList, IonListHeader, IonCheckbox, IonSelectOption, IonIcon, IonSelect, IonRange, IonInput, IonButton, useIonToast, IonHeader, IonToolbar, IonTitle, IonContent} from '@ionic/react';
import React, { useReducer,  useState } from 'react';
import Device from '../library/Device';
import Storag, { PhoneticType, AppType } from '../library/Storag';
import SutraDemo from '../components/SutraDemo';
import { colorPaletteOutline, textOutline, repeatOutline, settingsOutline, musicalNotesOutline, sunnyOutline, saveOutline, desktopOutline, browsersOutline, alertCircleOutline, eyeOutline, phonePortraitOutline, businessOutline, informationCircleOutline, readerOutline, heartOutline, cafeOutline} from 'ionicons/icons';
import './SutraSetting.css';
import i18n from '../library/i18n';
import Stote from '../library/Stote';
import SutraColor from './SutraColor';
import SutraPurchase, { PurchaseHandles } from './SutraPurchase';


let TouchTimeList: number[] = [];


const SutraSetting: React.FC = () => {

    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const [, SetFontScale] = useState(Storag.GetFontScale());
    const [, SetDevelopmentMode] = useState(Storag.GetDevelopmentMode());
    const Toast = useIonToast()[0];
    

    const PurchaseRef = React.createRef<PurchaseHandles>();

    const TouchVersion = () => {
        TouchTimeList.push(new Date().getTime());
        ///五秒內按20下 開關開發模式
        if (TouchTimeList.length >= 20) {
            if ((TouchTimeList[TouchTimeList.length - 1] - TouchTimeList[TouchTimeList.length - 20]) <= 5000) {
                Storag.SetDevelopmentMode(!Storag.GetDevelopmentMode());
                TouchTimeList = [];
                Toast(i18n.t("info.developer_mode_" + (Storag.GetDevelopmentMode() ? "open" : "close")), 1500);

                SetDevelopmentMode(Storag.GetDevelopmentMode());
            }
        }
    };

    return (
        <div>
            <IonList>
                <IonListHeader>
                    <IonLabel style={{ fontSize: "150%" }}>{i18n.t("menu.sutra_setting")}</IonLabel>
                </IonListHeader>
                <IonItem>
                    <IonLabel><IonIcon icon={alertCircleOutline}></IonIcon>&nbsp;{i18n.t("info.language")}</IonLabel>
                    <IonSelect value={Storag.GetLanguage()} key="Language" onIonChange={(e) => { Storag.SetLanguage(e.detail.value); window.location = window.location; }} interface="popover" >
                        <IonSelectOption value="tw">繁體中文</IonSelectOption>
                        <IonSelectOption value="cn">简体中文</IonSelectOption>
                        {((Storag.GetDevelopmentMode()?(<IonSelectOption value="en">English</IonSelectOption>):""))}
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel><IonIcon icon={alertCircleOutline}></IonIcon>&nbsp;{i18n.t("menu.resume_auto")}</IonLabel>
                    <IonSelect value={Storag.GetResume()} key="Resume" onIonChange={(e) => { Storag.SetResume(e.detail.value); forceUpdate(); }} interface="popover" >
                        <IonSelectOption value="Resume">{i18n.t("menu.resume_resume")}</IonSelectOption>
                        <IonSelectOption value="Start">{i18n.t("menu.resume_start")}</IonSelectOption>
                        <IonSelectOption value="Ask">{i18n.t("menu.resume_ask")}</IonSelectOption>
                        <IonSelectOption value="AskOnce">{i18n.t("menu.resume_ask_once")}</IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel><IonIcon icon={textOutline}></IonIcon>&nbsp;{i18n.t("menu.font")}</IonLabel>
                    <IonSelect value={Storag.GetFontFamily().toString()} key="FontFamily" onIonChange={(e) => { Storag.SetFontFamily(e.detail.value); forceUpdate(); }} interface="popover" >
                        {(Storag.GetAppType()==AppType.Full?Storag.FontFullList:Storag.FontList).map(x => <IonSelectOption key={"FontFamily_" + x.Key} value={x.Key}>{i18n.t("info.font_" + x.Value)}</IonSelectOption>)}
                    </IonSelect>
                </IonItem>
                <IonListHeader>
                    <IonLabel>
                        <h2><IonIcon icon={textOutline}></IonIcon>&nbsp;{i18n.t("menu.font_size")}</h2>
                    </IonLabel>
                </IonListHeader>
                <IonItem>
                    <IonRange min={0.7} max={1.3} value={Storag.GetFontScale()} key="FontSizeScale" snaps={true} color="secondary" style={{ padding: 0 }} step={0.1} onIonChange={(e) => { Storag.SetFontScale(e.detail.value as number); }} onTouchEnd={() => SetFontScale(Storag.GetFontScale())} onMouseUp={() => SetFontScale(Storag.GetFontScale())} >
                        <IonLabel slot="start">{i18n.t("menu.size_small")}</IonLabel>
                        <IonLabel slot="end">{i18n.t("menu.size_large")}</IonLabel>
                    </IonRange>
                </IonItem>
                <IonItem>
                    <IonLabel><IonIcon icon={colorPaletteOutline}></IonIcon>&nbsp;{i18n.t("menu.theme")}</IonLabel>
                    <SutraColor slot="end" Color={Storag.GetColor()} OnChange={(color:number)=>{Storag.SetColor(color);forceUpdate(); }}></SutraColor>
                </IonItem>
                <IonItem hidden={Storag.GetAppType()!=AppType.Full}>
                    <IonLabel><IonIcon icon={repeatOutline}></IonIcon>&nbsp;{i18n.t("menu.repeat")}</IonLabel>
                    <IonCheckbox slot="end" color="primary" checked={Storag.GetRepeat()} onIonChange={(e) => { Storag.SetRepeat(e.detail.checked); forceUpdate(); }} />
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonIcon icon={readerOutline}></IonIcon>&nbsp;{i18n.t("menu.phonetic")}</IonLabel>
                        <IonSelect key="PhoneticType" value={Storag.GetPhoneticType()} interface="popover" slot="end" onIonChange={(e) => {Storag.SetPhoneticType(e.detail.value); forceUpdate();}}>
                            <IonSelectOption value={PhoneticType.None}>{i18n.t("menu.phonetic_0")}</IonSelectOption>
                            <IonSelectOption value={PhoneticType.Bopomofo}>{i18n.t("menu.phonetic_1")}</IonSelectOption>
                            <IonSelectOption value={PhoneticType.PinYin}>{i18n.t("menu.phonetic_2")}</IonSelectOption>
                            <IonSelectOption value={PhoneticType.PinYinRotate}>{i18n.t("menu.phonetic_3")}</IonSelectOption>
                        </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        <IonIcon icon={settingsOutline}></IonIcon>&nbsp;{i18n.t("menu.preview")}</IonLabel>
                    <IonCheckbox slot="end" color="primary" checked={Storag.GetPreview()} onIonChange={(e) => { Storag.SetPreview(e.detail.checked); forceUpdate(); }} />
                </IonItem>
                <IonItem>
                    <IonLabel><IonIcon icon={eyeOutline}></IonIcon>&nbsp;{i18n.t("menu.theme_display")}</IonLabel>
                    <SutraDemo></SutraDemo>
                </IonItem>
                <IonItem lines={"none"} hidden={!(Device.IsHybrid || Storag.GetDevelopmentMode())}>
                    <IonLabel position="fixed">
                        <IonIcon icon={heartOutline}></IonIcon>&nbsp;{i18n.t("stote.buy")}
                    </IonLabel>
                    <IonButton  slot="end"color="primary" size="default" onClick={() => {PurchaseRef.current?.ShowPurchase()}}>{i18n.t("stote.instruction")}</IonButton>
                </IonItem>

               <IonItem hidden={!Storag.GetDevelopmentMode() && !Device.IsHybrid}>
                    <div style={{ width: "100%", textAlign:"right" }}>
                            {Storag.GetAppType()==AppType.Full?i18n.t("stote.purchased"):i18n.t("stote.buy_message")}                 
                    </div>
                </IonItem>

                <IonItem lines={"none"} hidden={!Storag.GetDevelopmentMode() && Device.IsHybrid}>
                    <IonLabel position="fixed">
                        <IonIcon icon={cafeOutline}></IonIcon>&nbsp;{i18n.t("stote.donate")}
                    </IonLabel>
                    <IonButton  slot="end" size="default" class="CoffeeButton" href="https://www.buymeacoffee.com/mingyan.tw" target="_blank">
                        <img src="assets/menu/buymeacoffee.svg" alt="" style={{height:"100%"}}></img>
                    </IonButton>  
                </IonItem>
                <IonItem hidden={!Storag.GetDevelopmentMode() && Device.IsHybrid}>
                    <div style={{ width: "100%", textAlign:"right" }}>
                        {i18n.t("stote.donate_message")}
                    </div>
                </IonItem>

                <IonItem type="button" onClick={TouchVersion} ><IonLabel><IonIcon icon={alertCircleOutline}></IonIcon>&nbsp;{i18n.t("info.version")}</IonLabel>{i18n.t("info." + (AppType[Storag.GetAppType()].toLowerCase()) + "_version")} {process.env.REACT_APP_VERSION}</IonItem>
            

            </IonList>

            <IonList hidden={!Storag.GetDevelopmentMode()} >
                <IonListHeader>
                    <IonLabel style={{ fontSize: "150%" }}>{i18n.t("menu.device_info")}</IonLabel>
                </IonListHeader>
                <IonItem><IonLabel><IonIcon icon={sunnyOutline}></IonIcon>&nbsp;{i18n.t("menu.file_path")}</IonLabel>
                    <IonInput value={Storag.GetUrlPath()} style={{ width: "100%", textAlign: "right"}} onIonChange={(e) => {Storag.SetUrlPath(e.detail.value as string);}} onIonBlur={(e) => {forceUpdate();}}></IonInput>
                </IonItem>
                <IonItem><IonLabel><IonIcon icon={browsersOutline}></IonIcon>&nbsp;{i18n.t("info.browse")}</IonLabel>{Device.BrowserString()}</IonItem>
                <IonItem><IonLabel><IonIcon icon={desktopOutline}></IonIcon>&nbsp;{i18n.t("info.system")}</IonLabel>{Device.SystemString()}</IonItem>
                <IonItem><IonLabel><IonIcon icon={desktopOutline}></IonIcon>&nbsp;{i18n.t("info.device")}</IonLabel>{Device.DeviceString()}</IonItem>
                <IonItem><IonLabel><IonIcon icon={phonePortraitOutline}></IonIcon>&nbsp;{i18n.t("info.model")}</IonLabel>{Device.GetModel()}</IonItem>
                <IonItem><IonLabel><IonIcon icon={businessOutline}></IonIcon>&nbsp;{i18n.t("info.manufacturer")}</IonLabel>{Device.GetManufacturer()}</IonItem>
                <IonItem><IonLabel><IonIcon icon={informationCircleOutline}></IonIcon>&nbsp;{i18n.t("info.system_version")}</IonLabel>{Device.GetSystemVersion()}</IonItem>
                <IonItem><IonLabel><IonIcon icon={informationCircleOutline}></IonIcon>&nbsp;{i18n.t("info.virtual")}</IonLabel>{Device.IsVirtual() ? i18n.t("info.yes") : i18n.t("info.no")}</IonItem>
                <IonItem><IonLabel><IonIcon icon={browsersOutline}></IonIcon>&nbsp;{i18n.t("info.pwa")}</IonLabel>{Device.IsPwa() ? i18n.t("info.yes") : i18n.t("info.no")}</IonItem>
                <IonItem><IonLabel><IonIcon icon={browsersOutline}></IonIcon>&nbsp;{i18n.t("info.app")}</IonLabel>{Device.IsHybrid ? i18n.t("info.yes") : i18n.t("info.no")}</IonItem>
                <IonItem><IonLabel><IonIcon icon={musicalNotesOutline}></IonIcon>&nbsp;{i18n.t("info.webaudio")}</IonLabel>{Device.SupportWebAudioAPI() ? i18n.t("info.supported") : i18n.t("info.unsupported")}</IonItem>
                <IonItem><IonLabel><IonIcon icon={musicalNotesOutline}></IonIcon>&nbsp;{i18n.t("info.howl_control")}</IonLabel><IonCheckbox slot="end" color="primary" checked={Storag.GetHowlControl()} onIonChange={(e) => { Storag.SetHowlControl(e.detail.checked); forceUpdate(); }} /></IonItem>
                <IonItem><IonLabel><IonIcon icon={saveOutline}></IonIcon>&nbsp;{i18n.t("info.service_worker")}</IonLabel>{Device.SupportServiceWorker() ? i18n.t("info.supported") : i18n.t("info.unsupported")}</IonItem>
                <IonItem><IonLabel><IonIcon icon={saveOutline}></IonIcon>&nbsp;{i18n.t("info.use_service_worker")}</IonLabel>{Device.UsedServiceWorker() ? i18n.t("info.used") : i18n.t("info.unused")}</IonItem>
                <IonItem><IonLabel><IonIcon icon={sunnyOutline}></IonIcon>&nbsp;{i18n.t("info.wakelock")}</IonLabel>{Device.SupportWakeLock() ? i18n.t("info.supported") : i18n.t("info.unsupported")}</IonItem>
                <IonItem><IonLabel position="fixed"><IonIcon icon={settingsOutline}></IonIcon>&nbsp;{i18n.t("info.useragent")}</IonLabel>
                    <div style={{ width: "100%", maxHeight: "10em", overflow:"auto" }}>{Device.UserAgent}</div>
                </IonItem>

                <IonItem>
                    <IonLabel>App類型</IonLabel>
                    <IonSelect key="AppType" value={Storag.GetAppType()} interface="popover" slot="end" onIonChange={(e) => {Storag.SetAppType(e.detail.value); forceUpdate();}}>
                        <IonSelectOption value={AppType.Base}>{i18n.t("info." + (AppType[AppType.Base].toLowerCase()) + "_version")}</IonSelectOption>
                        <IonSelectOption value={AppType.Full}>{i18n.t("info." + (AppType[AppType.Full].toLowerCase()) + "_version")}</IonSelectOption>
                        <IonSelectOption value={AppType.Test}>{i18n.t("info." + (AppType[AppType.Test].toLowerCase()) + "_version")}</IonSelectOption>
                    </IonSelect>
                </IonItem>


                <IonItem><IonLabel position="fixed"><IonIcon icon={settingsOutline}></IonIcon>&nbsp;{i18n.t("info.stote")}</IonLabel>
                    <div style={{ width: "100%", maxHeight: "20em", overflow:"auto" }}>{JSON.stringify(Stote.Get("full_sutra_recite"))}</div>
                </IonItem>
            </IonList>
            <SutraPurchase ref={PurchaseRef} OnHide={()=>{forceUpdate();}}></SutraPurchase>
        </div>
    );

};   

export default SutraSetting;

import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Book from './pages/Book';
import Help from './pages/Help';
import Setting from './pages/Setting';
import Recite from './pages/Recite';
import Read from './pages/Read';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import Listener from './library/Listener';
import { setupIonicReact } from '@ionic/react';
import Stote from './library/Stote';
import Shortcut from './library/Shortcut';

import Analytics from './library/Analytics';

setupIonicReact({ mode: "md", animated:false});

const App: React.FC = () => {

    useEffect(() => {
        Listener.OnBeforeInstallPrompt();

        Analytics.Initialize();

        Listener.OnDeviceReady(() => {
            Shortcut.Start();
            //裝置準備好時執行 web無效
            Stote.Register("full_sutra_recite");
       
        });
    });
    
    //IonRouterOutlet會跑兩次用Switch替代 新版本好像不會了
    return (
        <IonApp>
            <IonReactRouter>
                <Switch>
                    <Route path="/Book/:code" component={Book} exact={false} />
                    <Route path='/Recite' component={Recite} exact={true} />
                    <Route path='/Read' component={Read} exact={true} />
                    <Route path='/Help' component={Help} exact={true} />
                    <Route path='/Setting' component={Setting} exact={true} />
                    <Route exact path="/" render={() => <Redirect to="/Recite" />} />
                </Switch>
            </IonReactRouter>
        </IonApp>
    );
}


export default App;

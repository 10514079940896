import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { useHistory } from 'react-router';

import { IonPopover, IonLabel, IonList, IonItem, IonRange, IonIcon, IonCheckbox, IonRadioGroup, IonListHeader, IonRadio, IonButton, IonSelect, IonSelectOption, IonContent, useIonPopover, IonInput, IonTextarea, useIonAlert } from '@ionic/react';
import { bookOutline, textOutline, colorPaletteOutline, settingsOutline, repeatOutline, refreshOutline, readerOutline, searchOutline, search, libraryOutline, alertCircleOutline, heartOutline, cafeOutline} from 'ionicons/icons';

import Storag, { AppType, PhoneticType } from '../library/Storag';
import Info, { QueryResult } from '../library/Info';
import Device from '../library/Device';
import i18n from '../library/i18n';

import SutraColor from './SutraColor';

import './SutraMenu.css';
import SutraPurchase, { PurchaseHandles } from './SutraPurchase';

export interface MenuHandles {
    ShowMenu(playRate: number): void;
    IsOpen(): boolean;
}

interface Props {
    SeekIndex(index: number): void;
    SeekPage(pageIndex: number): void;
    OnInfoChange(): void;
    OnAudioStop(): void;
    OnReset(): void;
    //OnAudioChange(rate: number | null, repeat: boolean | null): void;
    OnRateChange(rate: number): void;
    OnHideMenu(): void;
    Info: Info;
    Code: string;
}

enum MenuType {
    Main,
    Setting,
    Page,
    Rate,
    Info,
    Close
}


const Popover = React.createRef<HTMLIonPopoverElement>();

let PlayRate: number = 1;
let SearchInput: HTMLIonInputElement|null;
let SearchText: string = "";

const SutraMenu: React.ForwardRefRenderFunction<MenuHandles, Props> = (props, ref) => {
    const [ShowMenu, SetShowMenu] = useState(MenuType.Close);
    const [SearchResult, SetSearchResult] = useState(null as QueryResult|null);
    const IsExit = useRef<boolean>(false);
    const History = useHistory();
    const PurchaseRef = React.createRef<PurchaseHandles>();
    const Alert = useIonAlert()[0];
    const [ReportText, SetReportText] = useState<string>("");

    let PageNumber: number;
    
    useImperativeHandle(ref, () => ({
        ShowMenu: (playRate: number) => {
            PlayRate = playRate;
            
            SetShowMenu(MenuType.Main);
        },
        IsOpen: () => {
            return ShowMenu !== MenuType.Close;
        }
    }));

    const HideMenu = () => {
        if(!IsExit.current){
            SetShowMenu(MenuType.Close);
            props.OnHideMenu();
        }
    };

    const SetChapter = (index: number) => {
        props.SeekIndex(index);
        HideMenu();
    };
    

    const SendReport = () => {
        var SturaText = props.Info.Data.Content[props.Info.SelectIndex??0].Sentence.map((x)=>(x.Word+(props.Info.PhoneticType==PhoneticType.None?"":x.Phonetic))).join("");
        Storag.Feedback("經文錯誤回報",
            ReportText + "\n\n" + "SutraText: " + SturaText + "\nSutraCode: " + props.Code + ("\nSutraIndex: "+props.Info.SelectIndex??0),
            ()=>{
                Alert({message:i18n.t("menu.report_ok"),buttons:[{text:i18n.t("info.ok"),handler:()=>HideMenu()}]});
            },(e)=>{
                Alert(i18n.t("menu.report_fail") + "\n" + e);
            }
        );
    };


    //以index取得現在章節的index
    const GetChapterIndex = () => {
        if (props.Info !== undefined) {
            if (props.Info.SelectIndex != null)
                for (var i = props.Info.ChapterList.length - 1; i >= 0; i--) {
                    if (props.Info.ChapterList[i].Index <= props.Info.SelectIndex) {
                        return props.Info.ChapterList[i].Index;
                    }
                }

        }
    };

    //設定字體
    const SetFontFamily = (fontName: string) => {
        Storag.SetFontFamily(fontName);
        props.OnInfoChange();
        HideMenu();
    };

    //設定字型大小
    const SetFontSizeScale = () => {
        //TouchEnd 和 MouseUp可能會造成兩次
        if (ShowMenu !== MenuType.Close) {
            props.OnInfoChange();
            //延遲一下 不然會點到文字
            setTimeout(()=>{
                HideMenu();
            },100);
        }
    };

    //設定頁數
    const SetPageNumber = () => {
        //TouchEnd 和 MouseUp可能會造成兩次
        if (ShowMenu !== MenuType.Close) {
            props.SeekPage(PageNumber);
            //延遲一下 不然會點到文字
            setTimeout(()=>{
                HideMenu();
            },100);
        }
    };

    const SetVolumeNumber = (num:number) => {
        if (ShowMenu !== MenuType.Close) {
            var Regx = props.Code.match(/(.*_)([0-9]+)/);
            if (Regx != null && props.Info.Data.Volume != null) {
                var Code = Regx[1];
                GoSutra(Code + num);
            }
        }
    }

    const SetColor = (color: number) => {
        Storag.SetColor(color);
        props.OnInfoChange();
        HideMenu();
    };

    const SetRate = (rate: number) => {
        //props.OnAudioChange(rate, null);
        props.OnRateChange(rate);
        HideMenu();
    };


    const SetRepeat = (repeat: boolean) => {
        Storag.SetRepeat(repeat);
        //props.OnAudioChange(null, repeat);
        HideMenu();
    };


    const SetResume = (resume: boolean) => {
        Storag.SetResume(resume ? "Resume" :"Start");
        HideMenu();
    };


    const SetPreview = (preview: boolean) => {
        Storag.SetPreview(preview);
        props.OnInfoChange();
        HideMenu();
    };


        //設定注音方式
    const SetPhoneticType = (phoneticType: PhoneticType) => {
        Storag.SetPhoneticType(phoneticType as PhoneticType);
        props.OnInfoChange();
        HideMenu();
    };
        
    const GoList = () => {
        IsExit.current = true;
        props.OnAudioStop();
        if (props.Info.Data.Audio) {
            History.push("/Recite");
            //window.location.href = "/Recite";
        } else {
            History.push("/Read");
            //window.location.href = "/Read";
        }

    };


    const GoSutra = (code: string) => {
        IsExit.current = true;
        
        props.OnAudioStop();
        props.OnReset();
        History.push("/Book/" + code);
        //window.location.href = "/Book/" + code;
    };

    const SetPlayStatus = (play: boolean) => {
        Storag.SetPlayStatus(play);
        HideMenu();
    };

    let MenuElement: JSX.Element;
    switch (ShowMenu) {
        case MenuType.Setting:
            MenuElement = (
                <IonList>
                    <IonListHeader>
                        <IonLabel>
                            <h2><IonIcon icon={textOutline}></IonIcon>&nbsp;{i18n.t("menu.font_size")}</h2>
                        </IonLabel>
                    </IonListHeader>
                    <IonItem>
                        <IonRange min={0.7} max={1.3} value={Storag.GetFontScale()} key="FontSizeScale" snaps={true} color="secondary" style={{ padding: 0 }} step={0.1} onIonChange={(e) => Storag.SetFontScale(e.detail.value as number)} onMouseUp={SetFontSizeScale} onTouchEnd={SetFontSizeScale} >
                            <IonLabel slot="start">{i18n.t("menu.size_small")}</IonLabel>
                            <IonLabel slot="end">{i18n.t("menu.size_large")}</IonLabel>
                        </IonRange>
                    </IonItem>
                    <IonItem>
                        <IonLabel><IonIcon icon={textOutline}></IonIcon>&nbsp;{i18n.t("menu.font")}</IonLabel>
                        <IonSelect value={Storag.GetFontFamily().toString()} key="FontFamily" interface="popover" slot="end" onIonChange={(e) => SetFontFamily(e.detail.value)}>
                            {(Storag.GetAppType()==AppType.Full?Storag.FontFullList:Storag.FontList).map(x => <IonSelectOption key={"Family_" + x.Key} value={x.Key} hidden={true}>{i18n.t("info.font_" + x.Value)}</IonSelectOption>)}
                        </IonSelect>
                    </IonItem>
                    <IonItem>
                        <IonLabel><IonIcon icon={colorPaletteOutline}></IonIcon>&nbsp;{i18n.t("menu.theme")}</IonLabel>
                            <SutraColor slot="end" Color={Storag.GetColor()} OnChange={(color:number)=>{SetColor(color);}}></SutraColor>
                    </IonItem>
                    <IonItem>
                        <IonLabel><IonIcon icon={readerOutline}></IonIcon>&nbsp;{i18n.t("menu.phonetic")}</IonLabel>
                        <IonSelect key="PhoneticType" value={Storag.GetPhoneticType()} disabled={!props.Info.Data.Phonetic} interface="popover" slot="end" onIonChange={(e) => SetPhoneticType(parseInt(e.detail.value))}>
                            <IonSelectOption value={PhoneticType.None}>{i18n.t("menu.phonetic_0")}</IonSelectOption>
                            <IonSelectOption value={PhoneticType.Bopomofo}>{i18n.t("menu.phonetic_1")}</IonSelectOption>
                            <IonSelectOption value={PhoneticType.PinYin}>{i18n.t("menu.phonetic_2")}</IonSelectOption>
                            <IonSelectOption value={PhoneticType.PinYinRotate}>{i18n.t("menu.phonetic_3")}</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem>
                        <IonLabel><IonIcon icon={settingsOutline}></IonIcon>&nbsp;{i18n.t("menu.preview")}</IonLabel>
                        <IonCheckbox slot="end" color="primary" checked={Storag.GetPreview()} onIonChange={(e) => SetPreview(e.detail.checked)} />
                    </IonItem>
                    <IonItem>
                        <IonLabel><IonIcon icon={refreshOutline}></IonIcon>&nbsp;{i18n.t("menu.resume_auto")}</IonLabel>
                        <IonCheckbox slot="end" color="primary" checked={Storag.GetResume() === "Resume"} onIonChange={(e) => SetResume(e.detail.checked)} />
                    </IonItem>
                    <IonItem hidden={Storag.GetAppType()!=AppType.Full}>
                        <IonLabel><IonIcon icon={repeatOutline}></IonIcon>&nbsp;{i18n.t("menu.repeat")}</IonLabel>
                        <IonCheckbox slot="end" color="primary" checked={Storag.GetRepeat()} onIonChange={(e) => SetRepeat(e.detail.checked)} />
                    </IonItem>

                </IonList>
            );
            break;
        case MenuType.Rate:
            MenuElement = (
                <IonList>
                    <IonListHeader>
                        <IonLabel><h2><IonIcon icon={textOutline}></IonIcon>&nbsp;{i18n.t("menu.rate")}</h2></IonLabel>
                    </IonListHeader>
                    <IonRadioGroup value={PlayRate.toString()} key="PlayRate" onIonChange={(e) => SetRate(e.detail.value)}>
                    <IonItem hidden={Storag.GetAppType() != AppType.Full}>
                            <IonRadio value="0.7" slot="end" />
                            <IonLabel>{i18n.t("menu.rate1")}(0.7x)</IonLabel>
                        </IonItem>
                       <IonItem hidden={Storag.GetAppType() != AppType.Full}>
                            <IonRadio value="0.8" slot="end" />
                            <IonLabel>{i18n.t("menu.rate2")}(0.8x)</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonRadio value="1" slot="end" />
                            <IonLabel>{i18n.t("menu.rate3")}(1x)</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonRadio value="1.3" slot="end" />
                            <IonLabel>{i18n.t("menu.rate4")}(1.3x)</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonRadio value="1.6" slot="end" />
                            <IonLabel>{i18n.t("menu.rate5")}(1.6x)</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonRadio value="2" slot="end" />
                            <IonLabel>{i18n.t("menu.rate6")}(2x)</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonRadio value="2.5" slot="end" />
                            <IonLabel>{i18n.t("menu.rate7")}(2.5x)</IonLabel>
                        </IonItem>
                    </IonRadioGroup>
                </IonList>
            );
            break;
        case MenuType.Page:
            var VolumeNum = 0;
            var Regx = props.Code.match(/(.*_)([0-9]+)/);
            if (Regx != null && props.Info.Data.Volume != null) {
                VolumeNum = parseInt(Regx[2], 10);
            }

            MenuElement = (
                <IonList>
                    <IonListHeader hidden={Storag.GetAppType()!=AppType.Full}>
                        <IonLabel>
                            <h2><IonIcon icon={searchOutline}></IonIcon>&nbsp;{i18n.t("menu.search")}</h2>
                        </IonLabel>
                    </IonListHeader>
                    <IonItem hidden={Storag.GetAppType()!=AppType.Full}>
                        <IonInput value={SearchText} onKeyDown={x=>{if(x.keyCode==13){SearchText=(SearchInput?.value as string);SetSearchResult(props.Info.Query(SearchText));}}} placeholder={i18n.t("menu.search_input")} maxlength={50} ref={(e)=>{SearchInput = e;}}></IonInput>
                        <IonButton color="light" size="default" onClick={()=>{SearchText=(SearchInput?.value as string);SetSearchResult(props.Info.Query(SearchText));}}>{i18n.t("menu.search")}</IonButton>
                        <IonButton color="light" size="default" hidden={SearchResult==null} onClick={()=>{SearchText="";SetSearchResult(null);}}>{i18n.t("menu.clear")}</IonButton>
                    </IonItem>
                    
                    {SearchResult?.Result?.map((x,index)=>(<IonItem key={"search_"+index} button={true} onClick={()=>{SetChapter(x.Index)}}><IonLabel>&nbsp;<IonIcon icon={search}></IonIcon> {x.Sentence}</IonLabel><IonLabel slot="end">{i18n.t("menu.page",{page:x.Page})}</IonLabel></IonItem>))}
                    <IonItem hidden={!SearchResult?.IsOver}>
                        <div style={{textAlign:"center",width:"100%"}}>
                            ({i18n.t("menu.search_over")})
                        </div>
                    </IonItem>

                    <span hidden={SearchResult!=null}>
                        <IonItem>
                            <IonLabel>
                                <h2><IonIcon icon={readerOutline}></IonIcon>&nbsp;{i18n.t("menu.select_page")}</h2>
                            </IonLabel>
                            <IonSelect value={props.Info.Page} interface="popover" slot="end" onIonChange={(e) => { PageNumber = (e.detail.value as number);props.SeekPage(PageNumber); HideMenu();}}>
                                {(new Array(props.Info.PageList.length)).fill(null).map((x,i)=>(<IonSelectOption key={"page_"+i} value={i}>{i18n.t("menu.page",{page:i+1})}</IonSelectOption>))}
                            </IonSelect>
                        </IonItem>
                        <IonItem lines="none" hidden={props.Info.Data.Volume == null}>
                            <IonLabel>
                                <h2><IonIcon icon={libraryOutline}></IonIcon>&nbsp;{i18n.t("menu.select_volume")}</h2>
                            </IonLabel>
                            <IonSelect value={VolumeNum} interface="popover" slot="end" onIonChange={(e) => { SetVolumeNumber(e.detail.value as number);}}>
                                {(new Array(props.Info.Data.Volume)).fill(null).map((x,i)=>(<IonSelectOption key={"volume_"+i} value={i+1}>{i18n.t("menu.volume_num",{num:i+1})}</IonSelectOption>))}
                            </IonSelect>
                        </IonItem>
                        <IonItem hidden={props.Info.Data.Volume == null}>
                            <table style={{width:"100%"}}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <IonButton disabled={!(VolumeNum > 1)} size="default" color="light" onClick={() => {SetVolumeNumber(VolumeNum - 1); }}>{i18n.t("menu.pre_volume")}</IonButton>
                                        </td>
                                        <td style={{textAlign:"right"}}>
                                            <IonButton disabled={!(VolumeNum < (props.Info.Data.Volume??0))} size="default" color="light" onClick={() => { SetVolumeNumber(VolumeNum + 1); }}>{i18n.t("menu.next_volume")}</IonButton>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </IonItem>

                        <IonRadioGroup value={GetChapterIndex()} hidden={props.Info.ChapterList.length==0}>
                            <IonListHeader>
                                <IonLabel>
                                    <h2><IonIcon icon={bookOutline}></IonIcon>&nbsp;{i18n.t("menu.select_chapter")}</h2>
                                </IonLabel>
                            </IonListHeader>
                            {props.Info.ChapterList.map((item) => (<IonItem key={"chapter_" + item.Index}><IonRadio value={item.Index} onClick={() => { SetChapter(item.Index);}} slot="end" /><IonLabel>{item.Chapter}</IonLabel></IonItem>))}
                        </IonRadioGroup>
                    </span>
                </IonList>
            );
            break;
            case MenuType.Info:
                MenuElement = (
                    <IonList>
                         <IonItem>
                            <IonLabel>
                                <h2><IonIcon icon={bookOutline}></IonIcon>&nbsp;{i18n.t("info.sutra_name")}</h2>
                            </IonLabel>
                            {props.Info.Title}
                        </IonItem>

                        <IonItem>
                            <IonLabel>
                                <h2><IonIcon icon={alertCircleOutline}></IonIcon>&nbsp;{i18n.t("info.version")}</h2>
                            </IonLabel>
                            {i18n.t("info." + (AppType[Storag.GetAppType()].toLowerCase()) + "_version")} {process.env.REACT_APP_VERSION}
                        </IonItem>

                        <IonItem hidden={!Storag.GetDevelopmentMode() && !Device.IsHybrid}>
                            <IonLabel>
                                <h2><IonIcon icon={heartOutline}></IonIcon>&nbsp;{i18n.t("stote.buy")}</h2>
                            </IonLabel>
                            <IonButton color="primary" slot="end" size="default" onClick={() => {PurchaseRef.current?.ShowPurchase()}}>{i18n.t("stote.instruction")}</IonButton>
                            <SutraPurchase ref={PurchaseRef} OnHide={()=>{HideMenu();}}></SutraPurchase>
                        </IonItem>
                        

                        <IonItem hidden={!Storag.GetDevelopmentMode() && Device.IsHybrid}>
                            <IonLabel>
                                <IonIcon icon={cafeOutline}></IonIcon>&nbsp;{i18n.t("stote.donate")}
                            </IonLabel>
                                <IonButton  slot="end" size="default" class="CoffeeButton" href="https://www.buymeacoffee.com/mingyan.tw" target="_blank">
                                    <img src="assets/menu/buymeacoffee.svg" alt="" style={{height:"100%"}}></img>
                                </IonButton>  
                        </IonItem>
                
                        <IonItem lines="none">
                            <IonLabel>
                                <h2><IonIcon icon={alertCircleOutline}></IonIcon>{i18n.t("menu.report_problem")}</h2>
                            </IonLabel>
                            
                        </IonItem>
                        
                        <IonItem lines="none">
                            <div style={{width:"100%", backgroundColor:Storag.GetColorInfo().Background,color:Storag.GetColorInfo().Font,borderRadius:5,padding:10, fontSize:"120%",fontFamily:[PhoneticType[props.Info.PhoneticType], Storag.GetFontFamily()].filter(x=>x.length != 0 && x != "None").join(",")}}>
                                { props.Info.Data.Content[props.Info.SelectIndex??0].Sentence.map((x, index)=><span key={"sutra_" + index} style={{whiteSpace:"nowrap"}}>{x.Word+(props.Info.PhoneticType==PhoneticType.None?"":x.Phonetic)}</span>)}
                            </div>
                        </IonItem>
                        <IonItem lines="none">
                            <IonTextarea rows={4} maxlength={200} value={ReportText} placeholder={i18n.t("menu.report_text_body")} onIonChange={e => SetReportText(e.detail.value!)}></IonTextarea>
                            
                        </IonItem>
                        <IonItem>
                            <IonButton slot="end" size="default" color="light" disabled={ReportText==""} onClick={() => {SendReport();}}>{i18n.t("menu.report_text")}</IonButton>
                        </IonItem>
                    </IonList>
                );
                break; 
        default:
            var ItemList = [];
            if (props.Info.Data.Audio && Storag.GetSoundStatus()) {
                ItemList.push(
                    <div key="PlayStatus" className="button_area">
                        <img className="menu-img" src={"assets/menu/" + (Storag.GetPlayStatus() ? "pause" : "play") + ".svg"} onClick={() => { SetPlayStatus(!Storag.GetPlayStatus()) }} alt={i18n.t(" menu.play_status")}></img>
                        <div>{Storag.GetPlayStatus() ? i18n.t("menu.pause") : i18n.t("menu.play")}</div>
                    </div>
                );

                ItemList.push(
                    <div key="PlayRate" className="button_area">
                        <img className="menu-img" src={"assets/menu/fast.svg"} onClick={() => { SetShowMenu(MenuType.Rate) }} alt={i18n.t("menu.rate")}></img>
                        <div>{i18n.t("menu.rate")}</div>
                    </div>
                );
            }

            ItemList.push(
                <div key="Chapter" className="button_area">
                    <img className="menu-img" src={"assets/menu/book.svg"} onClick={() => { SetShowMenu(MenuType.Page) }} alt={i18n.t("menu.chapter")}></img>
                    <div>{i18n.t("menu.chapter")}</div>
                </div>
            );

            ItemList.push(
                <div key="Setting" className="button_area">
                    <img className="menu-img" src={"assets/menu/gear.svg"} onClick={() => { SetShowMenu(MenuType.Setting) }} alt={i18n.t("menu.setting")}></img>
                    <div>{i18n.t("menu.setting")}</div>
                </div>
            );

            ItemList.push(
                <div key="Infomation" className="button_area">
                    <img className="menu-img" src={"assets/menu/info.svg"} onClick={() => { SetShowMenu(MenuType.Info) }} alt={i18n.t("menu.info")}></img>
                    <div>{i18n.t("menu.info")}</div>
                </div>
            );

            ItemList.push(
                <div key="Exit" className="button_area">
                    <img className="menu-img" src={"assets/menu/logout.svg"} onClick={() => { GoList(); }} alt={i18n.t("menu.exit")}></img>
                    <div>{i18n.t("menu.exit")}</div>
                </div>
            );


            MenuElement = (
                <div>{ItemList}</div>
            );
            break;
    }
//cssClass={["contact-popover", Device.DeviceString().toLocaleLowerCase()]}
    return (
        <IonPopover isOpen={ShowMenu !== MenuType.Close} className={"contact-popover " + Device.DeviceString().toLocaleLowerCase()} onDidDismiss={(e) => HideMenu()} animated={false} ref={Popover}>
            <span style={{ fontSize: Storag.DefaultFontSize() * 0.4 }}>
                {MenuElement}
            </span>
        </IonPopover>
    );
}

export default forwardRef(SutraMenu);

import React from 'react';
import './SutraDemo.css';
import Storag,{PhoneticType} from '../library/Storag';
import { IonCard, IonCardContent } from '@ionic/react';
import Color from '../library/Color';

interface Props {

}


const SutraDemo: React.FC<Props> = (props) => {


    var FontSize = Storag.DefaultFontSize() * Storag.GetFontScale() *0.6;
    var Phonetic = Storag.GetPhoneticType()
    var RowCount = 9;
    var ColCount = 6;
    var RowSpace = (Phonetic== PhoneticType.PinYin)?0.3:0.05;
    var ColSpace = (Phonetic== PhoneticType.Bopomofo || Phonetic==PhoneticType.PinYinRotate)?0.52:0.3;
    var TopSpace = FontSize * ((Phonetic== PhoneticType.PinYin)?0.6:0);
    var StrokeWidth: number = Math.floor(FontSize /5);
    var Text = [];

    if(Storag.GetLanguage()=="cn")
        Text = [["观", "世", "音", "，", "南", "无", "佛", "，", "与"], ["佛", "有", "因", "，", "与", "佛", "有", "缘", "，"], ["佛", "法", "相", "缘", "，", "常", "乐", "我", "净"], ["，", "朝", "念", "观", "世", "音", "，", "暮", "念"], ["观", "世", "音", "，", "念", "念", "从", "心", "起"], ["，", "念", "念", "不", "离", "心", "。"]];
    else
        Text = [["觀", "世", "音", "，", "南", "無", "佛", "，", "與"], ["佛", "有", "因", "，", "與", "佛", "有", "緣", "，"], ["佛", "法", "相", "緣", "，", "常", "樂", "我", "淨"], ["，", "朝", "念", "觀", "世", "音", "，", "暮", "念"], ["觀", "世", "音", "，", "念", "念", "從", "心", "起"], ["，", "念", "念", "不", "離", "心", "。"]];
    
    var PhoneticText:string[][] = [[]];


    if(Phonetic != PhoneticType.None)
        PhoneticText = [["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", ""]];


            
    var Width = (ColCount * (1 + ColSpace) + (Phonetic== PhoneticType.Bopomofo || Phonetic== PhoneticType.PinYinRotate?0:ColSpace)) * FontSize;
    var Hight = (RowCount * (1 + RowSpace) + RowSpace) * FontSize;

    //paintOrder在ios有時會失效
    var Color = Storag.GetColorInfo();
    return (
        <IonCard>
            <IonCardContent style={{ backgroundColor: Color.Background, padding: (FontSize * 0.5) + "px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" className="no_select" fontFamily={[PhoneticType[Phonetic], Storag.GetFontFamily()].filter(x=>x.length != 0 && x != "None").join(",")} height={Hight} width={Width}  >
                    {PhoneticText[0].filter((x, col) => col < 3).map((y, row) => <text key={"Demo1_"+row} x={Width - (FontSize * (1 + ColSpace))+ FontSize} y={TopSpace + FontSize * ((row + 1) + (row - 1) * RowSpace)}
                        fontSize={FontSize} paintOrder="stroke" stroke={Color.Stroke} strokeLinejoin="round"
                        strokeWidth={StrokeWidth}>{y}</text>)}

                    {Text[0].filter((x, col) => col < 3).map((y, row) => <text key={"Demo4_"+row} x={Width - (FontSize * (1 + ColSpace))} y={TopSpace + FontSize * ((row + 1) + (row - 1) * RowSpace)}
                        fontSize={FontSize} paintOrder="stroke" stroke={Color.Stroke} strokeLinejoin="round"
                        strokeWidth={StrokeWidth}>{y}</text>)}

                    {PhoneticText.map((x, col) => (x.map((y, row) => <text key={"Demo5_"+row} x={Width - (FontSize * ((col + 1) + (col + 1) * ColSpace))+ FontSize} y={TopSpace + FontSize * ((row + 1) + (row - 1) * RowSpace)}
                        fontSize={FontSize} fill={ColCount > col + 1 ? ((col === 0 && row < 3) ? Color.SelectedFont : Color.Font) : (Storag.GetPreview() ? Color.Preview : Color.Font)}>{y}</text>)))}

                    {Text.map((x, col) => (x.map((y, row) => <text key={"Demo8_"+row} x={Width - (FontSize * ((col + 1) + (col + 1) * ColSpace))} y={TopSpace + FontSize * ((row + 1) + (row - 1) * RowSpace)}
                        fontSize={FontSize} fill={ColCount > col + 1 ? ((col === 0 && row < 3) ? Color.SelectedFont : Color.Font) : (Storag.GetPreview() ? Color.Preview : Color.Font)}>{y}</text>)))}
                </svg>
            </IonCardContent>
        </IonCard>
    );
}
        
export default SutraDemo;
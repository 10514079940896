import { IonRouterOutlet, IonTabButton, IonLabel, IonIcon, IonTabBar, IonTabs } from '@ionic/react';
import React from 'react';
import './SutraTabs.css';
import { library, helpCircle, construct, personOutline } from 'ionicons/icons';
import i18n from '../library/i18n';

interface Props {
   
}
const SutraTabs: React.FC<Props> = (props) => {
    
    return (
        <IonTabs>
            <IonRouterOutlet>
                {props.children}
            </IonRouterOutlet>
            <IonTabBar slot="bottom">

                <IonTabButton tab="Recite" href='/Recite'>
                    <IonIcon icon={personOutline} />
                    <IonLabel>{i18n.t("list.recite")}</IonLabel>
                </IonTabButton>

                <IonTabButton tab="Read" href='/Read'>
                    <IonIcon icon={library} />
                    <IonLabel>{i18n.t("list.read")}</IonLabel>
                </IonTabButton>


                <IonTabButton tab="Help" href='/Help'>
                    <IonIcon icon={helpCircle} />
                    <IonLabel>{i18n.t("list.help")}</IonLabel>
                </IonTabButton>
                <IonTabButton tab="Setting" href='/Setting'>
                    <IonIcon icon={construct} />
                    <IonLabel>{i18n.t("list.setting")}</IonLabel>
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
    );
};

export default SutraTabs;

import React, { useState } from 'react';
import './SutraColor.css';
import Color from '../library/Color';
import { SliderPicker } from 'react-color';
import { IonButton, useIonPopover } from '@ionic/react';
import Storag, { AppType } from '../library/Storag';
import i18n from '../library/i18n';

interface Props {
    Color:number;
    OnChange?:(index:number)=>void;
    slot?: string | undefined;
}

const SutraColor: React.FC<Props> = (props) => {
    const [ColorShow, ColorClose] = useIonPopover(SutraColorMenu, {ColorValue:props.Color,OnChange:(color:number)=>{ColorClose();OnClick(color);}});

    const OnClick = (color:number)=>{
        if(props.OnChange != undefined){
            props.OnChange(color);
        }
    }

    return (
        <IonButton size="default" slot={props.slot} color="light" onClick={(e) => ColorShow({event: e.nativeEvent,})}>
            <div style={{backgroundColor:Color.ToHex(props.Color),borderColor:"#F0F0F0", borderStyle:"solid",borderWidth:"1px",height:"25px", width:"25px", borderRadius:"4px",margin:"-10px 10px -10px -10px"}}></div>
            {i18n.t("menu.setting")}
        </IonButton>
    );
}

const SutraColorMenu: React.FC<{ColorValue:number, OnChange:(color:number) => void;}> = ({ColorValue, OnChange}) => {
    const CircleSize =  32;
    const [ColorState, SetColorState] = useState(ColorValue);

    const Circle = (color:number) => {
        var ColorData = new Color(color);
        return (
            <span>
                <div className="SutraColorCircle" style={{width:CircleSize+"px", height:CircleSize+"px", marginRight:(CircleSize/3)+"px",  marginBottom:(CircleSize/2)+"px"}} onClick={()=>{OnChange(color);}}>
                    <span>
                        <div hidden={!(ColorData.Hsl.l>=0.95)} style={{height:"100%", width:"100%",position:"absolute", borderRadius:"50%",backgroundColor:"#F9F9F9"}}>
                        </div>
                        <div style={{borderColor:"#F0F0F0", borderStyle:(ColorData.Hsl.l>=0.95?"solid":"none"),borderWidth:"1px",background:"transparent", height:"100%", width:"100%",cursor:"pointer", position:"relative", outline:"none", borderRadius:"50%", boxShadow: ColorData.Background + " 0px 0px 0px " + (ColorValue==color?(CircleSize/7):(CircleSize/2+1)) + "px inset, " + (ColorData.Hsl.l>=0.95?"#E0E0E0":ColorData.Background) + " 0px 0px "+(ColorValue==color?8:0)+"px", transition:"box-shadow 100ms ease 0s", textAlign:"center",paddingTop:Math.floor(CircleSize/5)+"px"}}>
                        </div>
                    </span>
                </div>
            </span>
        );
    }

    if(Storag.GetAppType() != AppType.Base){
        return (
            <div>
                <div style={{width:"90%",height:"95%", padding:"10px 0px 10px 0px",display:"flex", flexWrap:"wrap",marginLeft:(CircleSize/2)+"px", marginRight:"-"+(CircleSize/2)+"px", marginBottom:"-"+(CircleSize/2)+"px"}}>
                    {Color.ColorListFull.map((x)=>Circle(x))}
                </div>
                <div style={{width:"95%",padding:"10px 10px 20px 20px"}}>
                     <SliderPicker color={Color.ToHex(ColorState)} onChangeComplete={(data, event)=>{SetColorState(Color.FromRgb(data.rgb));if(event.target.className==""){OnChange(Color.FromRgb(data.rgb));}}}></SliderPicker>
                </div>  
            </div>
        );
    }

    return (
        <div style={{width:"95%",paddingBottom:"20px",paddingTop:"10px"}}>
            <div style={{width:"90%",height:"95%", padding:"10px 0px 0px 0px",display:"flex", flexWrap:"wrap",marginLeft:(CircleSize/2)+"px", marginRight:"-"+(CircleSize/2)+"px", marginBottom:"-"+(CircleSize/2)+"px"}}>
            {Color.ColorListBase.map((x)=>Circle(x))}
            </div>
        </div>
    );
   
}

export default SutraColor;
import { IonContent, IonPage, IonCard, IonCardHeader, IonCardTitle } from '@ionic/react';
import React, { useEffect } from 'react';
import './Help.css';
import SutraTabs from '../components/SutraTabs';
import Device, { DeviceType } from '../library/Device';
import i18n from '../library/i18n';
import Analytics from '../library/Analytics';


const Help: React.FC = () => {

    useEffect(() => {
        document.title = (i18n.getDataByLanguage("tw")?.translation.list as any).help;
        Analytics.ScreenName(document.title,"Help");
    });


    var ClassName = "help " + DeviceType[Device.Device].toLocaleLowerCase();

    return (
        <IonPage>
            <IonContent forceOverscroll={false}>
                <SutraTabs>
                    <IonContent>
                        <div className="statusbar"></div>
                        <div>
                            <div className={ClassName}>
                                <IonCard>
                                    <table style={{ height: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                    <picture>
                                                        <source srcSet="assets/help/orientation2.svg" media="(prefers-color-scheme: dark)" />
                                                        <img src="assets/help/orientation.svg" style={{ width: "100%" }} alt=""></img>
                                                    </picture>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ verticalAlign: "middle", height: "25%" }}>
                                                    <IonCardHeader>
                                                        <IonCardTitle>{i18n.t("help.orientation")}</IonCardTitle>
                                                    </IonCardHeader>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot></tfoot>
                                    </table>
                                </IonCard>
                            </div>
                            <div className={ClassName}>
                                <IonCard >
                                    <table style={{ height: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                    <picture>
                                                        <source srcSet="assets/help/device2.svg" media="(prefers-color-scheme: dark)" />
                                                        <img src="assets/help/device.svg" style={{ width: "100%" }} alt=""></img>
                                                    </picture>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ verticalAlign: "middle", height: "25%" }}>
                                                    <IonCardHeader>
                                                        <IonCardTitle>{i18n.t("help.device")}</IonCardTitle>
                                                    </IonCardHeader>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </IonCard>
                            </div>
                            <div className={ClassName}>
                                <IonCard >
                                    <table style={{ height: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "53%", verticalAlign: "middle" }}>
                                                    <picture>
                                                        <source srcSet="assets/help/swipe2.svg" media="(prefers-color-scheme: dark)" />
                                                        <img src="assets/help/swipe.svg" style={{ width: "100%" }} alt=""></img>
                                                    </picture>
                                                </td>
                                                <td>
                                                    <IonCardHeader style={{ width: "100%" }}>
                                                        <IonCardTitle>{i18n.t("help.swipe")}</IonCardTitle>
                                                    </IonCardHeader></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </IonCard>
                            </div>
                            <div className={ClassName}>
                                <IonCard >
                                    <table style={{ height: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                    <picture>
                                                            <source srcSet="assets/help/pace2.svg" media="(prefers-color-scheme: dark)" />
                                                            <img src="assets/help/pace.svg" style={{ width: "93%" }} alt=""></img>
                                                    </picture>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ verticalAlign: "middle", height: "25%" }}>
                                                    <IonCardHeader>
                                                        <IonCardTitle>{i18n.t("help.pace")}</IonCardTitle>
                                                    </IonCardHeader>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </IonCard>
                            </div>
                            <div className={ClassName}>
                                <IonCard >
                                    <table style={{ height: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                    <picture>
                                                        <source srcSet="assets/help/preview2.svg" media="(prefers-color-scheme: dark)" />
                                                        <img src="assets/help/preview.svg" style={{ width: "100%" }} alt=""></img>
                                                    </picture>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ verticalAlign: "middle", height: "25%" }}>
                                                    <IonCardHeader>
                                                        <IonCardTitle>{i18n.t("help.preview")}</IonCardTitle>
                                                    </IonCardHeader>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </IonCard>
                            </div>
                            <div className={ClassName}>
                                <IonCard >
                                    <table style={{ height: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "55%", verticalAlign: "middle" }}>
                                                    <picture>
                                                        <source srcSet="assets/help/light2.svg" media="(prefers-color-scheme: dark)" />
                                                        <img src="assets/help/light.svg" style={{ width: "100%" }} alt=""></img>
                                                    </picture>
                                                </td>
                                                <td>
                                                    <IonCardHeader style={{ width: "100%" }}>
                                                        <IonCardTitle>{i18n.t("help.light")}</IonCardTitle>
                                                    </IonCardHeader></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </IonCard>
                            </div>
                            <div className={ClassName}>
                                <IonCard >
                                    <table style={{ height: "100%", width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                    <picture>
                                                        <source srcSet="assets/help/menu2.svg" media="(prefers-color-scheme: dark)" />
                                                        <img src="assets/help/menu.svg" style={{ width: "100%" }} alt=""></img>
                                                    </picture>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ verticalAlign: "middle", height: "12%" }}>
                                                    <IonCardHeader>
                                                        <IonCardTitle>{i18n.t("help.menu")}</IonCardTitle>
                                                    </IonCardHeader>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </IonCard>
                            </div>
                            <div className={ClassName}>
                                <IonCard>
                                    <table style={{ height: "100%", width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                    <picture>
                                                        <source srcSet="assets/help/play2.svg" media="(prefers-color-scheme: dark)" />
                                                        <img src="assets/help/play.svg" style={{ width: "100%" }} alt=""></img>
                                                    </picture>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ verticalAlign: "middle", height: "25%" }}>
                                                    <IonCardHeader>
                                                        <IonCardTitle>{i18n.t("help.play")}</IonCardTitle>
                                                    </IonCardHeader>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </IonCard>
                            </div>
                            <div className={ClassName}>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: "bottom" }}>
                                                            <picture>
                                                                <source srcSet="assets/help/gear2.svg" media="(prefers-color-scheme: dark)" />
                                                                <img style={{ width: "2.5em" }} src="assets\menu\gear.svg" alt=""></img>
                                                            </picture>
                                                        </td>
                                                        <td style={{ verticalAlign: "bottom", fontSize: "150%" }}>
                                                            &nbsp;{i18n.t("menu.setting")}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div>
                                                <div style={{ marginTop: "0.5em", fontWeight: "bold" }}>{i18n.t("menu.font_size")}</div>
                                                <div>{i18n.t("help.set_font_size")}</div>
                                                <div style={{ marginTop: "0.5em", fontWeight: "bold" }}>{i18n.t("menu.font")}</div>
                                                <div>{i18n.t("help.set_font")}</div>
                                                <div style={{ marginTop: "0.5em", fontWeight: "bold" }}>{i18n.t("menu.theme")}</div>
                                                <div>{i18n.t("help.set_theme")}</div>
                                                <div style={{ marginTop: "0.5em", fontWeight: "bold" }}>{i18n.t("menu.preview")}</div>
                                                <div>{i18n.t("help.set_preview")}</div>
                                            </div>
                                        </IonCardTitle>
                                    </IonCardHeader>
                                </IonCard>
                            </div>
                            <div className={ClassName}>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: "bottom" }}>
                                                            <picture>
                                                                <source srcSet="assets/help/fast2.svg" media="(prefers-color-scheme: dark)" />
                                                                <img style={{ width: "2.5em" }} src="assets\menu\fast.svg" alt=""></img>
                                                            </picture>
                                                        </td>
                                                        <td style={{ verticalAlign: "bottom", fontSize: "150%" }}>
                                                            &nbsp;{i18n.t("menu.rate")}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div>
                                                <div style={{ marginTop: "0.5em", fontWeight: "bold" }}>{i18n.t("menu.rate")}</div>
                                                <div>{i18n.t("help.set_rate")}</div>
                                                <div style={{ marginTop: "0.5em", fontWeight: "bold" }}>{i18n.t("menu.repeat")}</div>
                                                <div>{i18n.t("help.set_repeat")}</div>
                                                <div style={{ marginTop: "0.5em", fontWeight: "bold" }}>{i18n.t("menu.resume_auto")}</div>
                                                <div>{i18n.t("help.set_resume_auto")}</div>

                                            </div>
                                        </IonCardTitle>
                                    </IonCardHeader>
                                </IonCard>
                            </div>
                            <div className={ClassName}>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: "bottom" }}>
                                                            <picture>
                                                                <source srcSet="assets/help/book2.svg" media="(prefers-color-scheme: dark)" />
                                                                <img style={{ width: "2.5em" }} src="assets\menu\book.svg" alt=""></img>
                                                            </picture>
                                                        </td>
                                                        <td style={{ verticalAlign: "bottom", fontSize: "150%" }}>
                                                            &nbsp;{i18n.t("menu.chapter")}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div>
                                                <div style={{ marginTop: "0.5em", fontWeight: "bold" }}>{i18n.t("menu.select_page")}</div>
                                                <div>{i18n.t("help.select_page")}</div>
                                                <div style={{ marginTop: "0.5em", fontWeight: "bold" }}>{i18n.t("menu.select_chapter")}</div>
                                                <div>{i18n.t("help.select_chapter")}</div>
                                            </div>
                                        </IonCardTitle>
                                    </IonCardHeader>
                                </IonCard>
                            </div>
                        </div>

                    </IonContent>
                </SutraTabs>
            </IonContent>
        </IonPage>
    );
};

export default Help;

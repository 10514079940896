import { InAppPurchase2, IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2';
import Storag, { AppType } from './Storag';


class Stote {
    static IsApproved = false;
    static EventApproved:(product: IAPProduct) => void | null;

    static Register(id: string) {
        Stote.IsApproved = false;
        //InAppPurchase2.verbosity = InAppPurchase2.DEBUG;
        InAppPurchase2.register(
            {
                id: id,
                type: InAppPurchase2.NON_CONSUMABLE
            }
        );

        InAppPurchase2.ready(() => {
            setTimeout(() => {
                //ios not
                if(!Stote.Get(id).owned){
                    if(!Storag.GetDevelopmentMode()){
                        Storag.SetAppType(AppType.Base);
                    }
                }
            }, 1000 * 2 * 60);
        });
        

        InAppPurchase2.error((err: any) => {
            //alert('Error1: ' + JSON.stringify(err));
        });

        InAppPurchase2.when(id).error((err: any) => {
            //alert('Error2: ' + JSON.stringify(err));
        });

        InAppPurchase2.when(id).approved((product: IAPProduct) => {
            product.verify();
        }).verified((product: IAPProduct) => {
            product.finish();
        }).finished((product: IAPProduct)=>{
            
            if(!Storag.GetDevelopmentMode()){
                Storag.SetAppType(AppType.Full);
            }

            if(Stote.EventApproved != null && !Stote.IsApproved)
                Stote.EventApproved(product);

            Stote.IsApproved = true;
        });


        InAppPurchase2.refresh();
    }

    static Get(id: string): IAPProduct {
        return InAppPurchase2.get(id);
    }

    static Order(id: string,onPurchased:()=>void, onApproved:(product: IAPProduct) => void) {
        if(Stote.IsApproved){
            onPurchased();
        }else{
            Stote.EventApproved = onApproved;
            Stote.IsApproved = false;
            InAppPurchase2.order(id);//.error((err:any)=>{alert(JSON.stringify(err));}); 
        }

    }

    static Restore(onApproved:(product: IAPProduct) => void) {
        Stote.EventApproved = onApproved;
        Stote.IsApproved = false;
        InAppPurchase2.refresh();  
    }

  
}

export default Stote;
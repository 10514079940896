import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import './SutraTour.css';
import Info from '../library/Info';
import Storag from '../library/Storag';
import Color from '../library/Color';

export interface TourHandles {
    Refresh(): void;
    Hide(): void;
}

interface Props {
    Info: Info;
    Color: Color;
    PageChange(page: number): void;
}

const LeftRef = React.createRef<HTMLImageElement>();
const RightRef = React.createRef<HTMLImageElement>();

let IndexPage: number;
const SutraTour: React.ForwardRefRenderFunction<TourHandles, Props> = (props, ref) => {
    useEffect(() => {
        Refresh();
    });

    useImperativeHandle(ref, () => ({
        Refresh: Refresh,
        Hide: Hide
    }));


    const Hide = () => {
        if (LeftRef.current != null && RightRef.current != null) {
            LeftRef.current.hidden = true;
            RightRef.current.hidden = true;
        }
    }


    const Refresh = () => {
        if (LeftRef.current != null && RightRef.current != null) {
            LeftRef.current.hidden = true;
            RightRef.current.hidden = true;
            if (props.Info.SelectIndex != null) {
                IndexPage = props.Info.IndexPage[props.Info.SelectIndex];
                
                if(RightRef.current)
                    if (props.Info.Page > IndexPage) {
                        if(typeof RightRef.current.getAnimations === "function"){
                            if(RightRef.current.getAnimations().length == 0)
                                RightRef.current.animate([{ transform: "translateX(-" + Storag.DefaultFontSize() / 4 + "px)",opacity:0 }, { transform: "translateX(-" + Storag.DefaultFontSize() / 5 + "px)",opacity:1 }, { transform: "translateX(0px)" ,opacity:0.3}], { iterations: Infinity, duration: 2500 });
                        }else{
                            if(typeof RightRef.current.animate === "function")
                                RightRef.current.animate([{ transform: "translateX(-" + Storag.DefaultFontSize() / 4 + "px)",opacity:0 }, { transform: "translateX(-" + Storag.DefaultFontSize() / 5 + "px)",opacity:1 }, { transform: "translateX(0px)" ,opacity:0.3}], { iterations: Infinity, duration: 2500 });
                        }
                        RightRef.current.hidden = false;
                    }

                if(LeftRef.current)
                    if (props.Info.Page < IndexPage) {
                        if(typeof LeftRef.current.getAnimations === "function"){
                            if(LeftRef.current.getAnimations().length == 0)
                                LeftRef.current.animate([{ transform: "translateX(" + Storag.DefaultFontSize() / 4 + "px)",opacity:0 }, { transform: "translateX(" + Storag.DefaultFontSize() / 5 + "px)",opacity:1 }, { transform: "translateX(0px)" ,opacity:0.3}], { iterations: Infinity, duration: 2500 });
                        }else{
                            if(typeof LeftRef.current.animate === "function")
                                LeftRef.current.animate([{ transform: "translateX(" + Storag.DefaultFontSize() / 4 + "px)",opacity:0 }, { transform: "translateX(" + Storag.DefaultFontSize() / 5 + "px)",opacity:1 }, { transform: "translateX(0px)" ,opacity:0.3}], { iterations: Infinity, duration: 2500 });
                        }

                        LeftRef.current.hidden = false;
                    }
            }
        }
    }

    return (
        <table style={{ width: props.Info.PageWidth }}>
            <tbody>
                <tr>
                    <td style={{ width: Storag.DefaultFontSize() * 2 }}>
                        <img src="/assets/left-arrow.svg" ref={LeftRef} hidden={true} onClick={() => { props.PageChange(IndexPage) }} style={{ width: Storag.DefaultFontSize() * 1.7,  filter: props.Color.Invert ? "invert(1)" : "" }} alt="" />
                    </td>
                    <td style={{ textAlign: "right" }}>
                        <img src="/assets/right-arrow.svg" ref={RightRef} hidden={true} onClick={() => { props.PageChange(IndexPage) }} style={{ width: Storag.DefaultFontSize() * 1.7,  filter: props.Color.Invert ? "invert(1)" : "" }} alt="" />
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

export default forwardRef(SutraTour);
import Storag, { PhoneticType } from './Storag';
import Device from '../library/Device';
import Download, { DownloadError } from './Download';
import Chinese from './Chinese';
import Analytics from './Analytics';

export interface ChapterData {
    Chapter: string;
    Index: number;
}


export interface SentenceData {
    Index: number | null;
    Data: string | null;
    Word: WordPhonetic[];
}

export interface SutraJson {
    Title: string;
    Volume: number | null;
    Audio: boolean;
    Indent: boolean| null;
    Phonetic: boolean| null;
    Content: ContentJson[];
}

export interface SutraData {
    Title: string;
    SourceTitle: string;
    Volume: number | null;
    Audio: boolean;
    Indent: boolean;
    Phonetic: boolean;
    Content: ContentData[];
}


export interface ContentJson {
    Sentence: string;
    Data: string|null;
    Punctuation: string;
    Chapter: string;
    Paragraph: string;
    Time: string;
}


export interface ContentData {
    Sentence: WordPhonetic[];
    Data: string|null;
    SentenceText: string; //搜尋用
    Punctuation: string[];
    Chapter: string;
    Paragraph: string;
    Time: number[];

    //記錄用 用於後面查詢
    Page: number | null;
}

export interface QueryResult {
    IsOver:boolean;
    Result:QueryData[];
}


export interface QueryData {
    Sentence: string;
    Page: number;
    Index: number;
}


export interface WordPhonetic {
    Word: string;
    Phonetic: string;
}

export interface TimeData {
    Second: number;
    Index: number;
}

class Info {
    RowCount: number = 0;
    ColCount: number = 0;
    SideWidth: number = 0;
    PageWidth: number = 0;
    PageHeight: number = 0;

    //經文的內容分頁
    PageList: SentenceData[][][] = [];

    //句子Index對應到頁面
    IndexPage: number[] = [];

    //章節清單
    ChapterList: ChapterData[] = [];

    SelectIndex: number | null = null;

    TimeIndex: TimeData[] = [];

    IndexTime: (number | undefined)[] = [];

    Data: SutraData = { Title: "", SourceTitle:"", Audio: false, Volume: null, Indent:false, Phonetic:false, Content: [] };

    Page: number = 0;
    Title: string = "";

    EdgeHeight: number;

    InfoLoaded: boolean = false;
    JsonLoaded: boolean = false;
    ParserCount: number = 0;

    UseStorage: boolean = true;

    PhoneticType: PhoneticType = PhoneticType.Bopomofo;

    static GetVolumeString = (num: number, volume: number,language:string) => {
        if(language != "en"){
            if (volume == 3) {
                       return "上中下".substr(num - 1, 1);
                   } else if (volume == 2) {
                       return "上下".substr(num - 1, 1);
                   }
        }

        return num.toString().padStart(Math.ceil(Math.pow(volume, 1 / 10)), "0");
    }

    static GetShortcutVolumeString = (num: number, volume: number, language:string) => {
        if(language == "en"){
            return num.toString();
        }

        if (volume == 3) {
            return "上中下".substr(num - 1, 1);
        } else if (volume == 2) {
            return "上下".substr(num - 1, 1);
        }

        var Chinese = "";
        var ChineseNumber = "零一二三四五六七八九";
        var ChineseUnit = "十百";
        for(var i = 0; i <= 2; i++){
            var Num = num % 10;
            num = Math.floor(num / 10);
            if(i != 0 && !(i==1 && Num==0)){
                Chinese = ChineseUnit.substring(i-1,i) + Chinese;
            }
            if(!(i==1 && Num==1 && num==0) && !(i==0 && Num==0))
                Chinese = ChineseNumber.substring(Num,Num+1) + Chinese;

            if(num==0)
                break;
        }

        return "第" + Chinese;
    }

    SetInfo() {
        try {
            var FontSize = Storag.GetFontSize();

            //this.ShowPreview = Storag.GetPreview();
            this.SideWidth = FontSize * 1.4;
            this.PageWidth = Device.GetWidth() - this.SideWidth;
            this.PageHeight = Device.GetHeight() - this.EdgeHeight * 2;
            this.PhoneticType = Storag.GetPhoneticType();

            var PhoneticRight = 1.3;
            var PhoneticTop = 1.05;

            //計算拼音或注音要留的空白
            if(this.PhoneticType != PhoneticType.None){
                if(this.PhoneticType == PhoneticType.PinYin){
                    if(this.Data.Phonetic)
                        PhoneticTop= 1.42;
                }else{
                    if(this.Data.Phonetic)
                        PhoneticRight= 1.52;
                }
            }

            this.ColCount = Math.floor((this.PageWidth - (FontSize * 0.3)) / (FontSize * PhoneticRight));
            this.RowCount = Math.floor((this.PageHeight - (FontSize * 0.05)) / (FontSize * PhoneticTop));

            this.Parser(this.ColCount, this.RowCount, Storag.GetPreview());
        

            if (this.InfoLoaded) {
                if (this.SelectIndex !== null){
                    this.Page = this.IndexPage[this.SelectIndex]
                }
                
            }

            this.InfoLoaded = true;
        } catch (error) {
            Analytics.Exception(error);
        }
    }

    constructor() {
        this.EdgeHeight = Storag.DefaultFontSize() * 1.9;
    }

    LoadJson = (filename:string, onLoad: () => void, onError: (type:DownloadError, error: any) => void) => {
        Download.DownloadJson(filename,(json:any)=>{
            if(json){
                this.ProcessJson(json);
                this.JsonLoaded = true;
                onLoad();  
            }else{
                onError(DownloadError.JsonParse, null);
            }
        },onError);
    }

    //先處理原始的Json資料 因為有utf16和簡體要處理 先處理完 翻轉不用重新處理
    ProcessJson = (json:SutraJson) => {
        try {
            var Language = Storag.GetLanguage();
            if(Language == "cn"){
                Chinese.Load();
                this.Data.Title = Chinese.ConvertString(json.Title);
            }
            else{
                this.Data.Title = json.Title;
            }
            this.Data.SourceTitle = json.Title;

            this.Data.Audio = json.Audio;
            this.Data.Volume = json.Volume;
            this.Data.Indent = (json.Indent == true);
            this.Data.Phonetic = (json.Phonetic??false);
            this.Data.Content = [];
            for (var i = 0; i < json.Content.length; i++) {
                //處理文字
                var Sentence:WordPhonetic[] = [];
                if (json.Content[i].Sentence != null) {
                    for (var j = 0; j < json.Content[i].Sentence.length; j++) {
                        //處理Unicode
                        var Char = "";
                        var Phonetic = "";
                        if(Chinese.IsUtf16(j, json.Content[i].Sentence)){
                            Char = json.Content[i].Sentence.substr(j, 2);
                            j++;
                            if(Chinese.IsPhonetic(j+1, json.Content[i].Sentence)){
                                Phonetic = json.Content[i].Sentence.substr(j+1, 1);
                                j++;
                            }
                        }else{
                            Char = json.Content[i].Sentence.substr(j, 1);
                            if(Chinese.IsPhonetic(j+1, json.Content[i].Sentence)){
                                Phonetic = json.Content[i].Sentence.substr(j+1, 1);
                                j++;
                            }
                        }
                        if(Language == "cn")
                            Sentence.push({Word:Chinese.ConvertChar(Char),Phonetic:Phonetic});
                        else
                            Sentence.push({Word:Char,Phonetic:Phonetic});
                    }
                }
                
                //標點符號
                var Punctuation:string[] = [];
                if (json.Content[i].Punctuation != null) {
                    for (var k = 0; k < json.Content[i].Punctuation.length; k++) {
                        Punctuation.push(json.Content[i].Punctuation.substr(k, 1));
                    }
                }

                //時間
                var Time:number[] = [];
                if (json.Content[i].Time != null)
                    if (json.Content[i].Time != "") {
                        Time = json.Content[i].Time.split(";").map(x => this.ParserTime(x));
                        Time.sort();
                    }
                var Item:ContentData={
                    Chapter: json.Content[i].Chapter,
                    Sentence: Sentence,
                    Data: json.Content[i].Data,
                    SentenceText:Sentence.map(x=>x.Word).join(""),
                    Punctuation: Punctuation,
                    Paragraph: json.Content[i].Paragraph,
                    Time: Time,
                    Page: null
                };
                this.Data.Content.push(Item);
            }
        } catch (error) {
            Analytics.Exception(error, JSON.stringify(json));
        }
    }


    ParserTime = (time: string) => {
        var TimePart = time.split(":");
        if (TimePart.length > 2) {
            return (parseInt(TimePart[0], 10) * 60 * 60) + (parseInt(TimePart[1], 10) * 60) + (parseInt(TimePart[2].substr(0, 2), 10)) + (parseFloat(TimePart[2].substr(3, 2)) / 100);
        } else {
            return (parseInt(TimePart[0], 10) * 60) + (parseInt(TimePart[1].substr(0, 2), 10)) + (parseFloat(TimePart[1].substr(3, 2)) / 100);
        }
    }

    Parser = (colCount: number, rowCount: number, showPreview: boolean) => {
        this.PageList = [];
        this.IndexPage = [];

        var RowArray: WordPhonetic[] = [];
        var Sentence: SentenceData[] = [];
        var Page: SentenceData[][] = [];
        var RowIndex = 0;
        var ShowPhonetic = this.Data.Phonetic && Storag.GetPhoneticType() !=PhoneticType.None;

        if (showPreview) {
            colCount = colCount - 1;
        }

        this.Title = this.Data.Title;

        for (var i = 0; i < this.Data.Content.length; i++) {
            var j = 0;
            this.IndexPage.push(this.PageList.length);

            //時間
            if (this.ParserCount === 0) {
                if (this.Data.Content[i].Time.length != 0) {
                     //重複部分
                    for (let k = 0; k < this.Data.Content[i].Time.length; k++) {
                        this.TimeIndex.push({ Second: this.Data.Content[i].Time[k], Index: i });
                    }
                    //重複部分以第一個時間
                    this.IndexTime.push(this.Data.Content[i].Time[0]);
                } else {
                    this.IndexTime.push(undefined);
                }
            }

            var PunctuationItem = this.Data.Content[i].Punctuation;
            var SentenceItem = this.Data.Content[i].Sentence;
            var DataItem = this.Data.Content[i].Data;

            if(DataItem){
                Sentence.push({ Index: i, Word: [], Data:DataItem});
                DataItem = null;
                this.Data.Content[i].Paragraph = "feed";
            }
            


            if (SentenceItem.length != 0) {
                //文字
                //章節
                if (this.ParserCount === 0) {
                    if (i === 0 && this.Data.Content[i].Chapter === null) {
                        this.ChapterList.push({ Chapter: "(經文開頭)", Index: i });
                    } else if (this.Data.Content[i].Chapter != null) {
                        this.ChapterList.push({ Chapter: this.Data.Content[i].Chapter, Index: i });
                    }
                }

                this.Data.Content[i].Page = this.PageList.length+1;
                
                //處理偈語縮排
                if(this.Data.Indent){
                    if(RowIndex == 0 && i != 0){
                        if ((this.Data.Content[i].Paragraph??"").indexOf("block") >= 0){
                            SentenceItem = SentenceItem.filter(x => x.Phonetic != "" || x.Word != "");
                            if((SentenceItem.length + PunctuationItem.length) < rowCount)
                                SentenceItem.unshift({Word:"",Phonetic:""});
                        }else if(this.Data.Content[i].Paragraph == "wrap" || this.Data.Content[i].Paragraph == "blank"){
                            if(this.Data.Content[i-1].Paragraph != null){
                                if (this.Data.Content[i-1].Paragraph.indexOf("block") >= 0){
                                    SentenceItem = SentenceItem.filter(x => x.Phonetic != "" || x.Word != "");
                                    if((SentenceItem.length + PunctuationItem.length) < rowCount)
                                        SentenceItem.unshift({Word:"",Phonetic:""});
                                }
                            }
                        }
                    }
                }
               
                for (j = 0; j < SentenceItem.length; j++) {
                    //是否顯示注音
                    if(ShowPhonetic){
                        RowArray.push({Word:SentenceItem[j].Word ,Phonetic: SentenceItem[j].Phonetic});
                    }
                    else
                        RowArray.push({Word:SentenceItem[j].Word,Phonetic:""});

                    if (++RowIndex >= rowCount) {
                        Sentence.push({ Index: i, Word: RowArray, Data: null});
                        Page.push(Sentence);
                        if (Page.length >= colCount) {
                            this.PageList.push(Page);
                            Page = [];
                        }
                        RowIndex = 0;
                        RowArray = [];
                        Sentence = [];
                    }
                }
            }

            if (RowArray.length > 0) {
                Sentence.push({ Index: i, Word: RowArray, Data:null });
                RowArray = [];
            }

           
            //標點符號
            for (var k = 0; k < PunctuationItem.length; k++) {
                RowArray.push({Word:PunctuationItem[k],Phonetic:""});
                if (++RowIndex >= rowCount) {
                    Sentence.push({ Index: null, Word: RowArray, Data:null });
                    Page.push(Sentence);
                    if (Page.length >= colCount) {
                        this.PageList.push(Page);
                        Page = [];
                    }
                    RowIndex = 0;
                    RowArray = [];
                    Sentence = [];
                }
            }

            if (RowArray.length > 0) {
                Sentence.push({ Index: null, Word: RowArray, Data:null });
                RowArray = [];
            }

            if (this.Data.Content[i].Paragraph != null) {
                if (this.Data.Content[i].Paragraph.indexOf("blockwrap") >= 0 ) {
                    this.Data.Content[i].Paragraph = "blockwrap";
                    if (i < this.Data.Content.length - 1) {
                        let NextLen = (this.Data.Content[i + 1].Sentence??"").length + (this.Data.Content[i + 1].Punctuation??"").length;
                        if ((rowCount - RowIndex) < NextLen) {
                            this.Data.Content[i].Paragraph = "blockwrap+";
                        }
                    }
                }

                if (this.Data.Content[i].Paragraph.indexOf("block2wrap") >= 0) {
                    this.Data.Content[i].Paragraph = "block2wrap";
                    if (i < this.Data.Content.length - 2) {
                        let NextLen = (this.Data.Content[i + 1].Sentence??"").length + (this.Data.Content[i + 1].Punctuation??"").length + (this.Data.Content[i + 2].Sentence??"").length + (this.Data.Content[i + 2].Punctuation??"").length;
                        if ((rowCount - RowIndex) < NextLen) {
                            this.Data.Content[i].Paragraph = "block2wrap+";
                        }
                    }
                }
                if (this.Data.Content[i].Paragraph === "wrap" || this.Data.Content[i].Paragraph === "block2wrap+" || this.Data.Content[i].Paragraph === "blockwrap+" || this.Data.Content[i].Paragraph === "bottom" || this.Data.Content[i].Paragraph === "blank" || this.Data.Content[i].Paragraph === "feed") {
                    RowIndex = 0;
                    if (Sentence.length > 0) {
                        if(this.Data.Content[i].Paragraph === "bottom"){
                            if(Sentence[0].Word.length < rowCount){
                                var Empty:WordPhonetic={Word:"", Phonetic:""};
                                Sentence[0].Word = Array(rowCount - Sentence[0].Word.length).map(() => Empty).concat(Sentence[0].Word);
                            }
                            Page.push(Sentence);
                        }else{
                            Page.push(Sentence); 
                        }
                        Sentence = [];

                    }

                    if (Page.length >= colCount) {
                        this.PageList.push(Page);
                        Page = [];
                    }

                    if ((this.Data.Content[i].Paragraph === "feed" || this.Data.Content[i].Paragraph === "blank")) {
                        var BlankCount = 1;
                        if (this.Data.Content[i].Paragraph === "feed") {
                            BlankCount = 0;
                            if(Page.length != 0){
                                BlankCount = colCount - Page.length;
                           }
                        }

                        for (let k = 0; k < BlankCount; k++) {
                            Page.push([]);
                        }

                        if (Page.length >= colCount) {
                            this.PageList.push(Page);
                            Page = [];
                        }

                    }
                }
            }
        }

        if (Sentence.length > 0) {
            Page.push(Sentence);
        }

        if (Page.length > 0) {
            this.PageList.push(Page);
        }

        //加入最後一個預覽行
        if (showPreview) {
            for (let i = 0; i < this.PageList.length - 1; i++) {
                var Page1 = this.PageList[i];
                var Page2 = this.PageList[i + 1];

                if (Page2.length > 0) {
                    Page1.push(Page2[0]);
                }
            }
        }

        if (this.ParserCount === 0) {
            this.TimeIndex.sort((time1: TimeData, time2: TimeData) => {
                return time1.Second - time2.Second;
            });
        }

        this.ParserCount++;
    }

    Query = (text:string) => {
        try {
            if(text!="" && text!=null){
                var Result:QueryData[] = [];
                for(var i=0; i< this.Data.Content.length; i++){
                    var Item = this.Data.Content[i];
                    if(Item.SentenceText != null)
                        if(Item.SentenceText?.indexOf(text) != -1){
                            if(Result.length >= 50)
                                return {IsOver:true,Result:Result} as QueryResult;
                            Result.push({Sentence:Item.SentenceText, Page:Item.Page, Index:i} as QueryData);

                        }
                }

                return {IsOver:false,Result:Result} as QueryResult;
            }
        
            return {IsOver:false,Result:[]} as QueryResult;
        } catch (error) {
            Analytics.Exception(error);
        }
        return null;
    }
}

export default Info;
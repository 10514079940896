import React, { useEffect, useReducer, useRef, useState } from 'react';
import './SutraBook.css';
import SutraPage, { PageHandles } from './SutraPage';
import SutraEdgeTop from './SutraEdgeTop';
import SutraEdgeBottom from './SutraEdgeBottom';
import SutraSide, { SideHandles } from './SutraSide';
import SutraMenu, { MenuHandles } from './SutraMenu';
import SutraTour, { TourHandles } from './SutraTour';
import { IonAlert, IonProgressBar,  IonCard, IonCardHeader, IonCardSubtitle,IonCardContent,IonCardTitle, IonButton, useIonToast, useIonAlert } from '@ionic/react';
import Audio from '../library/Audio';
import Info from '../library/Info';
import Storag from '../library/Storag';
import SutraCover from '../components/SutraCover';
import { AppState } from '@capacitor/app';
import Device from '../library/Device';
import i18n from '../library/i18n';
import Listener from '../library/Listener';
import { FileTransferObject } from '@ionic-native/file-transfer';
import { DownloadError } from '../library/Download';
import Analytics from '../library/Analytics';

const PageRef = React.createRef<PageHandles>();
const TourRef = React.createRef<TourHandles>();
const SideRef = React.createRef<SideHandles>();
const MenuRef = React.createRef<MenuHandles>();

interface Props {
    Code: string;
}

let ProcessString:string = "";
let AudioTransfer:FileTransferObject;

const SutraBook: React.FC<Props> = (props) => {
    const [StageAlert, SetStageAlert] = useState<boolean>(Storag.GetSutraStage(props.Code) !== null && (Storag.GetResume() === "Ask" || Storag.GetResume() === "AskOnce"));
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [AudioProcess, SetAudioProcess] = useState<number>(-1);
    

    const InfoObject = useRef<Info|undefined>(undefined);
    if(InfoObject.current == undefined)
        InfoObject.current = new Info();

    const AudioObject = useRef<Audio|undefined>(undefined);
    const TouchToStart = useRef<boolean>(false);

    const WindowWidth = useRef<number>(0);
    const WindowHeight = useRef<number>(0);
    const IsWaitingReSize = useRef<boolean>(false);
    const IsPageLoaded = useRef<boolean>(false);
    const Initializing = useRef<boolean>(true);

    const Toast = useIonToast()[0];
    const Alert = useIonAlert()[0];

    useEffect(() => {
        //事件會產生多次
        try {
            Listener.OnBackButton(() => {
                if (AudioObject.current !== undefined) {
                    AudioObject.current.Stop();
                }
                //SutraDevice.WakeLock(true);
                if(AudioTransfer !== undefined)
                    AudioTransfer.abort();
            });

            Listener.OnAppStateChange((state: AppState) => {
                if (state.isActive) {
                    //alert('App has become active');
                } else {
                    //alert('App has become inactive');
                    ShowMenu();
                }
            });
            
            if(InfoObject.current != undefined){
                if (!InfoObject.current.JsonLoaded) {
                    if(Initializing.current)
                        InfoObject.current?.LoadJson(props.Code + ".json", () => {
                            if(InfoObject.current != undefined){

                                document.title = InfoObject.current.Data.SourceTitle;
                                Analytics.ScreenName(document.title,"Book_" + props.Code);

                                var AudioPath = "";
                                if (InfoObject.current?.Data.Audio && Storag.GetSoundStatus()) 
                                    AudioPath = Storag.GetAudioPath();
                                AudioObject.current = new Audio(AudioPath, InfoObject.current?.Data.Audio?(props.Code+".m4a"):"", InfoObject.current,
                                    (hasAudio) => {
                                        //開始載入聲音
                                        if (hasAudio) {
                                            forceUpdate();
                                        }
                                    },
                                    (hasAudio) => {
                                        //載入聲音完成
                                        if (hasAudio) {
                                            Device.WakeLock(true);
                                        }
                                        Toast(i18n.t("book.sutra_loaded"), 1500);
                                        forceUpdate();
                                    }, 
                                    (transfer:FileTransferObject, loacded: number, total: number)=>{
                                        AudioTransfer = transfer;
                                        var value = Math.round((loacded / total) * 100) / 100
                                        ProcessString = (loacded/1024/1024).toFixed(1) + "/" + (total/1024/1024).toFixed(1) + "MB";
                                        SetAudioProcess(value);
                                    },OnAudioTime,
                                    ()=>{
                                        ShowMenu();
                                    },(type, error)=>{
                                        //載入聲音檔發生錯誤
                                        ErrorControl(type, error);
                                    });
                            }
                        },(type, error)=>{
                            //載入Json發生錯誤
                            ErrorControl(type, error);
                        });
                }
            }

            const ErrorControl = (type:DownloadError, error: any) => {
                if(type == DownloadError.FileTransfer){
                    if(error.code == 4){
                        //手動中斷 ABORT_ERR
                        GoToList();
                        return;
                    }
                    
                    if(error.code == 3){
                        //無網路 CONNECTION_ERR
                        Alert(i18n.t("book.sutra_not_connection"),[{ text: i18n.t("info.ok"), handler: GoToList}]);
                        return;
                    }
                }
            
                if(Storag.GetDevelopmentMode()){
                    alert(DownloadError[type]);
                    alert(JSON.stringify(error));
                }

                Alert(i18n.t("book.sutra_not_load"),[{ text: i18n.t("info.ok"), handler: GoToList}]);
            }
        
            const GoToList = () => {
                if(!InfoObject.current?.InfoLoaded){
                    window.location.href = document.referrer;
                }else if (InfoObject.current?.Data.Audio) {
                    window.location.href = "/Recite";
                } else {
                    window.location.href = "/Read";
                }
            }

            const WindowsOnLoad = (tryNum: number = 0) => {
                var NowWidth = document.body.scrollWidth;
                var NowHeight = document.body.scrollHeight;
                if (NowWidth === 0 || NowHeight === 0) {
                    setTimeout(() => { WindowsOnLoad(tryNum + 1); }, 500);
                } else if ((NowWidth !== WindowWidth.current || NowHeight !== WindowHeight.current) && InfoObject.current?.JsonLoaded) {
                    WindowWidth.current = NowWidth;
                    WindowHeight.current = NowHeight;
                    LoadPage();
                    setTimeout(() => { WindowsOnLoad(tryNum + 1); }, 500);
                } else if (tryNum <= 30) {
                    if(Initializing.current){
                        if (!(NowWidth === 0 || NowHeight === 0)){
                            Initializing.current = false;
                            forceUpdate();
                        }
                    }

                    if (IsWaitingReSize.current || !IsPageLoaded.current)
                        setTimeout(() => { WindowsOnLoad(tryNum + 1); }, 500);
        
                } else {
                    ErrorControl(DownloadError.Error, "WindowsOnLoad");
                }
            }

            window.addEventListener('resize', () => {
                if (!IsWaitingReSize.current) {
                    IsWaitingReSize.current = true;
                    WindowsOnLoad();
                }

            }, { once: true });

            if (!InfoObject.current?.InfoLoaded) {
            
                WindowsOnLoad();
            }
        } catch (error) {
            Analytics.Exception(error);
        }
    });

    const LoadPage = () => {
        try {
            InfoObject.current?.SetInfo();
            //document.body.style.backgroundColor = Color.Background;
            IsPageLoaded.current = true;
            Initializing.current = false;
            forceUpdate();
            setTimeout(() => {
                IsWaitingReSize.current = false;
            }, 100);
        } catch (error) {
             Analytics.Exception(error);
        }
    }

    const PageRender = () => {
        try {
            if (SideRef.current !== null)
                SideRef.current.SetPage();

            if (TourRef.current !== null)
                TourRef.current.Refresh();
        } catch (error) {
            Analytics.Exception(error);
        }
    }

    //點選事件
    const TouchIndex = ((index: number, goNext: boolean) => {
        try {
            var Second = InfoObject.current?.IndexTime[index];
            //儲存進度
            Storag.SetSutraStage(props.Code, index, Second === undefined ? 0 : Second);

            if (Second !== undefined)
                AudioObject.current?.SetTime(Second);
            SetIndex(index, false);
            /*
                if (goNext) {
                    if (PageRef.current !== null)
                        PageRef.current.Next();
                }
            */
            if (TourRef.current !== null)
                TourRef.current.Hide();
        } catch (error) {
            Analytics.Exception(error);
        }
    });


    const OnAudioTime = (second: number) => {
        try {
            if(InfoObject.current != undefined){
                if (InfoObject.current.TimeIndex.length !== 0) {
                    let Start = 0;
                    let End = InfoObject.current.TimeIndex.length;
                    do {
                        let Mid = Math.floor((Start + End) / 2);
                        let Second = InfoObject.current.TimeIndex[Mid].Second;
                        if (Second <= second) {
                            Start = Mid;
                        } else {
                            End = Mid;
                        }


                    } while (End - Start > 1);

                    var Index = InfoObject.current?.TimeIndex[Start].Index;
                
                    if (!(InfoObject.current.SelectIndex == Index)) {
                        SetIndex(InfoObject.current.TimeIndex[Start].Index, true, InfoObject.current.TimeIndex[Start].Index !== 0);
                    }
                }
            }
        } catch (error) {
            Analytics.Exception(error);
        }
    }

    const SetIndex = ((index: number, auto: boolean, SetStage?:boolean) => {
        try {
            if (PageRef.current !== null) {
                //儲存進度
                var Second = InfoObject.current?.IndexTime[index];

                //儲存進度
                if(SetStage==null || SetStage)
                    Storag.SetSutraStage(props.Code, index, Second === undefined ? 0 : Second);

                PageRef.current.SetIndex(index, auto);
            }
        } catch (error) {
            Analytics.Exception(error);
        }
    });

    const SeekIndexAudio = ((index: number) => {
        try {
            //if (Storag.GetPlayStatus() && Storag.GetSoundStatus() && InfoObject.Data.Audio) {
            if ( Storag.GetSoundStatus() && InfoObject.current?.Data.Audio) {
                var Second = InfoObject.current?.IndexTime[index];
                if (Second !== undefined){
                    AudioObject.current?.SetTime(Second);
                }
                SetIndex(index, false);  
                    
            } else {
                SetIndex(index,false);
                //SetIndex(index, true);
            }
        } catch (error) {
            Analytics.Exception(error);
        }
    });

    const SetRate = (rate: number) => {
        AudioObject.current?.SetRate(rate);
    };

    /*
    const SetAudio = ((rate: number | null, repeat: boolean | null) => {
        if (rate != null)
            AudioObject.current?.SetRate(rate);

        if (repeat != null)
            AudioObject.current?.SetRepeat(repeat);
    });
    */

    const SeekPage = ((pageIndex: number) => {
        if (PageRef.current !== null)
            PageRef.current.SetPage(pageIndex);
    });

    var StartX: number | null;
    var EndX: number | null = null;
    var IsTouch: boolean = false;
    var IsMoving = false;

    const TouchStart = (x: number, isTouch: boolean) => {
        if (isTouch)
            IsTouch = true;

        if (IsTouch === isTouch) {
            if (!IsMoving) {
                StartX = x;
                EndX = null;
            }
        }
    }

    const TouchMove = (x: number, isTouch: boolean) => {
        try {
            if (StartX !== null && IsTouch === isTouch) {
                if (PageRef.current !== null) {
                    EndX = x;
                    let DeltaX: number = EndX - StartX;
                    if (Math.abs(DeltaX) >= Storag.GetFontSize())
                        PageRef.current.SetX(DeltaX);
                }
            }
        } catch (error) {
            Analytics.Exception(error);
        }
    }

    const TouchEnd = (isTouch: boolean) => {
        try {
            if (StartX !== null && IsTouch === isTouch && EndX !== null && PageRef.current !== null) {
                let DeltaX: number = EndX - StartX;
                IsMoving = true;
                if (Math.abs(DeltaX) >= Storag.DefaultFontSize()) {
                    if (DeltaX > 0) {
                        //下一頁
                        IsMoving = true;
                        if(InfoObject.current != undefined){
                            if (InfoObject.current.Page >= InfoObject.current.PageList.length - 1) {
                                IsMoving = true;
                                GoVolume(true);
                                PageRef.current.MoveToX(DeltaX, 0, () => { IsMoving = false; });
                            } else {
                                PageRef.current.MoveToX(DeltaX, InfoObject.current.PageWidth, () => {
                                    if (PageRef.current !== null)
                                        PageRef.current.Next();

                                    IsMoving = false;
                                });

                            }
                        }
                    } else {
                        //上一頁
                        IsMoving = true;
                        if(InfoObject.current != undefined){
                            if (InfoObject.current.Page <= 0) {
                                GoVolume(false);
                                PageRef.current.MoveToX(DeltaX, 0, () => { IsMoving = false; });
                            } else {
                                PageRef.current.MoveToX(DeltaX, -InfoObject.current.PageWidth, () => {
                                    if (PageRef.current !== null)
                                        PageRef.current.Previous();

                                    IsMoving = false;
                                });
                            }    
                        }

                    }
                } else {
                    PageRef.current.MoveToX(DeltaX, 0, () => { IsMoving = false; });
                }

            }
            StartX = null;
        } catch (error) {
            Analytics.Exception(error);
        }
    }

    const GoVolume = (next: boolean) => {
        var Regx = props.Code.match(/(.*_)([0-9]+)/);
        if (Regx != null && InfoObject.current?.Data.Volume != null) {
            var Code = Regx[1];
            var Num = parseInt(Regx[2], 10) + (next ? 1 : -1);
            if ((InfoObject.current?.Data.Volume >= Num && next) || (Num >= 1 && !next)) {
                if (next) {
                    Alert(i18n.t("menu.ask_next_volume", { num: Num }),[
                        {
                            text: i18n.t("info.cancel"),
                            role: 'cancel',
                            cssClass: 'secondary'
                        }, {
                            text: i18n.t("info.ok"),
                            handler: ()=>{
                                window.location.href = "/Book/" + Code + Num;
                            }
                        }
                    ]);
                } else {
                    Alert(i18n.t("menu.ask_pre_volume", { num: Num }),[
                        {
                            text: i18n.t("info.cancel"),
                            role: 'cancel',
                            cssClass: 'secondary'
                        }, {
                            text: i18n.t("info.ok"),
                            handler: ()=>{
                                window.location.href = "/Book/" + Code + Num;
                            }
                        }
                    ]);
                }
            }
        }
    }

    const ShowMenu = () => {
        try {
            Device.WakeLock(false);
            if (MenuRef.current != null && InfoObject.current?.InfoLoaded && !StageAlert) {
                if (!MenuRef.current.IsOpen()) {
                    if(AudioObject.current?.PlayRate != undefined)
                        MenuRef.current.ShowMenu(AudioObject.current?.PlayRate);
                    AudioObject.current?.Pause();
                }
            }
        } catch (error) {
            Analytics.Exception(error);
        }
    };

    const HideMenu = () => {
        try {
            if(AudioObject.current!=null){
                if (InfoObject.current?.Data.Audio)
                    Device.WakeLock(true);
                AudioObject.current?.Play();
            }
        } catch (error) {
            Analytics.Exception(error);
        }
    };

    const Reset = () => {
        Initializing.current = true;
        IsWaitingReSize.current = false;
        IsPageLoaded.current = false;

        WindowWidth.current = 0;
        WindowHeight.current = 0;
        AudioObject.current = undefined;
        TouchToStart.current = false;

        
        InfoObject.current = undefined;
        SetAudioProcess(-1);
    }



    const TouchCover = () => {
        try {
            if(Storag.GetSutraStage(props.Code)==null){
                Storag.SetSutraStage(props.Code,0,0);
            }
            if (AudioObject.current !== undefined) {
                if (AudioObject.current?.Loaded) {
                    TouchToStart.current = true;
                    //判斷是否要回復上次進度
                    if (!StageAlert) {
                        if (Storag.GetResume() === "Resume") {
                            var Stage = Storag.GetSutraStage(props.Code);
                            if (Stage !== null) 
                                if (Stage.Index !== 0) {
                                    //指定初始頁面 沒有聲音的會以這個
                                    if(InfoObject.current != undefined){
                                        InfoObject.current.SelectIndex = Stage.Index;
                                        InfoObject.current.Page = InfoObject.current?.IndexPage[Stage.Index];
                                    }
                                
                                    if (AudioObject.current?.HasAudio)
                                        AudioObject.current?.SetTime(Stage.Second);
                                }
                        }
                
                        if (AudioObject.current?.HasAudio)
                            AudioObject.current?.Play();
                    }
                    
                    forceUpdate();
                }
            }
        } catch (error) {
            Analytics.Exception(error);
        }
    }

    try {
        var Color = Storag.GetColorInfo();
        
        if(Initializing.current){
            return (
                <table className="table" style={{ backgroundColor: Color.Background }}>
                    <tbody>
                        <tr>
                            <td>
                    
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        }
        
        if (AudioProcess>=0 && !AudioObject.current?.Loaded) {
            return (
                <span>
                    <SutraCover Color={Color} TouchCover={()=>{}}></SutraCover>
                    <table className="table" style={{ position:"absolute", width:(Device.GetShortSide()+"px"),left:((Device.GetWidth()-Device.GetShortSide())/2+"px")}}>
                        <tbody>
                            <tr>
                                <td>
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardSubtitle>{InfoObject.current?.Data.Title}</IonCardSubtitle>
                                            <IonCardTitle>{i18n.t("book.audio_loading")}</IonCardTitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <table width="100%">
                                                <thead></thead>
                                                <tfoot></tfoot>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2}><IonProgressBar value={AudioProcess}></IonProgressBar></td>
                                                    </tr>
                                                    <tr>
                                                        <td>{(AudioProcess*100).toFixed(0)+"%"}</td>
                                                        <td style={{textAlign:'right'}} >{ProcessString}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}><hr/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>{i18n.t("book.audio_offline_message")}</td>
                                                        <td style={{textAlign:"right"}} ><IonButton color="light" onClick={()=>{AudioTransfer.abort();}}>{i18n.t("info.cancel")}</IonButton></td>
                                                    </tr>
                                                </tbody>
                                                
                                            </table>
                                        </IonCardContent>
                                    </IonCard>
                                
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </span>
            );
        }
        

        if (!InfoObject.current?.InfoLoaded || AudioObject.current == undefined || !AudioObject.current?.Loaded) {
            return (
                <span>
                    <SutraCover Color={Color} TouchCover={()=>{}}></SutraCover>
                    <table className="table" style={{ position:"absolute", width:(Device.GetShortSide()+"px"),left:((Device.GetWidth()-Device.GetShortSide())/2+"px")}}>
                        <tbody>
                            <tr>
                                <td>
                                    <link rel="prefetch" href="/assets/cover.svg" />
                                    <link rel="prefetch" href="/assets/edge_top.svg" />
                                    <link rel="prefetch" href="/assets/edge_bottom.svg" />
                                    <link rel="prefetch" href="/assets/edge_menu.svg" />
                                    <link rel="prefetch" href="/assets/menu_flower.svg" />
                                    <link rel="prefetch" href="/assets/right-arrow.svg" />
                                    <link rel="prefetch" href="/assets/left-arrow.svg" />
                                    <link rel="prefetch" href={"/assets/font/" + Storag.GetFontFamily() + ".woff2"} />
                                    <link rel="prefetch" href="/assets/icon/pause.svg" />
                                    <link rel="prefetch" href="/assets/icon/play.svg" />
                                    <link rel="prefetch" href="/assets/icon/book.svg" />
                                    <link rel="prefetch" href="/assets/icon/gear.svg" />
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardTitle>{i18n.t("book.sutra_loading")}</IonCardTitle>
                                            <IonCardContent>
                                            <table width="100%">
                                                <thead></thead>
                                                <tfoot></tfoot>
                                                <tbody>
                                                    <tr>
                                                        <td><IonProgressBar type="indeterminate"></IonProgressBar></td>
                                                    </tr>
                                                    <tr>
                                                        <td> </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </IonCardContent>
                                        </IonCardHeader>
                                    </IonCard>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </span>
            );
        }

        if (!TouchToStart.current) {
            return (<SutraCover Color={Color} Info={InfoObject.current} TouchCover={TouchCover}></SutraCover>);
        }

        return (
            <table className="table" style={{ backgroundColor: Color.Background }} >
                <tbody>
                    <tr>
                        <td height={InfoObject.current?.EdgeHeight}>

                            <SutraEdgeTop Color={Color} Info={InfoObject.current}></SutraEdgeTop>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: 0 }} onMouseDown={(event) => { TouchStart(event.pageX, false) }} onTouchStart={(event) => { TouchStart(event.touches[0].pageX, true) }} onMouseMove={(event) => { TouchMove(event.pageX, false) }} onTouchMove={(event) => { TouchMove(event.touches[0].pageX, true) }} onMouseUp={() => { TouchEnd(false); }} onTouchEnd={() => { TouchEnd(true); }}>
                            <div style={{ position: "absolute", left: 0, top: InfoObject.current?.EdgeHeight }}>
                                <SutraPage Color={Color} Info={InfoObject.current} TouchBack={TouchIndex} RenderBack={() => { PageRender(); }} ref={PageRef} />
                            </div>
                            <div style={{ position: "absolute", right: 0, top: InfoObject.current?.EdgeHeight }}>
                                <SutraSide Color={Color} Info={InfoObject.current} ref={SideRef}></SutraSide>
                            </div>
                            <div style={{ position: "absolute", left: 0, width: InfoObject.current?.PageWidth, bottom: InfoObject.current?.EdgeHeight / 2 }}>
                                <SutraTour Color={Color} ref={TourRef} Info={InfoObject.current} PageChange={(page) => { SeekPage(page); }}></SutraTour>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td height={InfoObject.current?.EdgeHeight} className="foot">
                            <SutraEdgeBottom Color={Color} Info={InfoObject.current} onClick={ShowMenu}></SutraEdgeBottom>
                            <IonAlert
                                cssClass="stage_alert"
                                isOpen={StageAlert}
                                header={i18n.t("menu.resume_auto")}
                                onDidDismiss={() => { AudioObject.current?.Play(); SetStageAlert(false) }}
                                message={i18n.t("menu.resume_message")}
                                inputs={
                                    [
                                        {
                                            type: "checkbox",
                                            label: i18n.t("menu.resume_dont_ask"),
                                            value: "NoAsk",
                                            checked: (Storag.GetResume() === "AskOnce")
                                        }
                                    ]}
                                buttons={[
                                    {
                                        text: i18n.t("menu.resume_start"),
                                        handler: (data) => {
                                            SetIndex(0, true);
                                            AudioObject.current?.Play();
                                            if (data[0] === "NoAsk")
                                                Storag.SetResume("Start");

                                            SetStageAlert(false);
                                        }
                                    },
                                    {
                                        text: i18n.t("menu.resume_resume"), handler: (data) => {
                                            var Stage = Storag.GetSutraStage(props.Code);
                                            if (Stage !== null){
                                                if (Stage.Index !== 0) {
                                                    SetIndex(Stage.Index, true);
                                                    AudioObject.current?.SetTime(Stage.Second);
                                                    AudioObject.current?.Play();
                                                    
                                                    if (data[0] === "NoAsk")
                                                        Storag.SetResume("Resume");
                                                    SetStageAlert(false);
                                                }
                                            } 

                                        }
                                    }
                                ]}
                            />
                            <SutraMenu OnHideMenu={HideMenu} Info={InfoObject.current} ref={MenuRef} Code={props.Code} SeekIndex={(index) => { SeekIndexAudio(index); }} SeekPage={SeekPage} OnRateChange={SetRate} OnAudioStop={()=>{AudioObject.current?.Stop();AudioObject.current=undefined;}} OnReset={Reset} OnInfoChange={() => LoadPage()}></SutraMenu>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    } catch (error) {
        Analytics.Exception(error);
    }

    return <div>Error</div>;
};

export default SutraBook;

import React, { useEffect } from 'react';
import './SutraCover.css';
import Info from '../library/Info';
import Storag from '../library/Storag';
import Device, { DeviceType } from '../library/Device';
import Color from '../library/Color';

interface Props {
    Info?: Info;
    Color:Color;
    TouchCover(): void;
}

const SutraCover: React.FC<Props> = (props) => {

    var ScreenWidth = Device.GetWidth();
    var ScreenHeight = Device.GetHeight();

    var BlockHeight: number;
    if (ScreenHeight >= ScreenWidth) {
        //直
        switch (Device.Device) {
            case DeviceType.Mobile:
                BlockHeight = ScreenHeight / 5;
                break;
            case DeviceType.Tablet:
                BlockHeight = ScreenHeight / 6;
                break;
            default:
                BlockHeight = ScreenHeight / 6;
                break;
        }
    } else {
        //橫
        switch (Device.Device) {
            case DeviceType.Mobile:
                BlockHeight = (ScreenWidth / 1.5) / 3.5;
                break;
            case DeviceType.Tablet:
                BlockHeight = (ScreenWidth / 1.5) / 4.5;
                break;
            default:
                BlockHeight = ScreenHeight / 6;
                break;
        }
    }

    const Title = () => {
        if(props.Info != null){
            var TextList = [];
            var TitleFontSize = 32;
            var Space = (500 - (props.Info.Title.length * TitleFontSize)) / (props.Info.Title.length + 4);
            var TitleHeight = ScreenHeight * (ScreenHeight >= ScreenWidth ? 0.8 : 0.95);
            var TitleWidth = TitleHeight / 5;

            for (let i = 0; i < props.Info.Title.length; i++) {
                TextList.push(<text fill={props.Color.Invert?"#FFFFFF":"#000000"} key={"CoverNum" + i} x={(100 - TitleFontSize) / 2} y={(i + 1) * (TitleFontSize + Space) + Space} fontSize={TitleFontSize}>{props.Info.Title.substr(i, 1)}</text>);
            }
            return  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 500" fontFamily={Storag.GetFontFamily()} height={TitleHeight} style={{ display: "block", marginLeft: (ScreenWidth - TitleWidth) / 2, marginTop: ScreenHeight * (ScreenHeight >= ScreenWidth ? 0.05 : 0.025) }} >
                        <rect x="0" y="0" width="100" height="500" fill={props.Color.Invert?"#303030":"#FFFFFF"} stroke={props.Color.Invert?"#FFFFFF":"#000000"} strokeWidth="4"></rect>
                        <rect x="6" y="6" width="88" height="488" fill={props.Color.Invert?"#303030":"#FFFFFF"} stroke={props.Color.Invert?"#FFFFFF":"#000000"} strokeWidth="1"></rect>
                        {TextList}
                    </svg>;
        }else{
            return <span></span>;
        }

    }
    
    useEffect(() => {
        console.log("SutraCover載入");
        //document.body.style.backgroundColor = Color.Background;
    });

    return (
        <div>
            <div className="no_select" style={{ position: "absolute",cursor: "default",  width: "100%", height: "100%", backgroundImage: "url(/assets/cover"+(props.Color.Invert?"2":"1")+".svg)", backgroundSize: "auto "+Math.ceil(BlockHeight) + "px", backgroundBlendMode: props.Color.Invert ? "luminosity" : "multiply", backgroundColor: props.Color.Background }} onClick={() => { props.TouchCover() }}>
            {Title()}
            </div>
        </div>
    );
}

export default SutraCover;
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import Device from './Device';
import Storag, { AppType } from './Storag';

  
//deptno/gtag
class Analytics {

    static Initialize(){
        if(!Device.IsHybrid){
            try {
                FirebaseAnalytics.initializeFirebase({
                    apiKey: "AIzaSyAykBiRo5ecAxuMHKsiIIXtBk9xPebt138",
                    authDomain: "app-c77d9.firebaseapp.com",
                    projectId: "app-c77d9",
                    storageBucket: "app-c77d9.appspot.com",
                    messagingSenderId: "602646919308",
                    appId: "1:602646919308:web:06ade0d494ef50df56a3a9",
                    measurementId: "G-Y625203EXM"
                  }).then(()=>{
                    Analytics.UserProperty("AppType", AppType[Storag.GetAppType()].toString());
                    Analytics.UserProperty("FontFamily", Storag.GetFontFamily());
                    Analytics.UserProperty("Language", Storag.GetLanguage());
                    Analytics.UserProperty("Repeat", Storag.GetRepeat().toString());
                    Analytics.UserProperty("Resume", Storag.GetResume());
                    Analytics.ScreenView(window.location.pathname,"佛經誦讀Web",process.env.REACT_APP_NAME, process.env.REACT_APP_VERSION);
                  });
            } catch(e) {
            }
        }else{
            Analytics.UserProperty("AppType", AppType[Storag.GetAppType()].toString());
            Analytics.UserProperty("FontFamily", Storag.GetFontFamily());
            Analytics.UserProperty("Language", Storag.GetLanguage());
            Analytics.UserProperty("Repeat", Storag.GetRepeat().toString());
            Analytics.UserProperty("Resume", Storag.GetResume());
        }


    }



    static ScreenName(screenName:string, nameOverride?:string){
        if(Device.IsHybrid){
            try {
                FirebaseAnalytics.setScreenName({
                    screenName: screenName,
                    nameOverride: nameOverride
                });
            } catch(e) {
                if(Storag.GetDevelopmentMode()){
                    alert(e);
                }
            }
        }
    }

    static Action(action: string, event_category: string, event_label?: string, value?: number){
        try {
            FirebaseAnalytics.logEvent(
                {
                    name:action,
                    params:{
                        event_category: event_category,
                        event_label: event_label,
                        value: value
                    }
                });
        } catch(e) {
            if(Storag.GetDevelopmentMode()){
                alert(e);
            }
        }
    }

    static Exception(error:any, param?:string){
        if (error instanceof Error) {
            Analytics.Event("exception", {
                description: error.stack==undefined?error.message:error.stack,
                fatal: false
            });
        }

        var Description:string = "";
        if(error.message)
            Description += "Message: " + error.message + "\n";

        if(error.stack)
            Description += "Stack: " + error.stack + "\n";

        if(param)
            Description += "param: " + param + "\n";


        Storag.Feedback("程式錯誤回報", Description,()=>{},(e)=>{});

        throw error;
    }

    static ScreenView(screen_name: string, app_name: string, app_id?: string, app_version?: string, app_installer_id?: string){
        Analytics.Event("screen_view", {
            screen_name: screen_name,
            app_name: app_name,
            app_id: app_id,
            app_version: app_version,
            app_installer_id: app_installer_id
        });
    }

    static Search(search_term: string){
        Analytics.Event("search", {
            search_term: search_term
        });
    }


    static UserProperty(name: string, value: string){
        try {
            FirebaseAnalytics.setUserProperty({
                name: name,
                value: value
            });
        } catch(e) {
            if(Storag.GetDevelopmentMode()){
                alert(e);
            }
        }
    }



    static Event(name:string, params:object){
        try {
            FirebaseAnalytics.logEvent(
                {
                    name:name,
                    params:params
                });
        } catch(e) {
            if(Storag.GetDevelopmentMode()){
                alert(e);
            }
        }
    }
}

export default Analytics;
import React from 'react';
import { RouteComponentProps } from 'react-router';
import SutraBook from '../components/SutraBook';
import './Book.css';

interface PageProps extends RouteComponentProps<{ code: string; }> { }

const Book: React.FC<PageProps> = ({ match }) => {
    if (match.params.code !== undefined) {
        return (
            <SutraBook Code={match.params.code} />
        );
    }

    return (<div>錯誤！</div>);
};

export default Book;

import { Howl } from 'howler';
import Storag from './Storag';
import Info from './Info';
import Device from './Device';
import { FileTransferObject } from '@ionic-native/file-transfer';
import Download, { DownloadError } from './Download';

class Audio {
    Sound: Howl | null = null;
    SoundId:number|null = null;

    OnTime?: (second: number) => void = undefined;

    PlayRate: number = 1;
    Deviation: number = -0.5; //誤差時間
    Loaded: boolean = false;
    HasAudio: boolean = false;
    Info: Info;
    TimeIndexing: boolean = false;

    constructor(path: string, fileName:string, info: Info, onLoading: (HasAudio: boolean) => void, onLoad: (HasAudio: boolean) => void, onDownloading:(transfer:FileTransferObject, loaded: number, total: number)=>void, onTime: (second: number) => void,  onEnd: () => void,onError: (type:DownloadError, error: any) => void) {
        this.Info = info;
        var AudioObject = this;
        //const Loaded = () => {
            //this.Loaded = true;
        //}

        //const TimeIndex = () => {
          //  this.TimeIndex();
       // }

       if (path != "" && fileName != "" ) {
        this.HasAudio = true;
        this.OnTime = onTime;
        onLoading(true);

        Download.DownloadAudio(path, fileName,(urlData: string)=>{
                this.Sound = new Howl({
                    src: urlData,
                    autoplay: Device.IsHybrid,
                    preload : true,
                    //loop: Storag.GetRepeat(),
                    loop: false,
                    html5: true,
                    onload: function () {
                        // 載入後執行
                        //Loaded();
                        //第一次載入聲音檔 如果太快Play會無法暫停
                        setTimeout(() => {
                            AudioObject.Loaded = true
                            onLoad(true);
                        }, Device.IsHybrid ? 200 : 1000);
                    },
                    onend: function () {
                        //播放結尾的事件
                        if (!Storag.GetRepeat()) {
                            //Storag.SetPlayStatus(false);
                            onEnd();
                        }else{
                             //safari會沒有辦法loop
                            AudioObject.Pause();
                            setTimeout(() => {
                                AudioObject.Play();
                            }, 500);
                        }
                    }, onloaderror: (soundId: number, error: unknown)=> {
                        //發生錯誤的事件
                        onError(DownloadError.Audio, new Error((error as number).toString()));
                    }, onplay: function (soundId: number) {
                        console.log("PlayId:" + soundId);
                        AudioObject.SoundId = soundId;
                        AudioObject.TimeIndex();
                    }, onpause: function (soundId: number) {
                        console.log("PauseId:" + soundId);
                    }
                });
    
                //先自動播放再暫停比較不會有問題
                if (Device.IsHybrid)
                    this.Sound.pause();
    
                //this.Play();
            },
            (transfer:FileTransferObject, loaded: number, total: number)=>{
                //下載進度
                onDownloading(transfer, loaded, total)
            }, onError);
        } else {
            onLoading(false);
            //Loaded();
            AudioObject.Loaded = true
            onLoad(false);
        }
    }



    TimeIndex = () => {
        if (this.OnTime !== undefined) {
            if (!this.TimeIndexing)
                this.TimeIndexEvent();
        }
    }


    TimeIndexEvent = () => {
        if (this.OnTime !== undefined) {
            var Time = this.GetTime();
            if (Time != null)
                this.OnTime(Time);
            //if (this.IsPlaying) {
            if (this.Sound != null) {
                this.TimeIndexing = true;
                if (this.Playing()) {
                    setTimeout(this.TimeIndexEvent, 333.3 / this.PlayRate);
                } else {
                    setTimeout(this.TimeIndexEvent, 1000);
                }
            } else {
                this.TimeIndexing = false;
            }
        }
    }

    Playing = () => {
        if (this.Sound != null) {
            if(this.SoundId==null)
                return this.Sound.playing();
            else
            return this.Sound.playing(this.SoundId);
        }
        
        return false;
    }

    Play = () => {
        if (this.Sound != null) {
            if (Storag.GetPlayStatus()) {
                //if (!this.Sound.playing() && !this.IsPlaying) {
                if (!this.Playing()) {
                    console.log("Play");
                    if(this.SoundId!=null)
                        this.Sound.play(this.SoundId);
                    else
                        this.Sound.play();
          
                    //this.IsPlaying = true;
                    this.TimeIndex();
                }
            }
        }
    };

    Pause = () => {
        if (this.Sound != null) {
           // if (this.Sound.playing())
            if(this.SoundId!=null)
                this.Sound.pause(this.SoundId);
            if (this.Playing()) {
                //alert("無法停止!");
            }
              
            //this.IsPlaying = false;
          
        }
    };

    Stop = () => {
        if (this.Sound != null) {
            console.log("Stop");
            //this.IsPlaying = false;
            if(this.SoundId!=null)
                this.Sound.stop(this.SoundId);
            this.Sound.unload();
        }
        this.Sound = null;
    };



    GetTime = (): number | null => {
        if (this.Sound != null) {
            var CurrentTime = (this.Sound.seek() as number) || 0;
            return CurrentTime - this.Deviation;
        }
        return null
    }

    SetTime = (second: number) => {
        if (this.Sound != null) {
            if(this.SoundId!=null)
                this.Sound.seek(second, this.SoundId);
        }
    }

    SetRate = (rate: number) => {
        if (this.Sound != null) {
            this.PlayRate = rate;
            if(this.SoundId!=null)
                this.Sound.rate(rate, this.SoundId);
        }
    }
    /*
    SetRepeat = (repeat: boolean) => {
        if (this.Sound != null) {
            if(this.SoundId!=null)
                this.Sound.loop(repeat, this.SoundId);
        }
    }
    */

}

export default Audio;